.messenger {
  display: grid;
  width: 100%;
  height: 100vh;
  background: #eeeef1;
  grid-template-columns: 350px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.container {
  padding: 10px;
}

.scrollable {
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.sidebar {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

/* --- 22/01/19 --- */

.calendar-container-dark .sidebar {
  background: #1F1F1F;
}

.calendar-container-dark .content {
  background: #1F1F1F;
}

/* --- 23/01/19 --- */

.content {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.footer {
  grid-column-start: 2;
  background: white;
}