.form-group ::-webkit-input-placeholder {
  color: #1f1f1f !important;
  font-size: 16px;
  opacity: unset;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}
.form-group ::-moz-placeholder {
  color: #1f1f1f !important;
  font-size: 16px;
  opacity: unset;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}
.form-group ::-ms-input-placeholder {
  color: #1f1f1f !important;
  font-size: 16px;
  opacity: unset;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}
.form-group ::placeholder {
  color: #1f1f1f !important;
  font-size: 16px;
  opacity: unset;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}
.fam_gp ::-webkit-input-placeholder {
  color: #1f1f1f !important;
  font-size: 16px !important;
  opacity: unset;
  font-family: 'Raleway', sans-serif;
  font-weight: normal !important;
}
.fam_gp ::-moz-placeholder {
  color: #1f1f1f !important;
  font-size: 16px !important;
  opacity: unset;
  font-family: 'Raleway', sans-serif;
  font-weight: normal !important;
}
.fam_gp ::-ms-input-placeholder {
  color: #1f1f1f !important;
  font-size: 16px !important;
  opacity: unset;
  font-family: 'Raleway', sans-serif;
  font-weight: normal !important;
}
.fam_gp ::placeholder {
  color: #1f1f1f !important;
  font-size: 16px !important;
  opacity: unset;
  font-family: 'Raleway', sans-serif;
  font-weight: normal !important;
}
/* .author_profile{
    height:-webkit-fill-available;
    background-color:#fff;
    padding-bottom:30px;
  } */
.addMore {
  width: 20px;
  margin-left: 10px;
}
.raemail {
  margin-left: 3%;
}
.react-datepicker-wrapper {
  display: block;
}
.react-datepicker__input-container {
  display: block;
}
.profile-setup .setup_p_hed {
  height: auto !important;
  padding: 0px 0px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-setup .header-secion {
  background: #ededed;
  height: 74px;
  display: flex;
  align-items: center;
}
.profile-setup .setup_p_hed button {
  padding: 0px !important;
  min-width: auto !important;
}
.main-content.profile-setup .in_title {
  margin-left: 20px;
}
.main-content.profile-setup .in_form {
  border: 2px groove #000 !important;
}
.main-content.profile-setup input,
.main-content.profile-setup select,
.main-content.profile-setup select optgroup {
  border: 1px solid #acacac !important;
  font: 14px Raleway !important;
  padding: 7.5px 10px;
}
