/* @media (min-width: 576px){
      .modal-dialog {
        max-width: 920px;
        margin: 1.75rem auto;
    }
    } */
.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.model_cover {
  padding: 10px;
  border: 2px solid #706969;
}
.modal-title {
  font-weight: 600;
  position: relative;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.eventModal.modal-title {
  width: 100%;
}

.upperCaseText {
  text-transform: uppercase;
}
.disable-renew-btn {
  cursor: not-allowed !important;
  background-color: #e6e6e6 !important;
  border: 1px solid #e4e3e3 !important;
  color: #ffffff !important;
  font-weight: 500 !important;
}
.auto-renew span {
  display: flex;
}

.auto-renew .toggle_switch {
  padding-left: 12px;
}
.auto-renew .check-state {
  margin: 0px 20px 0px 5px;
  font-weight: 500;
}
.modal-header button {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 0px !important;
  width: auto;
  min-width: fit-content !important;
  font-size: 40px !important;
  background: url('../../../src/close.svg')
}
.modal-header button:focus {
  outline: none;
  box-shadow: none;
}
.modal-header {
  position: relative;
}

.blast-popup span.ql-formats button {
  min-width: auto !important;
  width: auto !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 5px !important;
  min-height: auto;
  height: auto !important;
}

.blast-popup span.ql-formats button svg {
  height: 16px !important;
  width: 15px !important;
}

.blast-popup .ql-toolbar.ql-snow {
  display: flex;
  align-items: center;
}

.event-cover-popup .event_body.modal-body,
.event-cover-popup .eventModal h5 h3 {
  margin-top: 0px;
  padding-top: 5px;
}
.event-cover-popup .event-poup-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 0.6rem;
}
.event-cover-popup .book-release-cover img {
  height: auto;
  width: auto;
  height: 160px;
  object-fit: cover;
  margin: 0 2px;
  /* border-radius: 50%;  */
}
.event-cover-popup .book-release-cover1 img {
  /* height:auto;
  width: 100%; */
  /* border-radius: 50%;  */
  height: 160px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 10px;
}

.event-cover-popup .book-release-cover {
  text-align: center;
}

.event-cover-popup .event_body.modal-body li {
  font-size: 14px !important;
}
.event-cover-popup .modal-title {
  margin-bottom: 20px;
}
.event-cover-popup .eventModal.modal-header {
  padding: 0px;
}
.event-cover-popup .event-popup-body {
  max-height: 350px;
  overflow: auto;
}
.event-cover-popup .event_body.modal-body {
  padding: 5px;
  padding-bottom: 0px;
}
.event-cover-popup .event-popup-body::-webkit-scrollbar {
  width: 5px;
}

.event-cover-popup .event-popup-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.event-cover-popup .event-popup-body::-webkit-scrollbar-thumb {
  background: #888;
}
.edit-profile-btn {
  display: flex;
  justify-content: flex-end;
}
.event-filter-popup legend {
  margin-bottom: 0px !important;
  padding: 0px 10px !important;
  font-weight: bolder;
}

.event-filter-popup fieldset {
  border: 3px groove #000 !important;
  box-shadow: 0 0 0 0 #000;
}
.event-filter-popup label.custom-control-label {
  display: initial;
  margin-bottom: 0px !important;
}
.event-filter-popup
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-size: 70%;
}

.sort-event-popup button.plus_btn i {
  font-size: 30px;
  margin-top: -2px;
}

.sort-event-popup button.delete_btn {
  min-width: auto !important;
  height: auto !important;
  margin-top: 0px;
  padding: 6px 10px !important;
}

.sort-event-popup button.delete_btn i {
  font-size: 28px;
  padding: 0px !important;
  margin-top: 0px !important;
}
.sort-event-popup .row {
  align-items: flex-end;
}
.blast-details-page .btn.disabled,
.blast-details-page .btn:disabled {
  opacity: 0.3;
}
.blast-details-page .blast-legend.blastFieldSet {
  border: 2px groove #000 !important;
  /* padding: 0 12px 12px !important; */
  padding: 0 12px 0 !important;
}
.blast-details-page .pr-blogger .details-box .blast-legend.blastFieldSet {
  padding-bottom: 12px !important;
}
.blast-details-page
  .pr-blogger
  .details-box
  .blast-legend.blastFieldSet
  .blastContainer {
  grid-template-rows: minmax(30px, auto) minmax(46px, auto) minmax(30px, auto);
}
.blast-details-page .blastContainer {
  margin-left: 0px !important;
}
.details-box {
  width: 32.5%;
}

.blast-details-boxes {
  display: flex;
  justify-content: space-between;
}
.blast-details-boxes p {
  font-size: 13px !important;
}
.blast-details-page button {
  min-width: 130px !important;
  padding: 8px 12px !important;
  font-size: 11px !important;
}
.blast-details-page .pr-blogger .blastContainer button {
  min-width: 150px !important;
  font-size: 14px !important;
}
.blast-details-page {
  /* margin-bottom: 50px; */
  margin-bottom: 20px;
}
.blast-details-page .blastRow2 {
  grid-column-gap: 5px;
}
.test-header select#page-size {
  margin-left: 10px;
}
.blast-details-page pre.blastP {
  font-family: 'Raleway';
  font-weight: 600;
  font-style: italic;
}
.edit-preferences-page p,
.edit-preferences-page p b,
.toggle_switch span {
  font-weight: 600 !important;
  font-size: 15px !important;
}
.edit-preferences-page span.yes_no,
.edit-preferences-page .sport a span,
.edit-preferences-page .calender_list a,
.edit-preferences-page .calender_list span {
  margin-left: 0px !important;
}
.edit-preferences-page .bottom-preferences span {
  font-weight: 600;
  font-size: 13px;
}
.edit-preferences-page span.yes_no {
  font-weight: 600 !important;
}
.my-membership-page ul li {
  font-size: 16px;
}
.my-membership-page .update-payment-btn {
  font-size: 15px !important;
  padding: 0px !important;
}
.my-membership-page .form-border .form-control {
  background-color: #f1f0f0;
  box-shadow: none !important;
}
.edit-preferences-page .control-group.blast-list p {
  margin-bottom: 0px;
}
.my-membership-page span {
  font-size: 16px;
}
.calendar-container .my-profile .event_body {
  line-height: 1.25;
}
.calendar-container .my-profile .event_body ul li span a {
  position: relative;
  top: 4px;
}

.profile_model .event-popup-body {
  line-height: 1.25;
}
.profile_model .event-popup-body ul li span a {
  position: relative;
  /* top: 4px; */
}

.ql-font-arial {
  font-family: 'Arial', sans-serif;
}
.ql-font-roboto {
  font-family: 'Roboto', sans-serif;
}
.ql-font-calibri {
  font-family: 'Calibri', sans-serif;
}
.ql-font-courier {
  font-family: 'Courier New', serif;
}
.ql-font-georgia {
  font-family: 'Georgia', sans-serif;
}
.ql-font-lucida {
  font-family: 'Lucida Sans Unicode', sans-serif;
}
.ql-font-open {
  font-family: 'Open Sans', sans-serif;
}
.ql-font-tahoma {
  font-family: 'Tahoma', sans-serif;
}
.ql-font-times {
  font-family: 'Times New Roman', sans-serif;
}
.ql-font-trebuchet {
  font-family: 'Trebuchet Ms', sans-serif;
}
.ql-font-verdana {
  font-family: 'Verdana', sans-serif;
}
.ql-font-raleway {
  font-family: 'Raleway', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='arial']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arial']::before {
  content: 'Arial';
  font-family: 'Arial', 'Helvetica';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='calibri']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='calibri']::before {
  content: 'Calibri';
  font-family: 'Calibri', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='roboto']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='roboto']::before {
  content: 'Roboto';
  font-family: 'Roboto', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='courier']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='courier']::before {
  content: 'Courier New';
  font-family: 'Courier New';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='georgia']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='georgia']::before {
  content: 'Georgia';
  font-family: 'Georgia', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='lucida']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='lucida']::before {
  content: 'Lucida Sans';
  font-family: 'Lucida Sans Unicode', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='open']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='open']::before {
  content: 'Open Sans';
  font-family: 'Open Sans', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='tahoma']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='tahoma']::before {
  content: 'Tahoma';
  font-family: 'Tahoma';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='times']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='times']::before {
  content: 'Times New Roman';
  font-family: 'Times New Roman';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='trebuchet']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='trebuchet']::before {
  content: 'Trebuchet MS';
  font-family: 'Trebuchet MS';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='verdana']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='verdana']::before {
  content: 'Verdana';
  font-family: 'Verdana', sans-serif;
}
.ql-picker.ql-font .ql-picker-label[data-value='raleway']::before,
.ql-picker.ql-font .ql-picker-item[data-value='raleway']::before {
  font-family: 'Raleway', cursive;
  content: 'Raleway' !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='font-size']::before {
  content: 'font-size';
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='9px']::before {
  content: '9';
  font-size: 9px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='10px']::before {
  content: '10';
  font-size: 10px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='11px']::before {
  content: '11';
  font-size: 11px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='12px']::before {
  content: '12';
  font-size: 12px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='14px']::before {
  content: '14';
  font-size: 14px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='16px']::before {
  content: '16';
  font-size: 16px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='18px']::before {
  content: '18';
  font-size: 18px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='20px']::before {
  content: '20';
  font-size: 20px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='22px']::before {
  content: '22';
  font-size: 22px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value='24px']::before {
  content: '24';
  font-size: 24px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='26px']::before {
  content: '26';
  font-size: 26px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value='28px']::before {
  content: '28';
  font-size: 28px !important;
}
.btn-close {
  border: 0px;
  height: 15px !important;
  width: 15px !important;
}
