.loader-section {
	display:flex;
	align-items: center;
	justify-content: center;
	min-height:100vh;
	margin: 0;
	background-color: rgba(0,0,0,0.6);
    width:100vw;
    position:fixed;
    top:0;
    bottom: 0;
    left:0;
    right:0;
	z-index: 999999;
}

.heart {
	background: #FF0000;
	position: relative;
	height: 40px;
	width:40px;
	/* Animation */
	transform: rotate(-45deg) scale(1);
	animation: pulse 2s linear infinite;
}


.heart::after {
	/* background:blue; */
	background:inherit;
	border-radius: 50%; /* To make circle */
	content:'';
	position:absolute;
	/* top: -100px;*/
	top: -50%; /* Inherit properties of parent */
	/* left: -100px; */
	left:0;
	height: 40px;
	width:40px;
}
.heart::before {
/* background:green; */
	background:inherit;
	border-radius: 50%; /* To make circle */
	content:'';
	position:absolute;
	top:0;
	right:-50%; /* Inherit properties of parent */
	height: 40px;
	width:40px;
}

@keyframes pulse{
	0% {
		transform: rotate(-45deg) scale(1);
		opacity: 0;
	}/*
	10% {
		transform: rotate(-45deg) scale(1.3);
	}
	20% {
		transform: rotate(-45deg) scale(0.9);
	}
	30% {
		transform: rotate(-45deg) scale(1.2);
	}
	40% {
		transform: rotate(-45deg) scale(0.9);
	}*/
	50% {
		transform: rotate(-45deg) scale(2);
		opacity: 1;
	}/*
	60% {
		transform: rotate(-45deg) scale(0.95);
	}
	70% {
		transform: rotate(-45deg) scale(1);
	} */
	100% {
		transform: rotate(-45deg) scale(1);
		opacity: 1;
	}
}
