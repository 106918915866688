@media screen and (min-width: 1441px) {
  .container {
    max-width: 1236px;
  }
  .home-page .container {
    max-width: calc(1300px - 220px);
  }
  .home-page .main-wrapper {
    max-width: calc(1440px - 220px);
  }
  .mid-section .main-wrapper {
    max-width: 1366px;
  }
}
@media screen and (max-width: 1440px) {
  .container {
    max-width: 1100px;
  }
  .mid-section .main-wrapper {
    background-color: #fff;
    max-width: 1200px;
    margin: auto;
  }
  .home-page .container {
    max-width: calc(1100px - 220px);
  }
  .home-page .main-wrapper {
    background-color: #fff;
    max-width: calc(1200px - 220px);
    margin: auto;
  }

  .edit-event {
    font-size: 14px;
  }

  .edit-event.edit_event input.form-control.sync_btn {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1366px) {
  .optimizeBox p {
    font-size: 18px !important;
  }
  h1 {
    font-size: 32px !important;
  }
  h2 {
    font-size: 32px !important;
  }
  h3 {
    font-size: 24px !important;
  }
  h4 {
    font-size: 20px !important;
  }
  h5 {
    font-size: 18px !important;
  }
  h6 {
    font-size: 16px !important;
  }
  p {
    font-size: 16px !important;
  }
  input[type='text'],
  input[type='email'],
  input[type='url'] {
    height: 34px !important;
    font-size: 14px !important;
  }
  input[type='password'] {
    height: 34px !important;
    font-size: 14px !important;
  }
  button,
  input.btn.input-btn {
    height: 44px !important;
    font-size: 14px !important;
  }
  ul.footer-links li {
    font-size: 12px;
    margin: 8px 5px;
  }
  .writing-promoting-section .ul_plans li p {
    font-size: 12px !important;
  }
  input.form-control::placeholder {
    font-size: 14px !important;
  }
  .our-members-section .members-list em {
    font-size: 16px;
  }
  select.form-control {
    height: 34px !important;
    font-size: 14px !important;
  }
  .book-release input#exampleEmail,
  .book-release .react-datepicker-wrapper {
    height: 34px;
  }
  .book-release .react-datepicker__input-container input::placeholder {
    font-size: 14px !important;
  }
  .upload_text input {
    font-size: 14px;
  }
  .home-main-page .writing-promoting-section .ul_plans li .divImg img {
    max-width: 60px;
  }
  .home-main-page .writing-promoting-section .ul_plans li p {
    font-size: 10px !important;
  }
  .home-main-page .our-members-section .members-list em {
    font-size: 15px;
  }
  .landing-page h2 {
    font-size: 32px !important;
  }
  .home-main-page .landing-page h2 {
    font-size: 30px !important;
  }

  .edit-event {
    font-size: 12px;
  }
}
@media screen and (max-width: 1266px) {
  footer .copyRight {
    font-size: 10px !important;
  }
  ul.footer-links li {
    font-size: 10px;
  }
  ul.list-unstyled.footer-links li img {
    height: 25px !important;
    width: 25px !important;
  }
  ul.list-unstyled.footer-links li {
    min-width: 25px;
  }
}

@media screen and (max-width: 991px) {
  .details-box {
    width: 100%;
  }

  .blast-details-boxes {
    flex-wrap: wrap;
  }
  .main-wrapper .sidebar {
    width: 100%;
    height: 70px;
    position: fixed;
    padding: 0;
    z-index: 5;
  }
  .header-secion {
    position: relative;
  }
  .header-secion .navbar-toggler {
    position: fixed;
    left: 30px;
    top: 15px;
    z-index: 6;
    margin: 0;
    filter: invert(1);
    padding: 0 !important;
    min-width: 30px !important;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: rgba(0, 0, 0, 0.1) !important;
    outline: 0 !important;
  }
  .header-secion .navbar.navbar-expand-lg {
    /* position: absolute;
    left: 0;
    top: 0;
    z-index: 7; */
    padding: 0 !important;
  }
  .header-secion .navbar-collapse.collapse.show
  /* , .navbar-collapse */
 {
    position: fixed;
    z-index: 100;
    padding: 15px;
    min-width: 150px;
    top: 70px;
    left: 10px;
    box-shadow: rgb(191, 191, 191) 0px 3px 5px 0px;
    background: #eae7e7 !important;
    color: #1b1e21;
  }
  .advertise-section {
    display: none !important;
  }
  .filter-section {
    flex-wrap: wrap;
  }
  .filter-section .toggle_switch .switch {
    width: 30px;
    height: 16px;
  }
  .filter-section .toggle_switch .switch .slider.round:before {
    height: 11px;
    width: 11px;
    bottom: 2.5px;
  }
  .filter-section .toggle_switch .switch input:checked + .slider:before {
    transform: translateX(14px);
  }
  .header-secion .navbar-nav li {
    position: relative;
  }
  .header-secion .navbar-nav li .dropdown-menu {
    box-shadow: rgb(191, 191, 191) 0px 3px 5px 0px;
    position: absolute;
    top: 0 !important;
    left: 85px !important;
    max-width: 150px !important;
    min-width: 130px !important;
  }
  .header-secion .navbar-nav li .dropdown-menu li .dropdown-item {
    padding: 4px 8px !important;
    font-size: 13px;
  }
  .header-secion .navbar-nav li a.nav-link {
    color: #1b1e21 !important;
    font-weight: 500;
  }
  .header-secion .navbar-nav li.nav-item.active a.nav-link {
    font-weight: 700;
  }
  .header-secion .navbar-light.bg-light .navbar-nav li.nav-item::before {
    content: none;
  }

  .noti_setting {
    z-index: 6;
    position: fixed;
    right: 0;
    top: 0;
  }
  .noti_setting .fa {
    color: #fff !important;
  }

  .main_left {
    width: 100%;
    height: 100%;
    float: none;
    padding: 0;
  }
  .main-wrapper .sidebar .logo_3 img {
    max-height: 57px;
    width: auto;
  }
  .main-wrapper .main-content {
    width: 100%;
  }
  .main-content.profile-setup {
    padding-top: 72px;
  }
  ul.ul_plans {
    grid-template-columns: auto auto auto auto;
  }
  .writing-promoting-section .ul_plans li {
    width: 100%;
  }
  .footer-badges-link ul {
    flex-direction: revert;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .footer-badges-link ul li {
    margin: 0 !important;
  }
  .footer-badges-link ul.download-links img {
    width: auto;
    max-width: 100%;
    object-fit: cover;
  }
  .footer_text {
    margin-bottom: 20px;
  }
  .my-membership-page button {
    font-weight: 700 !important;
    max-width: 150px !important;
    margin-right: 0 !important;
    margin-left: 5px !important;
  }
  .purchase-blast .blastRow2:last-child {
    margin-bottom: 10px;
  }
  .messenge-page.list-show .scrollable.content {
    width: calc(100% - 300px);
  }
}

@media screen and (max-width: 768px) {
  .messenge-page {
    flex-direction: column;
    position: relative;
  }
  .messenge-page .conversation-list {
    position: absolute;
    z-index: 1;
    /* max-width: 100%; */
    transition: all 0.3s ease-in;
    height: 100%;
  }
  .messenge-page .conversation-list.hide {
    left: -100vw;
  }
  .messenge-page .conversation-list.show {
    left: 0;
  }
  .messenge-page .conversation-list {
    max-width: 250px;
  }
  .messenge-page.list-show .scrollable.content {
    width: calc(100% - 250px);
    margin-left: 250px;
  }
  .messenge-page .scrollable.content {
    width: 100%;
    transition: all 0.3s ease-in;
  }
  .message-search-section .message-search-box {
    max-width: 100%;
    align-items: center;
  }
  .message-search-section .message-search-box input {
    max-width: calc(100% - 35px);
    margin: 0 0 0 auto !important;
  }
  .message-search-box button {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    height: 34px !important;
    background: transparent !important;
    width: 34px;
  }
  .message-search-box button i {
    position: unset;
    font-size: 28px;
    transition: all 0.3s ease-in;
  }
  .conversation-list-item .conversation-info {
    display: block;
  }
}

@media screen and (max-width: 576px) {
  .messenge-page .conversation-list {
    max-width: 100%;
  }
  .messenge-page.list-show .scrollable.content {
    width: 100%;
    margin-left: 0;
  }
}
