.button {
  width: '13%';
  margin-right: '1%';
  margin-top: '3%';
}

.calhight {
  min-height: 700px;
}

.add-btn button.btn.btn_save_bt.daily img {
  height: 20px;
  filter: invert(1);
  margin-left: 7px;
}

.add-btn button.btn.btn_save_bt.daily {
  display: flex;
  align-items: center;
  justify-content: center;
  float: none;
}
.add-btn {
  display: flex;
  justify-content: flex-end;
}
.quill button {
  min-width: auto !important;
  height: 24px !important;
  padding: 3px 5px !important;
  width: 28px !important;
}
.ql-editor {
  min-height: 125px;
}

.custom-datepicker {
  position: relative;
  width: 100%;
}

.custom-datepicker input {
  width: 100%;
  border: 1px solid #ced4da;
}

.custom-datepicker i {
  position: absolute;
  right: 10px;
  top: 10px;
  pointer-events: none;
}

.ql-font-arial                {  font-family: 'Arial'             , sans-serif;}
.ql-font-roboto               {  font-family: 'Roboto'            , sans-serif;}
.ql-font-calibri              {  font-family: 'Calibri'           , sans-serif;}
.ql-font-courier              {  font-family: 'Courier New'       , serif;    }
.ql-font-georgia              {  font-family: 'Georgia'           , sans-serif;}
.ql-font-lucida               {  font-family: 'Lucida Sans Unicode', sans-serif;}
.ql-font-open                 {  font-family: 'Open Sans'         , sans-serif;}
.ql-font-tahoma               {  font-family: 'Tahoma'            , sans-serif;}
.ql-font-times                {  font-family: 'Times New Roman'   , sans-serif;}
.ql-font-trebuchet            {  font-family: 'Trebuchet Ms'      , sans-serif;}
.ql-font-verdana              {  font-family: 'Verdana'           , sans-serif;}
.ql-font-raleway             {  font-family: 'Raleway'           , sans-serif;}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=arial]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=arial]::before {
    content: 'Arial';  font-family: 'Arial', 'Helvetica';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=calibri]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=calibri]::before {
    content: 'Calibri'; font-family: 'Calibri', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=roboto]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=roboto]::before {
    content: 'Roboto'; font-family: 'Roboto', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="courier"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="courier"]::before {
    content: 'Courier New';  font-family: 'Courier New';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=georgia]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=georgia]::before {
    content: 'Georgia';  font-family: 'Georgia', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="lucida"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="lucida"]::before {
    content: 'Lucida Sans';  font-family: 'Lucida Sans Unicode', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="open"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="open"]::before {
    content: 'Open Sans';  font-family: 'Open Sans', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=tahoma]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=tahoma]::before {
    content: 'Tahoma';  font-family: 'Tahoma';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="times"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="times"]::before {
    content: 'Times New Roman';  font-family: 'Times New Roman';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="trebuchet"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="trebuchet"]::before {
    content: 'Trebuchet MS';  font-family: 'Trebuchet MS';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=verdana]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=verdana]::before {
    content: 'Verdana';  font-family: 'Verdana', sans-serif;
}
.ql-picker.ql-font .ql-picker-label[data-value="raleway"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="raleway"]::before
{
    font-family: "Raleway", cursive;;
    content: "Raleway" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="font-size"]::before {
  content: "font-size";
  
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
  content: "9";
  font-size: 9px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10";
  font-size: 10px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="11px"]::before {
  content: "11";
  font-size: 11px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12";
  font-size: 12px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14";
  font-size: 14px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16";
  font-size: 16px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18";
  font-size: 18px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20";
  font-size: 20px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22";
  font-size: 22px !important;
}
.ql-snow .ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24";
  font-size: 24px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="26px"]::before {
  content: "26";
  font-size: 26px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="28px"]::before {
  content: "28";
  font-size: 28px !important;
}
