.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 90px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0px;
  top: 38px;
}

.dropdown-content a {
  color: black;
  padding: 3px 8px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.setup-btn .setup_event_bt.daily {
  background-color: #1f1f1f !important;
  color: #fff !important;
}

.event-filter-page button.add-calendar {
  min-width: auto !important;
  height: auto !important;
  padding: 2px 20px !important;
  font-size: 12px !important;
  background: #1f1f1f;
  color: white;
  border-radius: 25px !important;
}
.sub_notification span {
  font-weight: 700;
  margin-right: 5px;
  height: 22px !important;
  width: 22px !important;
  justify-content: center;
  align-items: center;
  line-height: 25px;
  padding: 0;
  max-width: 100% !important;
  border-radius: 50px;
  display: flex;
}
.sub_notification  p{
  font-size: 14px !important;
  width: calc(100% - 32px);
  margin-bottom: 0px !important;
}

/* .calendar-container .row .ag-cell {
  overflow: hidden;
  text-overflow: ellipsis !important;
} */
.ag-row .ag-cell:nth-child(2){
  text-overflow: ellipsis !important;
}
.ag-row .ag-cell:nth-child(3){
  text-overflow: ellipsis !important;
}