.offer-page {
  background: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../Utils//Assets//red-black-brush-stroke-banner-background.jpg);
  font-family: 'Raleway';
  position: relative;
  background-position-x: -2px;
}
.offer-page .container {
  max-width: calc(100% - 140px);
  margin-left: 140px;
}
.offer-page .logo-section {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100vh;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 18px;
  background: transparent;
}
.offer-page .logo-section img {
  /* height: 130px;
  width: auto; */
  width: 60%;
  object-fit: cover;
}
.logo-section a {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.offer-section {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-items: stretch; /* Update: Change align-items property */
  height: fit-content;
  min-height: calc(100vh - 70px);
  gap: 50px;
  position: relative;
  padding: 40px 0 40px;
}
.offer-section .offer-section-card {
  width: calc(33% - 40px);
  max-width: 350px;
  flex: 1;
}

.offer-section-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
  background: rgba(255, 255, 255, 0.5);
  padding: 15px 15px 25px;
  border-radius: 10px;
  position: relative;
  padding-top: 120px;
  /* min-height: 500px; */
}

.offer-section .main_left {
  background: #000;
  border-radius: 10px;
  width: 95px;
  height: fit-content;
  padding: 10px 20px;
  position: absolute;
  top: 15px;
}

.offer-section .logo_3 img {
  width: 100%;
}
.offer-heading {
  min-height: 69px;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.offer-section h2.heading {
  margin-bottom: 0;
  text-transform: none !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.offer-section span.heading {
  font-size: 20px;
  font-weight: normal;
  text-transform: uppercase;
}

.offer-section .discount-number {
  font-weight: 600;
  font-size: 65px;
  line-height: 1;
  color: #272525;
  /* font-family: 'Circular-Loom'; */
  font-family: 'Raleway';
  position: relative;
}

.offer-section .discount-number p {
  font-size: 48px !important;
  margin: 0;
  position: absolute;
  top: 0;
  right: -10px;
  line-height: 1 !important;
}

.offer-section .discount-text {
  font-weight: 700;
  letter-spacing: 1.5px;
  margin: 4px 0 10px;
  text-align: justify;
  text-transform: uppercase;
}

.offer-page .offer-section a {
  font-weight: 600;
  color: #3d3d3d;
}

.offer-page .offer-section a:hover,
.offer-page .offer-section a:active,
.offer-page .offer-section a:focus,
.offer-page .offer-section a:visited {
  color: #3d3d3d;
  text-decoration: none;
}

.offer-section button.offer-btn,
.offer-section a.offer-btn {
  height: 46px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000 !important;
  padding: 10px;
  font-size: 1rem;
  line-height: 1.5;
  text-transform: uppercase;
  color: #fff;
  border-radius: 8px;
  min-width: 150px;
  cursor: pointer;
  border: 0;
  /* margin: 5px 0 5px -35px; */
}

.offer-section button.offer-btn:focus,
.offer-section button.offer-btn:hover,
.offer-section a.offer-btn:hover,
.offer-section a.offer-btn:focus,
.offer-sectiona.offer-btn:active,
.offer-section a.offer-btn:visited {
  opacity: 0.8;
  border: 0 !important;
  box-shadow: none !important;
  outline: 0 !important;
  text-decoration: none;
  color: #fff;
}

.offer-section p.note {
  font-size: 12px !important;
  margin: 0;
  font-weight: 600 !important;
  position: absolute;
  bottom: 2px;
  right: 7px;
}

.offer-section p.offer-info-text {
  margin: 10px 0 0;
  font-size: 12px !important;
  line-height: 1.2 !important;
  text-align: justify;
  letter-spacing: -0.4px;
  /* min-height: 30px; */
}

.promo-offer-section p.note {
  left: unset;
  right: 7px;
}

.promo-offer-section h2.heading {
  font-size: 35px !important;
  line-height: 1.2;
}

.promo-offer-section span.heading {
  font-weight: 600;
  display: block;
}

.offer-section .clipboard-copy-section {
  display: flex;
  padding: 0;
  background: transparent;
  max-width: 186px;
  width: 100%;
  border-radius: 10px;
  margin: 10px 0;
  background: #000;
}

.offer-section .clipboard-copy-section input,
.offer-section .clipboard-copy-section input:focus,
.offer-section .clipboard-copy-section input:hover,
.offer-section .clipboard-copy-section input:active {
  background: #fff !important;
  color: #3d3d3d !important;
  outline: 0 !important;
  box-shadow: none !important;
  border: 2px solid #000 !important;
  padding: 10px 10px !important;
  width: calc(100% - 36px);
  height: fit-content !important;
  border-radius: 10px !important;
}

.offer-section .clipboard-copy-section button {
  padding: 2px 4px 0 !important;
  width: fit-content !important;
  height: fit-content !important;
  min-width: fit-content !important;
  cursor: pointer;
  font-size: 14px !important;
  background: #000 !important;
  color: #fff !important;
  border: 0 !important;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.offer-section .input_button {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.offer-section .input_button span {
  font-weight: normal;
  font-size: 8px;
  display: block;
  padding-top: 2px;
  max-width: 28px;
}

.offer-section .clipboard-copy-section button:focus,
.offer-section .clipboard-copy-section button:hover,
.offer-section .clipboard-copy-section button:active {
  background: #000 !important;
  color: #fff !important;
  border: 0 !important;
  opacity: 0.9;
  outline: 0 !important;
  box-shadow: none !important;
}

.offer-section-footer {
  background: #000 !important;
  padding: 5px;
}
.offer-section-footer p {
  color: #fff !important;
  margin-bottom: 0;
  font-size: 13px !important;
}

.offer-card-btns {
  display: flex;
  gap: 10px;
  padding-top: 10px;
}
.offer-page .footer {
  /* width: 100%; */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.offer-page .footer ul.footer-links li {
  font-size: 14px;
}
.offer-page .footer ul.footer-links li.nav-item {
  margin: 8px 4px;
}
.offer-page p.copyRight.text-white {
  /* bottom: -12px; */
  margin-top: 1.5px;
}

@media screen and (max-width: 1400px) {
  .offer-page .footer ul.footer-links li {
    font-size: 12px;
  }
}

@media screen and (max-width: 1300px) {
  .offer-page .logo-section {
    width: 110px;
    padding-top: 15px;
  }
  .offer-page .container {
    max-width: calc(100% - 110px);
    margin-left: 100px;
  }
  /* .offer-page .logo-section img {
    height: 110px;
  } */
}

@media screen and (max-width: 1200px) {
  .offer-section {
    padding-bottom: 110px;
  }
  .offer-section .offer-section-card {
    width: calc(50% - 40px);
  }
  .offer-page footer#footer {
    padding: 10px 33px;
  }
}

@media screen and (max-width: 991px) {
  .offer-page .logo-section {
    width: 100px;
    padding-top: 15px;
  }
  /* .offer-page .logo-section img {
    height: 90px;
  } */
  .offer-page .container {
    max-width: calc(100% - 100px);
    margin-left: 100px;
  }
  .offer-section button.offer-btn,
  .offer-section a.offer-btn {
    min-width: 128px;
  }
}

@media screen and (max-width: 766px) {
  .offer-section .offer-section-card {
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .offer-page .container {
    max-width: 100%;
    margin-left: 0;
  }
  .offer-page .logo-section {
    width: 70px;
    padding-top: 10px;
  }
  /* .offer-page .logo-section img {
    height: 70px;
  } */
}
