.form-group ::-webkit-input-placeholder {
  color: #1f1f1f !important;
  font-size: 16px;
  opacity: unset;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
}
.form-group ::-moz-placeholder {
  color: #1f1f1f !important;
  font-size: 16px;
  opacity: unset;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
}
.form-group ::-ms-input-placeholder {
  color: #1f1f1f !important;
  font-size: 16px;
  opacity: unset;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
}
.form-group ::placeholder {
  color: #1f1f1f !important;
  font-size: 16px;
  opacity: unset;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
}
.fam_gp ::-webkit-input-placeholder {
  color: #1f1f1f !important;
  font-size: 16px !important;
  opacity: unset;
  font-family: "Raleway", sans-serif;
  font-weight: normal !important;
}
.fam_gp ::-moz-placeholder {
  color: #1f1f1f !important;
  font-size: 16px !important;
  opacity: unset;
  font-family: "Raleway", sans-serif;
  font-weight: normal !important;
}
.fam_gp ::-ms-input-placeholder {
  color: #1f1f1f !important;
  font-size: 16px !important;
  opacity: unset;
  font-family: "Raleway", sans-serif;
  font-weight: normal !important;
}
.fam_gp ::placeholder {
  color: #1f1f1f !important;
  font-size: 16px !important;
  opacity: unset;
  font-family: "Raleway", sans-serif;
  font-weight: normal !important;
}
.info {
  width: 20px;
  margin-left: 30px;
}
.author-radio {
  width: 30px;
  margin-top: 30px;
}
/* @media (min-width: 576px){
    .modal-dialog {
      max-width: 920px;
      margin: 1.75rem auto;
  }
  } */
.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.model_cover {
  padding: 10px;
  border: 2px solid #706969;
}
.modal-title {
  font-weight: 600;
  position: relative;
  margin-bottom: 30px;
  font-size: 1.5rem;
}
.code_form::placeholder {
  color: #1f1f1f !important;
  font-size: 18px;
  opacity: unset;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
}
.author_types {
  min-height: calc(100vh - 78px);
  background-color: #fff;
  padding-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 10%;
}
.main_right {
  background: white;
}
.payment-page .btn1 {
  position: initial;
}
.payment-page .margin{
  margin-bottom: 15px;
}
.input-section{
  position: relative;
}
.form-group .input-section input{
  padding-right: 35px !important;
}
.input-section .show-pwd-dark {
  color: black;
  right: 12px;
  display: inline;
  position: absolute;
  top: 6px;
  margin-right: 0px;
  font-size: 22px;
}
.signupmain .email_s{
    background-size: 22px;
}
.signupmain .user_s{
  background-size: 15px;
}
.memebership-page .btn1 {
  position: initial;
}

.memebership-page .margin {
  margin-bottom: 0;
}

/*********membership page *****************/
/* .membership-page .author_main_s div#scroll {
  padding: 10px 5px 0px;
} */

.membership-page table#table1 {
  margin-top: 0px !important;
  margin-bottom: 0px;
  height: calc(100% - 0px);
}

.membership-page .btn1 p {
  padding: 0px;
}
.membership-page .margin {
  margin: 0;
  margin-bottom: 7px;
}
.membership-page ul li {
  text-align: left;
  margin-bottom: 0px;
  line-height: 1.4;
  font-size: 15px;
    font-weight: 400;
}
.membership-page table tr td p{
  text-align: left;
  padding: 0px;
}
.membership-page table tr th{
  height: auto;
}
.membership-page table tr td {
  padding: 5px 5px !important;
  height: 100%;
}
.membership-page table tr td  ul.text_data {
  margin-left: 18px !important;
}
.membership-page table tr td  ul.ul {
  margin-left: 5px !important;
}
.membership-page .author_main_s legend.scheduler-border {
  margin-bottom: 0px;
}
.membership-page span.select-one {
  font-size: 15px;
  /* margin-left: 9px; */
}
.membership-page .author_main_s{
  padding: 0px !important;
}
.membership-page .border-dark {
  border: 2px groove #000 !important;
}
.membership-page .check-box {
  display: flex;
  align-items: baseline;
  margin-bottom: 0px !important;
}
.membership-page .promo-code {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}

.membership-page .promo-code input {
  height: 52px !important;
  width: calc(100% - 120px);
  margin-left: auto;
  border-radius: 10px !important;
}

.membership-page .promo-code button {
  height: 52px !important;
  min-width: 100px !important;
  font-size: 18px !important;
  border-radius: 10px !important;
}
.membership-page .purchase-section p, .membership-page .purchase-section h5, .membership-page .purchase-section h6 {
  display: flex;
  justify-content: space-between;
}
.membership-page .purchase-section p span,
 .membership-page .purchase-section h5 span,
 .membership-page .purchase-section h6 span{
padding-left: 10px;
}
.membership-page .purchase-section h5{
  color: rgb(255, 88, 88);
  font-size: 14px;
}
.membership-page fieldset.scheduler-border.border-dark {
  height: 100%;
}
.membership-page fieldset.scheduler-border {
padding-bottom: 0px !important;
}
.membership-page .author_main_s p{
  padding: 0px !important;
}
.membership-page .promo-code-section p {
    margin-top: 10px;
    line-height: initial !important;
}
.membership-page .purchase-section h6 {
  margin-top: 35px;
}
.membership-page .purchase-section h6 span {
text-decoration: underline;
}
.membership-page .check-box strong.mousechange {
  color: #007bff;
}
.membership-page .auto-renewal-input {
  margin-bottom: 20px;
}

.membership-page .auto-renewal-input span:first-child {
  padding-right: 22px;
}

.membership-page .auto-renewal p i{
  padding-left: 10px;
}
.membership-page .auto-renewal p{
  margin-bottom: 0px;
}
.membership-page #scroll\ author-scroll {
  padding: 0px 10px 20px !important;
}
.membership-page .thanktwo_main{
  padding: 0px;
}
.membership-btn {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 65%;
}
.membership-page .thanktwo_main {
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  flex-wrap: wrap;
}
.membership-btn .otr-text {
  max-width: 200px;
  font-size: 10px;
  border: 1px solid #bfbcbc;
  border-radius: 5px;
  padding: 4px 10px;
  font-weight: 600;
  font-family: 'Raleway';
  line-height: 12px;
}

.membership-btn img {
  border: 1px solid #bfbcbc;
  height: 46px;
  border-radius: 5px;
}
.outer-btn{
background-color: transparent !important;
background-origin: 1px solid #000;
color: black !important;
}
.signupmain.signup-page-one .in_form {
  padding-left: 20px !important;
  border:2px groove #000!important;
  border-radius: 0px !important;
}
.signupmain.signup-page-one .radioninline_3{
  margin-top: 10px;
}
.signupmain.signup-page-one .radiobuttons{
  margin-bottom: 0px;
}
.signupmain.signup-page-one .in_title_two span{
  font-weight: bold;
}
.signupmain.signup-page-one .main_right{
  float: none !important;
}
.signupmain.signup-page-one .author_types{
  min-height: 100% !important;
}
.signupmain.signup-page-one .signuptype_footercover {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signupmain.signup-page-one .signuptype_footercover button {
  margin: 0px !important;
}
.signupmain.signup-page-one .social-buttons-in-signup .socialButton{
width: 100% !important;
}
.signupmain.signup-page-one .button_btft{
  margin-top: 100px !important;
}
.signupmain.signup-page-one .button_btft button{
  margin: 0px !important;
  float: none !important;
}
.signupmain.signup-page-one .signup-btn-group {
  margin-right: 0px;
  margin-left: 0px;
}
.signup_text.note-txt span i {
  font-weight: 100;
}
.signupmain.signup-page-one .signup-btn-group button:first-child {
  margin-left: 15px;
}
.signupmain.signup-page-one .signup-btn-group button{
  float: none !important;
  margin-top: 80px !important;
}
.signupmain.signup-page-one .signup_text{
  float: none;
}
.social-buttons-in-signup {
  display: flex;
  justify-content: space-between;
}

.social-buttons-in-signup button {
  padding: 0px !important;
}
.signupmain.signup-page-one .text-bottom{
  width: 80%;
  margin: 0px auto;
}
/* .signup-modal ul li {
  text-indent: -21px;
  margin-left: 21px;
} */

.signup-modal ul {
margin-bottom: 0;
}
