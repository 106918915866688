
::-webkit-input-placeholder {
    color: #1f1f1f !important;
    font-size: 14px;
    opacity: unset;
    font-family: 'Raleway', sans-serif;
    /* font-weight:bold; */
  }
  ::-moz-placeholder {
    color: #1f1f1f !important;
    font-size: 14px;
    opacity: unset;
    font-family: 'Raleway', sans-serif;
    /* font-weight:bold; */
  }
  :-ms-input-placeholder {
    color: #1f1f1f !important;
    font-size: 14px;
    opacity: unset;
    font-family: 'Raleway', sans-serif;
    /* font-weight:bold; */
  }
  .mr-sm-2::placeholder {
    color: #1f1f1f !important;
    font-size: 14px;
    opacity: unset;
    font-family: 'Raleway', sans-serif;
    /* font-weight:bold; */
  }

  .left-logo {
    width: 100%;
  }
  .signup-btn {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
ul.download-links {
  margin-bottom: 0px;
}
.beta-agreement p {
  font-size: 14px !important;
  font-weight: 500;
}
.beta form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.beta .beta-btn{
display: flex;
}
.beta .contact-form label {
  font-size: 16px;
  margin-bottom: 0px;
}
.beta .content {
  padding: 0px 20px;
}
.beta-main-page footer {
  background: none !important;
}
.beta li::marker {
  font-family: 'Calibri';
  font-size: 16px;
}
.landing-page.home-page h2 {
  text-transform: capitalize !important;
}
.home-main-page .writing-promoting-section .ul_plans li .divImg img {
  max-width: 60px;
}
.home-main-page .writing-promoting-section .ul_plans li p {
  font-size: 13px !important;
}