/* #footer {
  position: absolute;
  bottom: 0;
  width: 100%;
} */
.links {
  color: white;
  outline: none !important;
}
.divbutton {
  width: 55%;
  /* margin-left: 26%; */
}
#footer .divbutton {
  min-width: 150px;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* padding-bottom: 15px; */
  /* padding-bottom: 10px; */
}
.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}
p.copyRight.text-white {
  margin-bottom: 0px;
  position: absolute;
  left: 0px;
  /* position: absolute;
  position: absolute;
  bottom: -25px; */
}
.footer-btn {
  display: flex;
  justify-content: space-between;
}

.footer-btn .divbutton.float-md-right {
  margin-right: 30px;
}
ul.footer-links li.nav-item.active a {
  color: #f40101 !important;
  text-decoration: underline;
  font-weight: 600;
}
ul.footer-links li.nav-item a:hover {
  color: #f40101 !important;
}

.input-disable {
  position: relative;
}

.input-disable span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  #footer {
    position: relative;
  }
}
.img {
  width: 100%;
}
.target-plan {
  display: flex;
  align-items: center;
}
.target-plan p {
  margin-bottom: 0px;
}
