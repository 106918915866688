.form-group ::-webkit-input-placeholder {
  color: #1f1f1f !important;
  font-size: 16px;
  opacity: unset;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}
.form-group ::-moz-placeholder {
  color: #1f1f1f !important;
  font-size: 16px;
  opacity: unset;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}
.form-group ::-ms-input-placeholder {
  color: #1f1f1f !important;
  font-size: 16px;
  opacity: unset;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}
.form-group ::placeholder {
  color: #1f1f1f !important;
  font-size: 16px;
  opacity: unset;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}
.fam_gp ::-webkit-input-placeholder {
  color: #1f1f1f !important;
  font-size: 16px !important;
  opacity: unset;
  font-family: 'Raleway', sans-serif;
  font-weight: normal !important;
}
.fam_gp ::-moz-placeholder {
  color: #1f1f1f !important;
  font-size: 16px !important;
  opacity: unset;
  font-family: 'Raleway', sans-serif;
  font-weight: normal !important;
}
.fam_gp ::-ms-input-placeholder {
  color: #1f1f1f !important;
  font-size: 16px !important;
  opacity: unset;
  font-family: 'Raleway', sans-serif;
  font-weight: normal !important;
}
.fam_gp ::placeholder {
  color: #1f1f1f !important;
  font-size: 16px !important;
  opacity: unset;
  font-family: 'Raleway', sans-serif;
  font-weight: normal !important;
}
/* .author_profile{
    height:-webkit-fill-available;
    background-color:#fff;
    padding-bottom:30px;
  } */
.addMore {
  width: 20px;
  margin-left: 10px;
}
.raemail,
.vendor-openings .vco-openings {
  margin-left: 3%;
  font-weight: 500 !important;
}
.vendor-openings .vco-openings:first-child {
  margin-left: 5%;
}
.raemail:hover,
.raemail:hover input[type='radio'],
.vendor-openings .vco-openings:hover,
.vendor-openings .vco-openings:hover input[type='radio'] {
  cursor: pointer;
}
.react-datepicker-wrapper {
  display: block;
}
.react-datepicker__input-container {
  display: block;
}
.form-group.event-page-btn {
  display: flex;
  justify-content: flex-end;
}
/* .edit_event_page .form-group {
  margin-bottom: 0;
} */
