#table1 {
  text-align: left;
  position: relative;
  border-collapse: collapse;
}

#table1 th.stickhead1 {
  /* position: sticky; */
  position: unset;
  top: 0; /* Don't forget this, required for the stickiness */
}

#table1 th.stickhead {
  background: white;
  position: unset;
  /* position: sticky; */
  top: 0; /* Don't forget this, required for the stickiness */
}

#table1 .headtitle {
  vertical-align: middle;
  padding: 8px;
  font-size: 22px;
}

#table1 th.headDescription {
  padding: 8px !important;
  vertical-align: middle;
}

#table1 .sub_head {
  font-size: 12px !important;
  margin: 0px !important;
  font-weight: 700 !important;
  line-height: 1.3;
}

#table1 .headtitle h5 {
  margin: 0px !important;
}

/*Custom CSS Start*/
.message-list .toolbar,
.conversation-list .toolbar {
  background-color: transparent;
}
.calendar-container-dark
  .conversation-list
  .toolbar
  .left-items
  .btn
  .toolbar-button,
.calendar-container-dark
  .conversation-list
  .toolbar
  .right-items
  .btn
  .toolbar-button,
.calendar-container-dark
  .message-list
  .toolbar
  .right-items
  .btn
  .toolbar-button {
  color: #fff;
}
.calendar-container-dark #table1 thead th.stickhead {
  background: #202020;
}
.calendar-container-dark #table1 thead tr:first-child #border {
  border: 0 !important;
}
.calendar-container-dark #table1 #border1 {
  border: 0 !important;
  border-right: 1px solid #dee2e6 !important;
}
.calendar-container-dark #table1 .thirdtr th.stickhead,
.calendar-container-dark #table1 .fourthtr th.stickhead {
  border-top: 1px solid #202020 !important;
}
.calendar-container-dark .black {
  color: #fff;
}
#table1 {
  font-family: Calibri;
  width: 100%;
  text-align: center;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  border-collapse: separate;
  border: 0px;
}

#table1 th,
#table1 td {
  border: 0px solid #dee2e6 !important;
  padding: 0.75rem 4px;
}

#table1 th {
  /* Apply both top and bottom borders to the <th> */
  padding: 5px 4px;
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
}

#table1 td {
  border-bottom: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
}

#table1 th:first-child,
#table1 td:first-child {
  border-left: 1px solid #dee2e6 !important;
}

#table1 #border {
  border-bottom: 0 !important;
  border-color: white !important;
}

#table1 #border1 {
  border-color: white #d3d3d3 white white !important;
  margin: 0%;
  padding: 0%;
}

#table1 tr.fourthtr th.stickhead {
  border-bottom: 2px solid #dee2e6 !important;
}

#table1 .hiderow {
  display: none;
}

#table1 .headele {
  padding: 14px 5px !important;
  margin-bottom: 5px;
}

#table1 .headele .btn1 {
  top: 10px !important;
  bottom: auto !important;
}

/* #table1 .headele .item1{
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-column-gap: 4px;
  align-items: baseline;
} */
.text-italic {
  display: contents !important;
}

#table1 .headele .item1 > strike {
  justify-self: right;
  position: relative;
  text-decoration: none;
}
#table1 .headele .item1 > strike::before {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #202020;
  top: 40%;
  transform: rotate(17deg);
}
#table1 .headele .item1 > strike.red::before {
  background-color: #ff5858;
}
#table1 .headele .item1 > strike.orange::before {
  background-color: #ff8f60;
}
#table1 .headele .item1 > strike.yellow::before {
  background-color: #fbdb69;
}
#table1 .headele .item1 > strike.green::before {
  background-color: #65ff98;
}
#table1 .headele .item1 > strike.blue::before {
  background-color: #6ca8ff;
}
#table1 .headele .item1 > strike.purple::before {
  background-color: #a062ff;
}
#table1 .headele .item1 > strike.pink::before {
  background-color: #ff6fde;
}
#table1 .headele .item1 > .price_1 {
  justify-self: left;
}

#table1 .headele .item1 span.freeclass {
  justify-self: left;
  font-size: 10pt;
}

#table1 .headele .item2 {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 5px;
}

#table1 .secondtr th.stickhead,
#table1 .thirdtr th.stickhead {
  border-bottom: 1px solid #f5f5f5;
}

#table1 .thirdtr th.stickhead,
#table1 .fourthtr th.stickhead {
  border-top: 1px solid #f5f5f5 !important;
  border-top: 1px solid #fff !important;
}

#table1 tr .details1 {
  text-align: left;
}

#table1 .text_data2 {
  font-size: 15px !important;
  line-height: 1.2 !important;
  margin-left: 8px;
  list-style-type: square;
  padding: 0px;
}

#table1 .marging-10 {
  margin-top: 15px;
}

#table1 .marging-5 {
  margin-top: 8px;
}

#table1 .text_data2 > li {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 4px;
  margin-bottom: 15px;
  font-weight: bold;
  align-items: baseline;
  font-family: 'Raleway';
}

#table1 .text_data2 > li > i.fa-plus-circle {
  font-size: 18px !important;
  margin-top: -3px;
}

#table1 .text_data2 > li > i.fa-info-circle {
  align-self: end;
  font-size: 18px;
}

#table1 li > i.fa-info-circle {
  color: darkgray;
}

#table1 .text_data {
  font-size: 15px !important;
  line-height: 1.2 !important;
  /* margin-left: 0px; */
  font-family: 'Raleway' !important;
  font-weight: 400 !important;
  margin-bottom: 10px;
}

#table1 ul.text_data {
  margin-left: 13px;
  /* margin-left: 4px; */
}

.text_data li {
  margin-bottom: 10px;
  /* margin-left: 5px; */
}

#table1 .text_data3 {
  font-size: 12px !important;
  line-height: 1.2 !important;
  margin-left: 0px;
  list-style-type: square;
  padding: 5px;
  font-weight: 600;
  font-family: 'Raleway';
}

#table1 ul.text_data3 {
  margin-left: 10px !important;
  margin-bottom: 0px !important;
}

#table1 .text_data4 {
  font-size: 8pt !important;
  line-height: 1.2 !important;
  margin-left: 0px;
  font-style: italic;
}

#table1 td > i.fa-check-circle,
#table1 td > i.fa-times-circle {
  font-size: 24px !important;
}

#table1 .alignMiddle {
  vertical-align: middle;
}

#table1 #border1 h6 {
  text-align: right !important;
  font-size: 13pt;
  margin-right: 10px;
}

#table1 #border1 {
  vertical-align: middle;
}

/*Custom CSS End*/

#table1 {
  table-layout: fixed;
  width: 100%;
}
.sub_heading {
  padding: 5px 25px;
  font-size: 16px;
}
.best_value {
  font-size: 13pt;
  font-weight: bold;
}
#tr {
  height: 2px;
}
.border_round {
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.main_head {
  font-size: 18pt;
  font-weight: bold;
}
.sub_head {
  font-size: 10pt;
  font-weight: bold;
  margin-bottom: 130px;
}
.price_1 {
  font-size: 13px;
  font-weight: bold;
  line-height: 0%;
}
.launch {
  font-size: 6pt;
  font-weight: bold;
}
.usd {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Raleway';
}
i .usd {
  font-weight: bold;
  color: #ea0000;
}
.equal-width {
  width: 90%;
}
.head {
  position: relative;
}
.text_data {
  font-size: 9pt;
  margin: 0%;
  padding: 0%;
  margin-left: 5px;
  list-style-type: square;
}

.text_data1 {
  font-size: 8pt;
  font-style: italic;
  margin: 0%;
  padding: 0%;
  margin-left: 15px;
}
#heading {
  vertical-align: top;
  margin: 0%;
  padding: 0%;
}
.title {
  font-size: 13pt;
  font-weight: bold;
}
.btn1 {
  text-align: center;
  position: absolute;
  bottom: 12px;
  width: 100%;
  left: 0;
}
.btn {
  min-width: 150px;
}
.margin {
  margin-top: 10px;
  margin-bottom: 80px;
}
.margin1 {
  margin-bottom: 95px;
  margin-top: 5px;
}
.red {
  color: rgb(255, 88, 88);
}
.btn-red {
  background-color: rgb(255, 88, 88);
}
.orange {
  color: rgb(255, 143, 96);
}
.btn-orange {
  background-color: rgb(255, 143, 96);
}
.yellow {
  color: rgb(251, 219, 105);
}
.btn-yellow {
  background-color: rgb(251, 219, 105);
}
.green {
  color: rgb(101, 255, 152);
}
.btn-green {
  background-color: rgb(101, 255, 152);
}
.blue {
  color: rgb(108, 168, 255);
}
.btn-blue {
  background-color: rgb(108, 168, 255);
}
.purple {
  color: rgb(160, 98, 255);
}
.btn-purple {
  background-color: rgb(160, 98, 255);
}
.pink {
  color: rgb(255, 111, 222);
}
.btn-pink {
  background-color: rgb(255, 111, 222);
}
.black {
  color: black;
}
.text {
  font-size: 10px;
}
.button {
  border-radius: 20px;
  padding: 5px 15px;
  color: white;
  border: none;
  font-size: 10.5pt;
}
.button:hover {
  text-decoration: none;
  color: white;
}

.ul {
  list-style-type: none;
  font-size: 9pt;
  font-weight: bold;
  margin: 0%;
  padding: 0%;
}
.cross {
  /* background-color: white; */
  color: darkgray;
  border-radius: 50%;
}
.header-tag {
  text-align: center;
  color: white;
  margin: 0;
  padding: 0;
}
#border {
  border-bottom: 0;
  border-color: white;
  padding: 0;
  margin: 0;
}
#border1 {
  border-color: white #d3d3d3 white white;
  margin: 0%;
  padding: 0%;
}
#border2 {
  border-bottom-width: 5px;
  border-color: rgb(255, 88, 88) #d3d3d3;
}
#border3 {
  border-bottom-width: 5px;
  border-color: rgb(255, 143, 96) #d3d3d3;
}
#border4 {
  border-bottom-width: 5px;
  border-color: rgb(251, 219, 105) #d3d3d3;
}
#border5 {
  border-bottom-width: 5px;
  border-color: rgb(101, 255, 152) #d3d3d3;
}
#border6 {
  border-bottom-width: 5px;
  border-color: rgb(108, 168, 255) #d3d3d3;
}
#border7 {
  border-bottom-width: 5px;
  border-color: rgb(160, 98, 255) #d3d3d3;
}
#border8 {
  border-bottom-width: 5px;
  border-color: rgb(255, 111, 222) #d3d3d3;
}
.fa-info-circle {
  cursor: pointer;
}

.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  border-color: inherit;

  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}
/* .author_profile .text_data {
  padding: 5px;
} */
.main_right.author_profile p.sub_heading {
  text-align: center;
}
.main_right.author_profile #table1 .thirdtr th.stickhead,
.main_right.author_profile #table1 .fourthtr th.stickhead {
  border-top: none !important;
  /* border-bottom: none !important; */
}
.main_right.author_profile #table1 th {
  border-bottom: none !important;
}

.main_right.author_profile th.head.stickhead.headele p {
  margin-bottom: 5px;
  font-family: 'Calibri' !important;
}
.main_right.author_profile .usd {
  font-size: 11pt;
  font-weight: bold;
}
.main_right.author_profile .price_1 {
  font-size: 12pt;
}
.main_right.author_profile th.head.stickhead.headele .button {
  border-radius: 20px;
  padding: 6px 20px;
  color: white;
  border: none;
  font-size: 12.5pt;
}
.main_right.author_profile #table1 .headele {
  padding: 0px 5px 14px !important;
  margin-bottom: 5px;
}
.main_right.author_profile th.head.stickhead.headtitle h3 {
  margin-bottom: -5px;
}

@media only screen and (max-width: 1670px) {
  .main_head {
    font-size: 22px !important;
}
}
@media only screen and (max-width: 1600px) {
  .main_head {
    font-size: 20px !important;
}
}
@media only screen and (max-width: 1366px) {
  #scroll {
    overflow-x: auto;
  }
  #table1 {
    /* min-width: 1026px; */
    table-layout: auto;
  }
}

@media only screen and (max-width: 1300px) {
  #reader {
    padding: 0;
    margin: 0;
  }
}
@media only screen and (max-width: 650px) {
  #table1 {
    table-layout: auto;
    width: 100%;
  }
  .messenge-page .conversation-list {
    max-width: 250px;
  }
  .messenge-page.list-show .scrollable.content {
    width: calc(100% - 250px);
    margin-left: 250px;
  }
}


.accordion__button,
.accordion__panel {
  color: #202020 !important;
}
.membership-plan-page p.sub_heading {
  padding: 0px;
  text-align: left !important;
}
h6.text-left.mt-5.title {
  margin-top: 0px !important;
}
.form-group.middle-text.col-md-12.blast-msg-grp.desktop-change
  .ck-restricted-editing_mode_standard.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  height: auto;
  min-height: 150px;
}
.desktop .fa-eye:before {
  content: '\f06e';
  color: darkgray;
}
.desktop i.fa.fa-eye-slash.pull-right.show-pwd-dark-change {
  color: darkgray !important;
}
.desktop-view label.col-md-5 {
  margin-top: 6px;
}
.desktop .sticky-row.fourthtr {
  top: 131px;
}
button[disabled='true'] {
  cursor: not-allowed;
}
