.main-wrapper {
  display: flex;
  justify-content: space-between;
  /* align-items: flex-start; */
  height: 100%;
}

.main-wrapper .sidebar {
  width: 220px;
  background-color: #1f1f1f;
  /* height: 100vh; */
  /* position: fixed; */
}

.main-wrapper .main-content {
  width: calc(100% - 220px);
  min-height: 100vh;
  height: 100%;
  margin-left: auto;
}

.main-wrapper .middle-content {
  /* min-height: calc(100vh - 149px); */
  min-height: calc(100vh - 134px);
  /* min-height: calc(100vh - 130px); */
  height: 100%;
  position: relative;
}

.dark-bg {
  background-color: #202020;
}

.header-secion nav.navbar {
  box-shadow: none !important;
}
.main-content .footer {
  width: calc(100% + 220px);
  margin-left: -220px;
  z-index: 99999;
}
.main-content.remove-header .middle-content {
  min-height: calc(100vh - 60px) !important;
}
.userway-s3-1 .header-secion,
.userway-s3-1-ff .header-secion {
  filter: invert;
}
.advertise-img .react-multi-carousel-list {
  height: 100%;
}
.advertise-img .react-multi-carousel-list button {
  min-width: fit-content !important;
  position: absolute;
  height: auto !important;
  min-height: auto;
  border: 0 !important;
  outline: 0 !important;
}

.advertise-img .react-multiple-carousel__arrow::before {
  font-size: 15px !important;
}
