.compose {
  padding: 10px;
  display: flex;
  align-items: center;
  background: white;
  border-top: 1px solid #eeeef1;
  position: fixed;
  bottom: 0px;
}

.compose textarea,
.compose input[type='text'] {
  width: 100%;
  overflow: hidden !important;
  padding-left: 40px;
}

@supports (backdrop-filter: blur(20px)) {
  .compose {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
  }
}

.compose-input {
  flex: 1;
  /* border: none; */
  font-size: 14px;
  height: 40px;
  background: none;
  border: 1px solid #000;
  border-bottom: 0 !important;
  border-left: 0 !important;
  font-weight: 500;
}

.compose-input::placeholder {
  opacity: 0.3;
}

.compose .toolbar-button {
  color: #dd0e0e;
  margin-left: 15px;
}

.compose .toolbar-button:hover {
  color: #99999c;
}

.compose input.compose-input {
  border: 1px solid #ccc;
}
.compose-dark input.compose-input {
  color: #000;
  border-top: 1px solid #000 !important;
  border-radius: 0 !important;
  height: 41px !important;
  overflow: hidden !important;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 30px;
}
.compose-dark input.compose-input::placeholder {
  color: #000 !important;

}
.compose button.btn {
  font-size: 0rem;
}

.compose button.btn i {
  margin: 0;
}
.compose section.emoji-mart-search button {
  top: 2px;
}
.compose.compose-dark .emoji-mart {
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
  position: absolute;
  bottom: 0px;
  width: 100% !important;
}
.compose.compose-dark {
  position: relative;
}
.emoji-mart-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #919191;
}

.emoji-mart-scroll::-webkit-scrollbar {
  width: 0px;
  background-color: #7c7c7c;
}

.emoji-mart-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #868686;
}
.compose .emojipicker {
  cursor: pointer;
  position: absolute;
  left: 0;
  background: white;
  padding: 5px;
}
.compose-input {
  border: 1px solid #000;
  border-bottom: 0 !important;
  border-left: 0 !important;
}
.message-list .compose .btn {
  border-top: 1px solid #000;
}
.messenge-page .compose.compose-dark button:focus {
  outline: none;
  box-shadow: none;
}
.message-list-container .message {
  margin-bottom: 10px;
}
.message-list-container .message.mine {
  margin-bottom: 0;
}
.message-list-container .message .message-time {
  font-size: 10px;
  text-align: left;
  font-weight: 500;
}
.message-list-container .message.mine .message-time {
  text-align: right;
}
