.Addbackcolour {
  background-color: #1f1f1f;
}
.blast-purchase .modal-title:after {
  display: none !important;
}
.blast-purchase .eventModal.modal-header {
  padding: 5px !important;
  display: none;
}
.input-text input {
  margin-right: 5px;
  margin-bottom: 10px;
}

.input-text {
  display: flex;
  align-items: flex-start;
}
.blast-purchase-btn button {
  width: 100%;
  border-radius: 40px;
  padding: 8px;
  border: 2px solid black;
}
.upgrade-btn button:first-child {
  border: 2px solid #a062ff !important;
  pointer-events: none;
}
.add-on-btn button:first-child {
  border: 2px solid #6ca8ff;
  pointer-events: none;
}

.blast-purchase-btn button:first-child {
  background-color: transparent;
  border: 2px solid red;
  color: black;
  padding: 8px;
}
.blast-purchase-btn {
  margin-top: 10px;
}
.logo-blast img {
  height: 100px;
  width: 100%;
  object-fit: contain;
  margin: 0px auto 15px;
}
.blast-purchase h6 {
  text-align: left;
  /* margin-bottom: 15px; */
}
.author-premium-popup .add-on-btn button:first-child {
  border: 2px solid #a062ff !important;
}
.author-popup .add-on-btn button:first-child {
  border: 2px solid #6ca8ff !important;
}
.blogger-popup .add-on-btn button:first-child {
  border: 2px solid #ff8f60 !important;
}
.blogger-premium-popup .add-on-btn button:first-child {
  border: 2px solid #fbdb69 !important;
}
.reader-popup .add-on-btn button:first-child {
  border: 2px solid #ff5858 !important;
}
.vendor-popup .add-on-btn button:first-child {
  border: 2px solid #65ff98 !important;
}
.pr-firm-popup .add-on-btn button:first-child {
  border: 2px solid #ff6fde !important;
}
.btn-align {
  align-self: center;
}
.agree-btn {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}
