.toolbar-button {
  color: #000;
  font-size: 28px;
  transition: all 0.1s;
}

.toolbar-button:hover {
  cursor: pointer;
  color: #000;
}

.toolbar-button:active {
  color: #000;
  opacity: 0.25;
}

.right-items button.btn, .left-items button.btn {
  background: none;
}