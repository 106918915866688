.conversation-list-item {
  display: flex;
  /* align-items: flex-start; */
  padding: 10px;
  align-items: center;
}

.conversation-list-item:hover {
  background: #eeeef1;
  cursor: pointer;
}

.conversation-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.conversation-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}

.conversation-snippet {
  font-size: 14px;
  color: #888;
  margin: 0;
}

.convCount {
  /* position: absolute; */
  right: 20px;
}
.active-list {
  background-color: #9e9e9e !important;
  margin: 5px 0px;
}
