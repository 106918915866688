.advertise.advertise-img {
  margin: 0;
  padding: 0;
  margin-top: 25px;
}

.advertise-img img.ad-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block !important;
}
.advertise-section {
  display: flex !important;
  justify-content: center;
}

.max-word {
  font-size: 16px !important;
}

.book-signing-me {
  margin-right: 5px;
}
