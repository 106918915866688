@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Calibri';
  src: local('Rajdhani'),
    url(./Utils/Assets/fonts/CALIBRI.ttf) format('truetype');
}

html {
  height: 100vh;
}

body {
  font-family: 'Raleway', sans-serif;
  min-height: 100vh;
}
ul {
  padding-left: 17px;
  list-style-type: square;
}

.footer-padding {
  background-color: #1f1f1f;
  height: 100%;
  min-height: 100vh;
  position: relative;
  /* padding-bottom: 30px; */
}
.advertise {
  background-color: transparent;
  width: 200px;
  height: 400px;
  margin-top: 40px;
}

.bg-light {
  background-color: #ededed !important;
}

.logo img {
  width: 150px;
}

.logo_two img {
  width: 180px;
}

.logo {
  display: table;
  margin: 0 auto;
}
.logo_3 a {
  outline: 0 !important;
}
.logo_3 img {
  width: 60%;
  outline: 0 !important;
}

.logo_3.my-5 {
  padding: 30px;
}

.loader-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  /* opacity: 0.8; */
}

.loader-container div {
  top: 33%;
  margin: auto;
}

.form_gp_lft .form-control {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #8f8f8f;
  border-radius: 0;
  padding-left: 0;
  color: #fff;
}

.main_left {
  width: 220px;
  float: left;
  height: 100%;
  padding-top: 10px;
}

.main_right {
  width: 100%;
  float: left;
  background-color: white;
  /* height:auto; */
}

.container-fluid .signupmain {
  overflow-y: auto;
  height: 100%;
  height: -webkit-fill-available;
}

.form_gp_lft .form-control:focus {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  outline: 0;
  box-shadow: unset;
  border: 0;
  border-bottom: 1px solid #8f8f8f;
}

.form_gp_rgyt .form-control {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #8f8f8f;
  border-radius: 0;
  padding-left: 0;
  color: #fff;
}

.form_gp_rgyt .form-control:focus {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  outline: 0;
  box-shadow: unset;
  border: 0;
  border-bottom: 1px solid #8f8f8f;
}

.btn_login {
  background-color: #fff;
  color: #1f1f1f;
  font-weight: bold;
  text-transform: uppercase;
}

.email {
  background-image: url(../src/Utils/Assets/mail.png);
  background-repeat: no-repeat;
  color: #fff;
  background-position: right;
  background-size: 30px;
}

.email_s {
  background-image: url(../src/Utils/Assets/email_s.png);
  background-repeat: no-repeat;
  /* color: #fff; */
  color: #1f1f1f;
  background-position: right 3% bottom 50%;
  background-size: 30px;
  padding-right: 10px;
}

.password_s {
  /* background-image: url(../src/Utils/Assets/password_s.png); */
  background-repeat: no-repeat;
  /* color: #fff; */
  color: #1f1f1f;
  background-position: right 3% bottom 50%;
  background-size: 20px;
}

.user_s {
  background-image: url(../src/Utils/Assets/user_s.png);
  background-repeat: no-repeat;
  /* color: #fff; */
  color: #1f1f1f;
  background-position: right 3% bottom 50%;
  background-size: 20px;
}

.note_s {
  float: left;
  width: 100%;
  padding: 0 15px;
  color: #1f1f1f;
}

select.form-control:not([size]):not([multiple]) {
  height: 55px;
  color: #1f1f1f;
  font-weight: 600;
}

.button_btft {
  /* float: right; */
  width: 100%;
  padding: 10px;
  margin-top: 10%;
}

.form_gp_rgyt {
  margin-left: 20px;
}

.form_gp_lft {
  margin-right: 20px;
}

.password {
  /* background-image: url(../src/Utils/Assets/lock.png); */
  background-repeat: no-repeat;
  color: #fff;
  background-position: right;
  background-size: 20px;
}

.custom-control {
  display: table;
  /* margin: 0 auto; */
}

/* .social li {
  list-style-type: none;
  display: inline-block;
  padding: 0 10px;
} */

.social li a img {
  width: 50px;
}

footer {
  background-color: rgb(31, 31, 31);
  /* padding: 20px 50px; */
  padding-bottom: 0px;
  /* min-height: 75px; */
  padding: 10px 50px;
  min-height: 60px;
}

.footer_contant {
  display: table;
  margin: 0 auto;
}

.footer_contant span {
  margin-right: 10px;
}

.btn_signup {
  margin-left: 10px;
  background-color: transparent;
  border: 1px solid #fff;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  padding: 5px 20px;
}

.btn_h_login {
  margin-left: 10px;
  background-color: rgb(0, 0, 0);
  border: 1px solid #1f1f1f;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  padding: 5px 20px;
  border-radius: 0;
}

.social {
  padding-left: 0;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  list-style-type: none;
}

img.video_img {
  width: 100%;
}

.video {
  margin-bottom: 20px;
}

.hedv_text {
  /* margin-top: 10px; */
  margin-bottom: 10px;
  text-align: center;
  font-weight: 600;
}

.welcome-text .links {
  color: #1f1f1f;
}

ul.footer-links li {
  display: inline;
  margin: 8px 6px;
  color: #fff;
  font-size: 16px;
}

.footer_text {
  text-align: center;
}

.form_cust_hed {
  background-color: transparent;
  border-color: #acacac;
}

.checkbox_label {
  display: inline-block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: calc(17px * 0.125) solid #fff;
  border-radius: 12.5%;
  transition: 400ms 100ms ease-out;
  float: left;
}

.checkbox_label_black {
  display: inline-block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: calc(17px * 0.125) solid black;
  border-radius: 12.5%;
  transition: 400ms 100ms ease-out;
  float: left;
}

.checkbox_label_black .mark {
  background-color: white !important;
  border-right: calc(9px * 0.25) solid black !important;
  border-bottom: calc(9px * 0.25) solid black !important;
}
.calendar-dark .checkbox_label_black .mark {
  background-color: #202020 !important;
  border-right: calc(9px * 0.25) solid #fff !important;
  border-bottom: calc(9px * 0.25) solid #fff !important;
}
.custom-checkbox p {
  float: left;
  margin-left: 10px;
  margin-bottom: 0;
}

.checkbox_label:hover {
  border-color: #466fb3;
}

input[type='file'] {
  float: left;
  width: 50%;
}

input[type='checkbox'] {
  /* position: absolute;
  opacity: 0; */
  left: -1000px;
  vertical-align: text-top;
  /* margin-right: 2%; */
  margin-bottom: 10%;
}

.mark {
  position: relative;
  right: calc(20px * -0.5);
  top: calc(50px * -0.2);
  width: calc(40px * 0.24);
  height: calc(28px * 0.74);
  border-right: calc(9px * 0.25) solid #ffffff;
  border-bottom: calc(9px * 0.25) solid #ffffff;
  transform: rotate(45deg) scale(0);
  opacity: 0;
  transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.5);
}

input[type='checkbox']:checked + label .mark {
  opacity: 1;
  transform: rotate(45deg) scale(1);
}

.mark,
mark {
  padding: 0.2em;
  background-color: #1f1f1f;
}

input[type='checkbox']:focus + label {
  animation: 400ms linear cb-pop;
}

@keyframes cb-pop {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.9);
  }
  66% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.progressbar {
  counter-reset: step;
  width: 100%;
  padding: 30px 0;
  margin-bottom: 0;
  float: none;
  display: flex;
}

.progressbar li {
  list-style-type: none;
  width: 19.33%;
  float: none;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}

.progressbar li:before {
  width: 25px;
  height: 25px;
  content: '';
  line-height: 30px;
  border: 6px solid #a6a6a6;
  background-color: #ffffff;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  transition: all 0.8s;
  z-index: -662;
}

.progressbar li:after {
  width: 93%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #a6a6a6;
  top: 12px;
  left: -46%;
  transition: all 0.8s;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active:before {
  border: 6px solid #a6a6a6;
  background-color: #000;
  transition: all 0.8s;
}

.progressbar li.active:after {
  background-color: #000;
  transition: all 0.8s;
  z-index: 1;
  width: 94%;
  left: -47%;
}

.headre_progress {
  width: 100%;
  float: none;
  background-color: #ededed;
}

.signup_text {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 80px;
  color: #1f1f1f;
  font-weight: bold;
  font-size: 15px;
}

.signup_text.note-txt {
  margin-top: 0px;
  /* margin-left: 10%;
  margin-right: 10%; */
  width: auto;
}

.signup_text.note-txt {
  margin-top: 0px;
  /* margin-left: 10%;
  margin-right: 10%; */
  width: auto;
}

.signup_text.note-txt span {
  font-weight: 100;
  display: block;
}

.code_form {
  width: 25%;
  height: 55px;
}

.form-group_code {
  margin-bottom: 1rem;
  margin: 0 auto;
  display: table;
  width: 35% !important;
}

.code_form {
  width: 100%;
  height: 55px;
}

.code_main {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.btn_group_submit {
  float: left;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

button.btn.btn-lg.btn-block.btn_submit {
  width: 15%;
  background-color: #1f1f1f;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}

.bottom_btn {
  float: left;
  width: 100%;
  margin-top: 50px;
}

.btn_resend {
  margin-left: 10px;
  background-color: transparent;
  border: 1px solid #000;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  padding: 5px 20px;
}

.btn_reset {
  background-color: transparent;
  border: 1px solid #000;
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
}

.signup_text p {
  line-height: 33px;
  letter-spacing: 1px;
  font-size: 17px !important;
}

.setup_p_hed {
  /* background-color: #1f1f1f; */
  padding: 0;
  height: 0;
}

.setup_p_hed h4 {
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  color: #1f1f1f;
  float: left;
}

.form_custom {
  float: left;
  width: 100%;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.form_custom .form-control {
  width: 100%;
  height: 55px;
  margin-bottom: 10px;
}

input[type='button'] {
  background: #ededed;
  color: black;
  cursor: pointer;
  box-shadow: 0px 0px 1px #351a1afc;
}

.full_title {
  float: left;
  width: 100%;
  padding: 0 27px;
}

.lft {
  float: left;
  width: 50%;
  padding: 0 15px;
}

.rght {
  width: 50%;
  float: left;
  padding: 0 15px;
  min-height: 50px;
}

.in_form_control {
  border: 0;
  background-color: transparent;
  border-bottom: 1px solid #acacac;
  border-radius: 0;
}

.in_form {
  border: 2px solid #acacac;
  padding: 20px;
  border-radius: 6px;
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 20px;
  padding-left: 0;
}

.in_title {
  position: absolute;
  top: -12px;
  background-color: #fff;
  padding: 0 10px;
  font-weight: 600;
  font-size: 16px;
}

.in_title p {
  color: #1f1f1f;
  margin: 0;
}

.ft_text {
  margin-top: 10px;
  text-align: right;
  float: right;
  width: 100%;
}

.ft_text span {
  float: left;
}
.add-more-btn span:hover {
  cursor: pointer;
  opacity: 0.9;
}
.add-more-btn span:hover img {
  opacity: 0.8;
}

.ft_text span {
  float: right;
}

.ft_text span a {
  color: #1f1f1f;
  font-weight: 600;
  text-decoration: underline;
}

.ft_text span a img {
  width: 20px;
  margin-left: 10px;
}

.dd_custom {
  width: 100%;
  background-color: transparent;
  border: 0;
  text-align: left;
  border-bottom: 1px solid #acacac;
  border-radius: 0;
  height: 55px;
  color: #1f1f1f;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.8em solid;
  border-right: 0.8em solid transparent;
  border-bottom: 0;
  border-left: 0.8em solid transparent;
  right: 0;
  position: absolute;
}

.btn-secondary:hover {
  color: #1f1f1f;
  background-color: transparent;
  border-color: transparent;
  border-bottom: 1px solid #acacac;
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: unset;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #1f1f1f;
  background-color: transparent;
  border-color: transparent;
  border: 0;
}

.full_title label {
  font-weight: 700;
}

.custom_textarea {
  height: 150px !important;
}

.btn_upload {
  height: 55px;
  display: block;
  width: 50%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  float: left;
}

.upload_text {
  float: left;
  width: auto;
  margin: 8px 0px;
}

.upload_text p {
  margin: 0;
}
.required-label {
  display: grid;
}

.required-check {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.reminder-text {
  font-style: italic;
  font-size: 14px !important;
  margin-top: 10px;
}
.ft_bottom p {
  margin: 0;
  color: #1f1f1f;
  font-weight: 600;
}

.ft_bottom {
  height: 55px;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  float: left;
}

.new_btn_save_bt {
  /* display: block; */
  font-weight: bold;
  /* width: 14%; */
  padding: 10px;
  font-size: 1rem;
  background-color: #1f1f1f;
  line-height: 1.5;
  color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  /* float: right; */
  text-transform: uppercase;
  /* margin-top: 2% !important; */
}

.btn_save_bt {
  display: block;
  font-weight: bold;
  /* width: 14%; */
  padding: 10px;
  font-size: 1rem;
  background-color: #1f1f1f;
  line-height: 1.5;
  color: #fff !important;
  background-clip: padding-box;
  border-radius: 0.25rem;
  text-transform: uppercase;
}

.rdio {
  position: relative;
}

.rdio input[type='radio'] {
  opacity: 0;
}

.rdio label {
  padding-left: 10px;
  cursor: pointer;
  margin-bottom: 7px !important;
}

.rdio label:before {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 1px;
  left: 0;
  content: '';
  display: inline-block;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  border: 2px solid #1f1f1f;
  background: #fff;
}

.rdio input[type='radio'] {
  margin: 0px;
}

.rdio input[type='radio']:disabled + label {
  color: #999;
}

.rdio input[type='radio']:disabled + label:before {
  background-color: #1f1f1f;
}

.rdio input[type='radio']:checked + label::after {
  content: '';
  position: absolute;
  top: 5px;
  left: 4px;
  display: inline-block;
  font-size: 11px;
  width: 10px;
  height: 10px;
  background-color: #1f1f1f;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}

.rdio-default input[type='radio']:checked + label:before {
  border-color: #1f1f1f;
}

.rdio-primary input[type='radio']:checked + label:before {
  border-color: #1f1f1f;
}

.rdio-primary input[type='radio']:checked + label::after {
  background-color: #1f1f1f;
}

.rdio.rdio-primary.radio-inline {
  float: left;
  margin-right: 15px;
}

.radiobuttons {
  float: left;
  margin-bottom: 30px;
  width: 100%;
}

.in_title_sec {
  float: left;
  width: 50%;
  margin-bottom: 20px;
}

.in_title_sec span {
  color: #1f1f1f;
  font-size: 17px;
  font-weight: 600;
}

.radioninline_2 {
  width: 30%;
}

.ft_text_sec {
  text-align: right;
  float: right;
  width: 50%;
}

.ft_text_sec span {
  float: right;
}

.ft_text_sec span a {
  color: #1f1f1f;
  font-weight: 600;
  text-decoration: underline;
}

.ft_text_sec span a img {
  width: 20px;
  margin-left: 10px;
}

.btm_note {
  float: left;
  width: 100%;
  padding: 20px;
}

.btm_note p {
  color: #1f1f1f;
  font-weight: 600;
  font-size: 20px;
}

.ft_img {
  float: left;
  width: 100%;
  padding: 20px;
  position: relative;
}

.ft_img img {
  width: 100%;
}

.ft_img_text {
  margin-top: 10px;
  float: right;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 50px;
  text-align: center;
  font-weight: 600;
}

.setbook_check .mark,
mark {
  padding: 0.2em;
  background-color: #fff;
}

.setbook_check .checkbox_label {
  display: inline-block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: calc(17px * 0.125) solid #1f1f1f;
  border-radius: 12.5%;
  transition: 400ms 100ms ease-out;
  float: left;
}

.setbook_check .mark {
  position: relative;
  right: calc(20px * -0.5);
  top: calc(50px * -0.2);
  width: calc(40px * 0.24);
  height: calc(28px * 0.74);
  border-right: calc(9px * 0.25) solid #1f1f1f;
  border-bottom: calc(9px * 0.25) solid #1f1f1f;
  transform: rotate(45deg) scale(0);
  opacity: 0;
  transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.5);
}

.setbook_check .custom-control {
  padding-left: 0;
  display: inherit;
  width: 25%;
  float: left;
}

.in_title_two {
  position: absolute;
  top: -12px;
  background-color: #fff;
  padding: 0 10px;
  font-weight: 600;
  font-size: 17px;
}

.button_sv {
  background-color: #1f1f1f;
  color: #fff;
  text-transform: uppercase;
  width: 210px;
  float: right;
  margin-top: 20px;
  margin-bottom: 30px;
}

.sync_fild {
  position: relative;
}

.sync_img img {
  width: 25px;
}

.sync_img {
  position: absolute;
  top: 12px;
  right: 0;
  margin-right: 20px;
}

/* .form-group {

/* 
.form-group {
  display: flex;
  justify-content: center;
  width: 100%;
} */

.radioninline_3 {
  width: 30%;
  margin-top: 30px;
}

.radioninline_3 label img {
  width: 20px;
  margin-left: 30px;
  opacity: 0.3;
}

.rdio-primary input[type='radio']:checked + label img {
  opacity: 1;
}

.signuptype_footercover {
  float: left;
  width: 100%;
  padding: 15px 27px;
  margin-top: 50px;
}

.signuptype_footercover .lft {
  float: left;
  width: 50%;
}

.signuptype_footercover .right {
  float: right;
  width: 50%;
}

button.btn.button_jon_sign {
  margin-top: 10px;
  padding: 9px;
  float: right;
  width: 120px;
  font-weight: 600;
  color: #000;
  background-color: transparent;
  border: 2px solid;
}

button.btn.button_jon_sign img {
  width: 20px;
  margin-left: 10px;
}

.model_cover {
  padding: 10px;
  border: 2px solid #706969;
}
.model_cover.profile_detail.my-profile {
  background: transparent;
}

.modal-content {
  padding: 20px;
}

.modal-title {
  font-weight: 600;
  position: relative;
  margin-bottom: 30px;
}

/* .modal-title:after {
  position: absolute;
  content: ' ';
  background-image: url(../src/Utils/Assets/hed_img.png);
  background-repeat: no-repeat;
  width: 150px;
  z-index: 9999999999999999999999;
  background-size: contain;
  bottom: -12px;
  height: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
} */

ul.model_text {
  padding-left: 19px;
}

.thankyou_cover {
  width: 100%;
  padding: 20px;
}

.thankyou_cover h1 {
  color: #1f1f1f;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 45px;
  margin-top: 12%;
}

.thankyou_cover p {
  margin-top: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 17px;
}

.inner_thankyou {
  width: 100%;
  text-align: center;
  border: 2px solid #1f1f1f;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.header-secion .navigation.bg-light {
  height: 74px;
  background: #ededed;
}
/* button.close {
    position: absolute;
    right: 5%;
    top: 8%;
    background-color: transparent;
    opacity: .5;
    color: black;
} */

/* button.close img {
	width:20px;
} */

/* Radio button style */

.rdio {
  position: relative;
}

.rdio input[type='radio'] {
  opacity: 0;
}

.rdio label {
  padding-left: 10px;
  cursor: pointer;
  margin-bottom: 7px !important;
}

.rdio label:before {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 1px;
  left: 0;
  content: '';
  display: inline-block;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  border: 2px solid #1f1f1f;
  background: #fff;
}

.rdio input[type='radio'] {
  margin: 0px;
}

.rdio input[type='radio']:disabled + label {
  color: #999;
}

.rdio input[type='radio']:disabled + label:before {
  background-color: #1f1f1f;
}

.rdio input[type='radio']:checked + label::after {
  content: '';
  position: absolute;
  top: 5px;
  left: 4px;
  display: inline-block;
  font-size: 11px;
  width: 10px;
  height: 10px;
  background-color: #1f1f1f;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}

.btn_save_bt {
  display: block;
  font-weight: bold;
  /* width: 14%; */
  padding: 10px;
  font-size: 1rem;
  background-color: #1f1f1f;
  line-height: 1.5;
  color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  float: right;
  text-transform: uppercase;
  margin-top: 10px;
}

.author_main_s {
  /* float: left; */
  width: 100%;
  padding: 15px 40px;
}

.modal-body_s {
  border: 3px solid #706969;
  padding: 15px 30px 10px 30px;
}

.author_main_s p {
  font-weight: 600;
  color: #1f1f1f;
  margin-bottom: 5px;
  padding: 10px 0 10px 0;
}

.author_main_s h1 {
  color: #1f1f1f;
  text-transform: uppercase;
  font-size: 26px;
  font-weight: bold;
}

.modal-body_s .model_text {
  margin-bottom: 0px;
}

.tick_s {
  background-image: url(../src/Utils/Assets/tick.png);
  background-repeat: no-repeat;
  color: #fff;
  background-position: right 3% bottom 50%;
  background-size: 30px;
}

.playnow {
  display: block;
  font-weight: bold;
  width: 50%;
  padding: 10px;
  font-size: 1rem;
  background-color: #1f1f1f;
  line-height: 1.5;
  color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  float: right;
  text-transform: uppercase;
}

.thanktwo_main {
  float: left;
  width: 100%;
  padding: 10px 25px;
  background: #fff;
}

.div_bt {
  float: left;
  width: 100%;
  padding: 10px 35px;
}

.div_bt p {
  margin: 0;
}

.thanktwo_main .form_custom {
  padding: 0;
}

.rdio-default input[type='radio']:checked + label:before {
  border-color: #1f1f1f;
}

.rdio-primary input[type='radio']:checked + label:before {
  border-color: #1f1f1f;
}

.rdio-primary input[type='radio']:checked + label::after {
  background-color: #1f1f1f;
}

.rdio.rdio-primary.radio-inline {
  float: left;
  margin-right: 15px;
}

.radiobuttons {
  float: left;
  margin-bottom: 30px;
  width: 100%;
}

.navigation.bg-light {
  background: #ededed;
}

.navigation.bg-dark {
  background: #302e2e !important;
  background-color: #302e2e !important;
}
.navigation.bg-dark:hover,
.navigation .bg-dark:hover {
  /* background: #1f1f1f !important;
  background-color: #1f1f1f !important; */
  background: #302e2e !important;
  background-color: #302e2e !important;
}

/* Dashboard */

/* .navigation .bg-light {
  z-index: 1;
} */

.navigation .bg-dark {
  background-color: #302e2e !important;
  /* z-index: 1; */
}

.navigation .navbar {
  padding: 13px 20px;
  display: inline-block;
}

.navbar-nav li a.nav-link {
  color: #b5b5b5 !important;
  text-transform: uppercase;
  font-size: 17px;
}

ul.navbar-nav li.nav-item .dropdown-menu {
  left: unset;
  top: 70%;
}

ul.navbar-nav li.nav-item:nth-child(4) a::after {
  font-family: 'FontAwesome';
  font-weight: 900;
  content: '\f0d7';
  margin-left: 5px;
}

ul.navbar-nav li.nav-item:nth-child(4) .dropdown-menu a::after {
  display: none;
}

.navbar-nav li:hover > ul.dropdown-menu {
  display: block;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}

/* rotate caret on hover */

.dropdown-menu > li > a:hover:after {
  text-decoration: underline;
  transform: rotate(-90deg);
}

li.nav-item {
  margin-right: 15px;
}

.navbar-nav .active > .nav-link,
.navbar-light.bg-dark .navbar-nav .nav-link:hover {
  font-weight: 600;
}

.navbar-light.bg-dark .navbar-nav .active > .nav-link,
.navbar-light.bg-dark .navbar-nav .nav-link:hover {
  color: #fff !important;
}

.navbar-light.bg-light .navbar-nav .active > .nav-link,
.navbar-light.bg-light .navbar-nav .nav-link:hover {
  color: #000 !important;
}
.navbar-light.bg-light .navbar-nav li.nav-item::before {
  content: '';
  display: block;
  height: 2px;
  width: 0;
  background: #000;
  position: relative;
  top: -14px;
  transition: 0.5s;
}
.navbar-light.bg-dark .navbar-nav li.nav-item::before {
  content: '';
  display: block;
  height: 2px;
  width: 0;
  background: #fff;
  position: relative;
  top: -13px;
  transition: 0.5s;
}
.navbar-light.bg-light .navbar-nav li.nav-item:hover::before,
.navbar-light.bg-light .navbar-nav li.nav-item.active::before,
.navbar-light.bg-dark .navbar-nav li.nav-item.active::before,
.navbar-light.bg-dark .navbar-nav li.nav-item:hover::before {
  width: 100%;
}

.noti_setting {
  display: flex;
}
.noti_setting > em {
  position: relative;
}

a.notification {
  width: 55px;
  margin-right: 15px;
}

.noti_setting a:last-child {
  margin-right: 0 !important;
}

.noti_setting a img {
  width: 30px;
}

a.notification {
  background: #f60c0c;
  color: #fff;
  height: 30px;
  display: inline-block;
  width: 55px;
  border-radius: 50%;
  text-align: center;
  line-height: 27px;
  margin-right: 25px;
}

.filter_row {
  /* display: inline-block; */
  width: 100%;
  padding-right: 25px;
  background: #ededed;
  /* position: absolute; */
  top: 0;
}

.noti_setting {
  float: right;
  padding: 16px 20px;
}

.filter_options {
  padding: 15px 20px;
}

span.yes_no,
.sport a span,
.calender_list a,
.calender_list span {
  font-size: 17px;
  font-weight: 700;
  position: relative;
  margin: 0 8px;
  color: #1f1f1f;
  text-transform: uppercase;
}

.sport a span {
  margin: 0;
  margin-right: 10px;
  text-decoration: none;
}

.sport a,
.calender_list a {
  text-decoration: none;
}
.toggle_switch,
.sport,
.new_filter,
.search_box,
.calender_list {
  display: inline-block;
}

.toggle_switch {
  display: flex;
  align-items: center;
}

/* .toggle_switch,
.sport,
.new_filter {
  margin-right: 40px;
} */

.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 24px;
  position: relative;
  margin: 0 7px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.search_box input[type='text'] {
  height: 40px;
  float: left;
  width: calc(100% - 42px);
  padding: 10px;
  font-size: 14px;
  color: #aaa;
  padding-right: 40px;
}

.search_box button {
  background: none;
  border: none;
  position: relative;
  left: -41px;
  top: 7px;
}

.filter_row img {
  width: 25px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Radio button style over */

@media (min-width: 1500px) and (max-width: 1920px) {
}

@media (max-width: 992px) {
  .in_title {
    position: unset;
  }
  .footer-padding {
    padding-bottom: 120px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 920px;
  }
}

@media (max-width: 767px) {
  .reminderContainer {
    display: grid;
    align-items: end;
    grid-template-columns: 1fr !important;
  }
  .reminderText1 {
    margin-bottom: 5px !important;
  }

  .advertise {
    width: 450px;
    height: 100px;
    margin: 10px;
  }
  .form_gp_rgyt {
    margin-left: 0px;
  }
  #myModal {
    padding: 0;
  }
  .button_btft {
    margin-top: 20px;
  }
  .main_left {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  .logo_3.my-5 {
    margin: 5px !important;
    padding: 10px;
  }
  .main_right {
    width: 100%;
  }
  .form-group_code {
    width: 80%;
  }
  button.btn.btn-lg.btn-block.btn_submit {
    width: 60%;
  }
  .form_gp_lft {
    margin-right: 0px;
    margin-bottom: 25px;
  }
  .logo_3 img {
    width: 120px;
  }
  .email {
    height: 45px;
  }
  .social li a img {
    width: 28px;
  }
  .social li {
    padding: 0 4px;
    font-size: 12px;
    list-style: none;
  }
  footer {
    padding: 20px 0;
    max-width: calc(100% - 80px);
    margin-left: 65px;
    margin-right: 0;
  }
  footer .container-fluid {
    padding: 0;
  }
  .form_cust_hed {
    background-color: transparent;
    border-color: #acacac;
    margin-bottom: 10px;
  }
  .btn_h_login {
    width: 100%;
  }
  .lft {
    width: 100%;
  }
  .rght {
    width: 100%;
  }
  .radioninline_2 {
    width: 100%;
    margin-bottom: 10px;
  }
  .radioninline_3 {
    width: 100%;
    margin-bottom: 10px;
  }
  .in_title_two_sec {
    width: 50%;
    float: left;
    margin-bottom: 30px;
  }
  .setbook_check .custom-control {
    width: 100%;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
  }
  button.delete_btn {
    margin-top: 0 !important;
  }
  .messenger {
    grid-template-columns: 22% auto;
  }
  .compose {
    bottom: 16% !important;
  }
  .conversation-info {
    display: none;
  }
  .form-group_code {
    width: 55% !important;
  }
  .signup-btn-group {
    display: inline-block;
    width: 92%;
  }
  .signup-btn-group {
    margin-top: 0 !important;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
  }
  img.header_mobile_img {
    display: block !important;
  }
  a.calender.cursor-pointer,
  a.list.cursor-pointer {
    display: inline-block;
  }
  span.header_text {
    display: none;
  }
  .calender_list {
    position: absolute;
    top: -73px;
    left: 34%;
  }
  span.devider {
    font-size: 28px;
  }
}
.signup-btn-group {
  margin-left: 4%;
  margin-right: 4%;
  margin-top: 0px;
}
.btn-filled {
  background-color: #1f1f1f;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  width: 170px;
  padding: 10px;
  float: right;
  margin-right: 10px;
  margin-top: 10px;
}

.social-buttons-in-signup {
  margin-bottom: 20px;
}

.new-btn-filled {
  background-color: #1f1f1f;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 10px 40px;
  margin-right: 10px;
}

.new-btn-bordered {
  margin-top: 10px;
  padding: 9px;
  font-weight: 600;
  color: #000;
  background-color: transparent;
  border: 2px solid;
}

.btn-bordered {
  margin-top: 10px;
  padding: 9px;
  float: right;
  width: 120px;
  font-weight: 600;
  color: #000;
  background-color: transparent;
  border: 2px solid;
}

.btn-bordered img {
  width: 20px;
  margin-left: 10px;
}
.frame-heading {
  font-weight: bold;
  font-size: 1.2rem;
}
.frame-container {
  margin: 15px 0;
  padding: 15px 25px;
  border: 2px solid #000;
  display: inline-block;
  width: 100%;
}

.frame-container .frame-header {
  font-weight: 600;
  font-size: 1.3rem;
  margin-top: -30px;
  margin-left: 6px;
}

.frame-container .frame-header span.title {
  background-color: white;
  padding-right: 6px;
  padding-left: 6px;
}

.frame-container .frame-content {
  padding: 0px 12px;
}
.frame-container .frame-content input.form-control {
  height: 55px;
}
.frame-container .frame-content .form-control {
  background-color: #f2f2f2;
  border-color: #e7e7e7;
  border-radius: 15px;
  padding: 10px;
}
.calendar-container-dark .frame-container .frame-content .form-control {
  background-color: transparent;
  color: #030303;
}
.calendar-container-dark .frame-container .frame-content .form-control option {
  color: #000000;
}
.calendar-container-dark .frame-container .frame-content .btn_save_bt {
  background-color: white !important;
  color: black !important;
}
.frame-container .frame-content label {
  font-weight: bold;
  font-size: 1.2rem;
}
.red-colored {
  color: red;
}
.green-colored {
  color: green;
  font-weight: bold;
}

.radio-label {
  margin-left: 4px;
  font-weight: 400;
}

.m-t-120 {
  margin-top: 120px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.pull-right {
  float: right;
}

.show-pwd {
  color: white;
  right: 5%;
  display: inline;
  position: absolute;
  top: 6px;
  font-size: 25px;
}

.show-pwd-dark {
  color: black;
  margin-right: 20px;
  display: inline;
  position: relative;
  /* top: -50px; */
  top: -47px;
  font-size: 25px;
}

.invalid {
  border: 1px solid red;
}

.dropdown {
  width: 100%;
}

.custom-badge {
  background: red;
  color: white;
  border-radius: 1em;
  font-size: 0.5em;
  top: -10px;
  position: absolute;
  right: 0;
  font-family: 'Raleway', sans-serif;
}

.calendar {
  /* height: 1100px; */
  color: #202020;
}
.calender-view-otr {
  min-height: 1400px;
}
.calender-view-otr .rbc-event:focus {
  outline: 0 !important;
}

.calendar .rbc-calendar .rbc-toolbar .rbc-btn-group button {
  color: #202020;
}

.calendar-dark {
  background-color: #202020 !important;
  color: white;
}

.calendar-dark
  .rbc-calendar
  .rbc-toolbar
  .rbc-btn-group
  button:not(.rbc-active) {
  color: white;
}

.calendar-container {
  /* padding: 15px; */
  color: #202020;
}

.calendar-container-dark {
  background: #202020;
  color: #fff;
  /* padding: 15px; */
}
.daily-task-list-view {
  margin-bottom: 10px;
}

/* / Style for modal-popup design / */

.eventModal h5.modal-title {
  width: 100%;
  padding: 10px;
}

.eventModal h5 h3 {
  padding-top: 250px;
}

.event_body {
  margin-top: 80px;
}

/* Style for legends */

.color_guide {
  display: inline-block;
  /* border: 1px solid #ddd; */
  border: 1px solid #000;
  padding: 15px;
  width: 100%;
}

.calendar-container-dark .color_guide {
  border: 1px solid #fff;
}
.color_guide .row {
  /* display: flex;
    justify-content: space-between;
    padding: 0 15px; */

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: row wrap;
  align-items: stretch;
}

span.color_box {
  /* height: 17px; */
  /* width: 27px; */
  /* display: inline-block; */
  /* background: #000; */
  /* border: 1px solid #ddd; */
  /* margin-right: 10px; */
  border-radius: 12px;
  padding: 3px;
}

.color_guide .legend {
  /* display: flex; */
  align-items: center;
  flex-grow: 1;
  font-weight: bold;
  margin-left: 5px;
  /* justify-content: left; */
}
.color_guide .legend .color_box {
  cursor: pointer;
}
.color_guide .col-sm-4:last-child {
  margin-bottom: 0px;
}

p.indicator_name {
  margin: 0;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  font-size: smaller;
}

/* Book release */

.color_guide .legend:nth-child(6) span.color_box {
  background: linear-gradient(to right, rgb(101, 193, 255), rgb(113, 147, 255));
  padding: 4px 10px;
  border-radius: 10px;
}

/* Giveaway */

.color_guide .legend:nth-child(5) span.color_box {
  background: linear-gradient(to right, rgb(159, 255, 129), rgb(121, 255, 201));
  padding: 4px 10px;
  border-radius: 10px;
}

/* Book promo */

.color_guide .legend:nth-child(3) span.color_box {
  background: linear-gradient(to right, rgb(255, 129, 129), rgb(255, 150, 79));
  padding: 4px 10px;
  border-radius: 10px;
}

/* Relese party */

.color_guide .legend:first-child span.color_box {
  background: linear-gradient(to right, rgb(255, 101, 255), rgb(255, 121, 188));
  padding: 4px 10px;
  border-radius: 10px;
}

/* COVER REVEAL */

.color_guide .legend:nth-child(4) span.color_box {
  background: linear-gradient(to right, rgb(255, 219, 105), rgb(243, 255, 133));
  padding: 4px 10px;
  border-radius: 10px;
}

/* BOOK SIGNING */

.color_guide .legend:nth-child(7) span.color_box {
  background: linear-gradient(to right, rgb(142, 91, 255), rgb(180, 105, 255));
  padding: 4px 10px;
  border-radius: 10px;
}

/* TICKET SALES */

.color_guide .legend:last-child span.color_box {
  background: linear-gradient(to right, rgb(185, 185, 255), rgb(220, 185, 255));
  padding: 4px 10px;
  border-radius: 10px;
}

/* ARC SIGN UP */

.color_guide .legend:nth-child(2) span.color_box {
  background: linear-gradient(to right, rgb(255, 94, 142), rgb(255, 88, 88));
  padding: 4px 10px;
  border-radius: 10px;
}
.cookip-policy {
  background-color: #1f1f1f;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999999999;
}
.privacy-content {
  font-weight: 700;
  font-size: 16px;
  color: white;
  width: calc(100% - 20%);
}

.privacy-content a {
  margin-left: 9px;
  text-transform: capitalize;
}
.privacy-policy {
  padding: 25px 30px;
}
.cookip-policy.hide {
  display: none;
}
.cookip-policy .cookip-inner .cookip-btn {
  background-color: #ff0000;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 50px;
  text-transform: uppercase;
  padding: 10px 30px;
}
.privacy-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 17%;
}
/* Home Page css Start */
.bg-dark {
  background-color: #000000 !important;
}
.bg-dark:hover {
  background-color: #111111 !important;
}
.radius8 {
  border-radius: 8px;
}
.mid-section {
  background-color: #f7f7f7;
}
.mid-section .main-wrapper {
  background-color: #fff;
  max-width: 1440px !important;
  margin: auto;
}
nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  background-color: #ededed !important;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 0px 0px 5px 0px #bfbfbf;
}
.optimizeBox {
  border: 2.7px solid #000;
  padding: 0 70px 50px;
  margin: 70px 0 0px;
}
.optimizeBox .main-title h1 {
  position: relative;
  top: -20px;
  background-color: #fff;
  display: inline-block;
  padding: 0 25px;
  margin-bottom: 20px;
  font-size: 32px;
}
.calendar-container-dark .optimizeBox .main-title h1 {
  background: #202020;
}
.calendar-container-dark .optimizeBox {
  border-color: #fff;
}

.optimizeBox .brand-logo {
  max-width: 170px;
  width: 100%;
  margin-top: -7px;
}
.optimizeBox p {
  font-size: 25px;
  line-height: 1.2;
  margin-bottom: 50px;
  text-align: left;
}
.calendar-container-dark .optimizeBox .bg-dark,
.calendar-container-dark .writing-promoting-section .bg-dark,
.calendar-container-dark .new-btn-filled {
  background-color: #ffffff !important;
  color: #000 !important;
}
.download-links li:first-child {
  margin-right: 20px;
}
.calendar-container-dark .black-logo,
.white-logo {
  display: none;
}
.calendar-container-dark .white-logo {
  display: block;
}
.optimizeBox .download-links img {
  max-width: 100%;
  width: auto;
  border-radius: 8px;
  height: 46px;
}
.main-title h1,
.main-title h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 40px;
}
.main-title .subTitle {
  font-size: 24px;
}
.writing-promoting-section {
  padding: 0px 0px;
  margin-top: 70px;
}
.writing-promoting-section .ul_plans li {
  width: 100%;
  padding: 0px;
  margin-bottom: 15px;
  /* box-shadow: 0px 0px 8px 0px #f5f5f5; */
  border-radius: 8px;
  /* border: 1px solid #ededed; */
}
.writing-promoting-section .ul_plans li .divImg {
  text-align: center;
  margin-bottom: 5px;
}
.writing-promoting-section .ul_plans li .divImg img {
  max-width: 80px;
}
.writing-promoting-section .ul_plans li h5 {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}
.writing-promoting-section .ul_plans li p {
  font-size: 15px !important;
  font-weight: 600 !important;
}
.features-plans-section {
  padding: 0px;
  margin-top: 70px;
}
.our-members-section {
  padding: 50px 0;
}
.our-members-section .members-list em {
  font-size: 20px;
  line-height: 1.5;
  display: block;
  margin-bottom: 20px;
  font-family: 'Raleway';
  font-weight: 400;
}
.our-members-section .members-list h5 {
  font-size: 17px;
  font-weight: 700;
  text-align: right;
}
.our-members-section .members-list h5::before {
  content: '-';
  position: relative;
  margin-right: 5px;
}

.footer_text h3 {
  font-size: 24px !important;
  font-weight: 700;
  margin-bottom: 0px;
}
#footer .payments-links li {
  margin: 0 0 20px;
}

#footer .download-links li:last-child,
#footer .payments-links li:last-child {
  margin-bottom: 0;
}

#footer .download-links,
#footer .payments-links {
  margin-bottom: 0;
}
#footer .payments-links li img {
  border-radius: 10px;
  max-width: 170px;
  width: 100%;
  object-fit: fill;
}
footer .copyRight {
  font-size: 13px !important;
}
#footer .download-links li {
  margin: 0 0 20px;
}

.timeline-section {
  padding: 50px 0;
}
.timeline-section .divImg img {
  max-width: 100%;
}

/* Home Page css End */

/* Additional responsive css */

@media only screen and (max-width: 1920px) {
  .color_guide {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1450px) {
  li.nav-item {
    margin-right: 0;
  }
  .color_guide {
    font-size: 12px;
  }
  .color_guide .row {
    padding: 0 5px;
  }
  .color_guide .row .legend {
    margin-right: 2px;
  }
  ul.footer-links li {
    margin: 0px 5px;
  }
  ul.footer-links li img {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 1199px) {
  .navigation .navbar {
    padding: 13px 10px;
  }
  .noti_setting {
    padding: 16px 10px;
  }
  .noti_setting > em {
    font-size: 25px !important;
  }
  .btn_save_bt {
    margin-right: 0% !important;
    margin-top: 25px;
    width: 18% !important;
  }
  .navbar-nav li a.nav-link {
    font-size: 13px;
  }
  .noti_setting a:first-child {
    position: relative;
  }
  .custom-badge {
    top: -5px;
    right: 1px;
  }
  p.indicator_name {
    font-size: 14px !important;
  }
  .color_guide .row {
    display: flex;
    justify-content: flex-start;
  }
  .color_guide .legend {
    margin-right: 2px;
    margin-bottom: 2%;
    flex-grow: inherit;
  }
  .color_guide .legend:nth-child(5),
  .color_guide .legend:nth-child(6),
  .color_guide .legend:nth-child(7) {
    margin-bottom: 0;
  }
  .color_guide {
    font-size: 9px;
  }
  button.setup_event_bt {
    top: 9% !important;
  }
  button.setup_event_bt.daily {
    top: -0.8% !important;
  }
  button.add_to_beta_reder {
    width: 51% !important;
  }
  .writing-promoting-section .ul_plans li {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .navbar-toggler {
    font-size: 15px;
  }
  .rbc-toolbar {
    flex-direction: column;
  }
  .btn_save_bt {
    margin-right: 2.5% !important;
    margin-top: 80px;
    width: 30% !important;
  }
  .search_box input[type='text'] {
    width: 170px;
  }
  p.indicator_name {
    font-size: 12px !important;
  }
  button.setup_event_bt {
    width: 24% !important;
    top: 11% !important;
  }
  button.setup_event_bt.daily {
    top: 0% !important;
  }
  button.add_to_beta_reder {
    width: 59% !important;
    margin-bottom: 10px;
  }
  .main-title .subTitle {
    font-size: 21px;
  }
  .optimizeBox {
    padding: 0 30px 30px;
  }
  .optimizeBox p {
    font-size: 22px;
  }
  .writing-promoting-section .ul_plans li {
    width: 33.33%;
  }
  .our-members-section .members-list em {
    font-size: 15px;
  }
  .our-members-section .members-list h4 {
    font-size: 17px;
  }
  #footer .download-links li,
  #footer .payments-links li {
    display: inline-block;
    margin: 0 10px 20px;
  }
  .main-content .footer {
    width: 100%;
    margin-left: 0;
    background-color: rgb(31, 31, 31);
  }
  ul.list-unstyled.footer-links li {
    margin-left: 5px !important;
  }
  ul.list-unstyled.footer-links li:first-child {
    margin-left: 0 !important;
  }

  ul.footer-links li {
    margin: 1px 5px !important;
    max-width: 65px;
  }
  .footer-bottom {
    flex-wrap: wrap;
  }
  .footer-links {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .footer-bottom,
  .footer-links {
    flex-wrap: wrap !important;
  }

  .footer-bottom .col-md-8.col-12 {
    max-width: calc(100% - 280px);
    padding-right: 5px;
    padding-left: 5px;
  }
  .footer-bottom .col-md-2.col-12 {
    max-width: fit-content;
    padding-right: 5px;
    padding-left: 5px;
  }
  p.copyRight.text-white {
    position: unset !important;
  }

  p.copyRight.text-white {
    bottom: 0px;
  }
  ul.list-unstyled.footer-links {
    justify-content: center !important;
    width: fit-content;
  }
  ul.ul_plans {
    grid-template-columns: auto auto auto !important;
  }
  .navbar-light.bg-light .navbar-nav li.nav-item::before {
    top: 35px;
  }
  .calender_list {
    display: block;
    text-align: center;
  }
  .advertise {
    width: 300px;
  }

  .social-buttons-in-signup {
    margin-bottom: 13rem;
  }

  .rbc-toolbar {
    flex-direction: row;
  }
  .btn_save_bt {
    margin-right: 2% !important;
    margin-top: 40px;
  }
  .filter_options {
    padding: 15px 20px;
    text-align: center;
  }
  button.setup_event_bt {
    top: 8.5% !important;
    width: 21% !important;
    right: 0 !important;
  }
  button.setup_event_bt.daily {
    top: -0.8% !important;
    right: 2% !important;
  }
  button.add_to_beta_reder {
    width: 82% !important;
  }
  em.fa.fa-2x.fa-bell {
    position: relative;
  }
  .custom-badge {
    top: -10px;
    right: -4px;
    position: absolute;
  }
  .main-title h1,
  .main-title h2 {
    font-size: 27px;
  }
  .main-title .subTitle {
    font-size: 18px;
  }
  .optimizeBox .brand-logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
  .optimizeBox p {
    font-size: 18px;
  }
  .writing-promoting-section .ul_plans li {
    width: 50%;
  }
  .our-members-section .members-list em {
    margin-bottom: 10px;
  }
  ul.footer-links li {
    max-width: fit-content;
  }
}

@media screen and (max-width: 682px) {
  .search_box input[type='text'] {
    width: 120px;
  }
}

@media screen and (max-width: 632px) {
  .search_box input[type='text'] {
    width: 170px;
  }
  ul.ul_plans {
    grid-template-columns: auto auto !important;
  }
}

@media screen and (max-width: 575px) {
  p.indicator_name {
    font-size: 11px !important;
  }
  .color_guide .row {
    flex-direction: column;
  }
  .color_guide .legend:nth-child(5),
  .color_guide .legend:nth-child(6),
  .color_guide .legend:nth-child(7) {
    margin-bottom: 2%;
  }
  .color_guide.mb-3 .row {
    flex-direction: row;
  }
  .legend {
    width: 46%;
  }
  .main-title h1,
  .main-title h2 {
    font-size: 24px;
  }
  .main-title .subTitle {
    font-size: 15px;
  }
  .optimizeBox {
    padding: 0 15px 15px;
    margin: 50px 0 25px;
  }
  .optimizeBox .main-title h1 {
    padding: 0 15px;
    max-width: 400px;
  }
  .writing-promoting-section,
  .features-plans-section,
  .our-members-section,
  .timeline-section {
    padding: 25px 0;
  }
  .writing-promoting-section .ul_plans li h5 {
    font-size: 20px;
  }
  ul.ul_plans {
    grid-template-columns: auto !important;
  }
}

@media screen and (max-width: 583px) {
  .rbc-toolbar {
    flex-direction: column;
  }
  .btn_save_bt {
    margin-right: 2% !important;
    margin-top: 80px;
  }

  .filter-section {
    justify-content: space-between !important;
  }
  .filter-section .toggle_switch span.yes_no {
    font-size: 12px !important;
    margin: 0;
    font-weight: 700 !important;
  }
  .calender_list {
    display: none;
  }
  .search_box {
    width: calc(100% - 180px);
  }
  .footer-bottom {
    flex-direction: column;
    display: flex;
    justify-content: center;
  }
  #footer .row {
    margin: 0;
  }
  .footer-bottom,
  .footer-links {
    width: 100%;
  }
  .footer-bottom .col-md-8.col-12 {
    max-width: 100%;
    margin: 10px 0;
  }
  .footer-bottom .col-md-2.col-12 {
    max-width: 100%;
  }
  ul.list-unstyled.footer-links {
    margin: auto;
  }
  p.copyRight.text-white {
    text-align: center;
  }
  footer {
    max-width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 479px) {
  .btn_save_bt {
    width: 47% !important;
    margin-right: 3% !important;
    margin-top: 20px !important;
    margin-bottom: 15px;
  }
  .rbc-toolbar {
    flex-direction: column;
  }
  .search_box {
    margin-top: 20px;
  }
  .filter_row {
    padding-right: 0 !important;
  }
  .filter_row .row {
    width: 100%;
    text-align: center;
    margin: 0 !important;
  }
  .filter_row .row .col-sm-12 {
    padding: 0 !important;
    margin: 0 !important;
  }

  .search_box {
    margin-top: 10px;
    margin-bottom: 6px;
    margin: 0 !important;
  }
  .toggle_switch,
  .sport,
  .new_filter {
    margin-right: 14px;
  }
  .search_box input[type='text'] {
    width: 200px;
    width: calc(100% - 40px);
  }
  button.btn.btn_h_login {
    margin-left: 0;
  }
  .signuptype_footercover {
    padding: 5px;
    margin-top: 0px;
    background: #fff;
  }
  .main_right.author_types .full_title .form-group {
    margin-bottom: 0;
  }
  .signup_text span {
    margin: 0 10px;
  }
  .border label {
    font-size: 14px;
    display: block;
  }
  .compose {
    bottom: 25% !important;
  }
  .messenger {
    grid-template-columns: 32% auto;
  }
  .sport a span {
    display: none;
  }
  .sport {
    /* position: relative; */
    top: 13px;
    position: unset;
  }
  span.color_box {
    /* height: 14px !important; */
    /* width: 20px !important; */
  }
  .modal-title h5 {
    font-size: 24px !important;
  }
  .modal-title {
    margin-bottom: 13px;
  }
  img.logout_img {
    display: block !important;
  }
  .setup_p_hed a button.btn span {
    display: none;
  }
  .setup_event_bt span {
    display: none;
  }
  button.setup_event_bt {
    background: none;
  }
  .setup_event_bt img {
    max-width: 27px;
  }
  button.setup_event_bt {
    top: 6% !important;
    width: 12% !important;
    right: 10% !important;
  }
  button.setup_event_bt img {
    display: block !important;
  }
  button.add_to_beta_reder {
    width: 100% !important;
    font-size: 12px;
  }
  button.add_to_beta_reder {
    width: 95% !important;
    font-size: 12px;
    margin-top: 19px !important;
  }
  .alignment.add_to_beta {
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 0;
  }
  .fontSize.add_to_beta_text {
    text-align: justify;
    margin-bottom: 20px;
  }
  .calender_list {
    z-index: 1;
  }
}

@media screen and (max-width: 411px) {
  button.setup_event_bt {
    top: 5.5% !important;
    width: 12% !important;
    right: 4% !important;
  }
}

@media screen and (max-width: 360px) {
  .advertise {
    width: 300px;
  }
  .toggle_switch,
  .sport,
  .new_filter {
    margin-right: 9px;
  }
  .search_box {
    margin-top: 20px;
    margin-bottom: 3px;
  }
  .btn_save_bt {
    width: 61% !important;
  }
  .setup_p_hed h4 {
    font-size: 20px;
  }
  .writing-promoting-section .ul_plans li {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  button.setup_event_bt {
    top: 6% !important;
    width: 17% !important;
    right: 0% !important;
    margin-right: 0 !important;
  }
  .advertise {
    width: 250px;
  }
}

@media screen and (max-width: 575px) {
  footer button {
    width: 100% !important;
    margin-bottom: 20px;
  }
  footer button:last-button {
    width: 100% !important;
    margin-bottom: 20px;
  }
}
.rbc-date-cell {
  text-align: left;
  margin-left: 5px;
}
.rbc-button-link {
  text-align: left;
}

.main_right.author_profile.popup {
  width: 100%;
}

#myModal .modal-content .container-fluid {
  padding: 10px;
  border: 2px solid #706969;
  padding-left: 25px;
  padding-right: 25px;
}

.row .col-md-5.mt-2.ml-1 button {
  margin-bottom: 5px;
  color: #000;
  border-radius: 10px;
  box-shadow: 0px 1px 4px -2px #333;
  text-shadow: 0px -1px #333;
}

.row .col-md-5.mt-2.ml-1 button:hover::after {
  background: transparent;
}

.row .col-md-5.mt-2.ml-1 button:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: 50%;
  /* background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2)); */
}

.row .col-md-5.mt-2.ml-1 button.event-type-1 {
  background: linear-gradient(to right, rgb(101, 193, 255), rgb(113, 147, 255));
}

.row .col-md-5.mt-2.ml-1 button.event-type-2 {
  background: linear-gradient(to right, rgb(159, 255, 129), rgb(121, 255, 201));
}

.row .col-md-5.mt-2.ml-1 button.event-type-3 {
  background: linear-gradient(to right, rgb(255, 129, 129), rgb(255, 150, 79));
}

.row .col-md-5.mt-2.ml-1 button.event-type-4 {
  background: linear-gradient(to right, rgb(255, 101, 255), rgb(255, 121, 188));
}

.row .col-md-5.mt-2.ml-1 button.event-type-5 {
  background: linear-gradient(to right, rgb(255, 219, 105), rgb(243, 255, 133));
}

.row .col-md-5.mt-2.ml-1 button.event-type-6 {
  background: linear-gradient(to right, rgb(142, 91, 255), rgb(180, 105, 255));
}

.row .col-md-5.mt-2.ml-1 button.event-type-7 {
  background: linear-gradient(to right, rgb(255, 94, 142), rgb(255, 88, 88));
}

.author_profile {
  padding-bottom: 0px;
}

div#myModal .modal-content .row {
  padding-bottom: 10px;
}

.model_cover.profile_model .modal-title:after {
  display: none;
}

.model_cover.profile_model .event_body.modal-body {
  margin-top: 15px;
}

.model_cover.profile_model h5.modal-title {
  margin-bottom: 10px !important;
  text-align: center;
}

.profilr_image,
.model_cover.profile_detail .eventModal .profile_image {
  display: inline-block;
  position: relative;
  width: 150px !important;
  height: 150px !important;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto !important;
  background-size: cover !important;
  background-position: center center;
  padding: 4.5px;
}

.model_cover.profile_detail .eventModal {
  text-align: center;
  margin-top: 20px;
}

h3.profile_title {
  padding: 10px !important;
}

.profile_detail .event_body {
  margin-top: 40px;
}

.ag-row .ag-cell,
.ag-cell-label-container .ag-header-cell-label {
  font-size: 14px;
  font-family: 'Helvetica Neue', sans-serif;
  text-overflow: unset;
}

.model_cover.create_event .modal-title {
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  text-transform: uppercase;
}

/* .rbc-calendar {
  height: 1045px !important;
} */

.calendar .rbc-event-content {
  line-height: 16px;
  font-size: 13.5px;
}
.calender-view-otr .rbc-month-row {
  overflow: unset;
}
/* .toast-notification span {
    -webkit-box-shadow: 0px 0px 14px 1px #fff;
    -moz-box-shadow: 0px 0px 14px 1px #fff;
    box-shadow: 0px 0px 14px 1px #fff;
} */

.dark-button {
  background-color: white !important;
  color: black !important;
}

.white-button {
  background-color: white !important;
  color: black !important;
}

.event_body.modal-body ul li p {
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 0.2px;
}

.more-profile {
  display: inline-block;
  /* margin-left: 5px; */
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 236px;
  display: inline-block;
  position: relative;
  top: 8px;
}

fieldset.border {
  padding: 10px;
}

fieldset.border legend.w-auto {
  width: auto;
}

fieldset.border.gener {
  margin-bottom: 15px;
}

button.plus_btn,
button.delete_btn {
  height: 38px;
  font-size: 21px;
  border-radius: 4px;
  border: 1px solid #343a40;
  cursor: pointer;
  background: transparent;
  color: #343a40;
}

button.delete_btn {
  margin-top: 28%;
}

.modal-title h5 {
  font-size: 28px;
}

.border
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before,
.border
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #23272b;
}

#myModal button.btn.btn-dark.pull-right {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
}

#navbarDropdownMenuLink {
  pointer-events: none;
  cursor: default;
}

.form-signin .custom-control.custom-checkbox.mb-4.mt-4 {
  margin: 0 auto;
}

div#myModal .modal-content .model_cover .modal-body b:nth-of-type(2) {
  margin-top: 10px !important;
  display: inline-block;
  margin-bottom: 0 !important;
}

.model_cover.create_event .modal-body .row {
  display: flex;
  justify-content: center;
}

.border .row .col-sm-3 label {
  display: block;
  font-size: 13px;
}

.radiobuttons label {
  padding-left: 0;
}

.row.signupmain .main_right.author_types .full_title .form-group .in_form {
  padding: 20px;
}

/* Popover CSS */

.alert.alert-dark.notif_wrap {
  background: #fff;
  margin: 0;
  padding: 0;
}

.notif_wrap h4.alert-heading {
  font-weight: 700;
  padding: 10px;
  margin: 0;
  font-size: 15px;
  text-transform: uppercase;
}

.sub_notification {
  background: #eae7e7;
  padding: 6px;
  border-bottom: 2px solid #ccc;
  font-size: 14px;
}

.sub_notification span {
  font-weight: 700;
  margin-right: 5px;
}

.sub_notification:last-child {
  border-bottom: none;
}

.showPopover {
  list-style-type: none;
  background: #ededed;
  overflow: hidden;
}

/* --- 27/12/19 --- */

/*.main_right.author_profile {*/
/* min-height: calc(100vh - 78px); */
/* padding-bottom: 85px; */
/* overflow-y:auto; */
/*}*/

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  ::-webkit-full-page-media,
  :future,
  :root,
  .main_right.author_profile {
    overflow-y: auto;
  }
}

.messenger {
  height: 72vh !important;
}

.compose {
  padding: 0 10px;
  width: 100%;
  position: absolute;
  bottom: 0;
}
.compose.compose-dark {
  background-color: transparent;
}
.messenger .btn,
.compose .btn {
  min-width: auto;
}
.react-tiny-popover-container ul.showPopover {
  padding: 0;
  margin: 0;
  border: 1px solid #c6c8ca;
  border-radius: 0.25rem;
  position: relative;
  right: 13%;
}

.react-tiny-popover-container ul.showPopover li {
  background: #eae7e7;
  padding: 6px;
  border-bottom: 2px solid #ccc;
  font-size: 14px;
}

.react-tiny-popover-container ul.showPopover li:last-child {
  border-bottom: none;
}

.react-tiny-popover-container ul.showPopover li button {
  padding: 0;
}

.react-tiny-popover-container {
  overflow: visible !important;
}

body {
  overflow-x: hidden;
}

@media screen and (max-width: 1199px) {
  .compose {
    width: unset;
  }
}

@media screen and (max-width: 991px) {
  .react-tiny-popover-container ul.showPopover {
    right: 5%;
  }
}

/* --- 27/12/19 --- */

/* css for show badge for unread message count */

.messageBadge {
  position: absolute;
  top: 13px;
  right: 18px;
}

/* --- 21/01/19 --- */

/* .footer_text ul {
    column-count: 2;
    float: left;
    position: relative;
} */

/* .footer_text ul li {
    display: inline-block;
    margin-bottom: 15px;
} */

/* @media screen and (max-width: 767px) {
    .footer_text div button {
        float: none;
        margin: 0 auto;
    }
    footer .footer_text-mb-4 {
        text-align: center;
        margin-top: 10%;
    }
    .footer_text ul {
        margin-top: 6%;
    }
    .footer_text ul {
        margin-top: 6%;
        width: 100%;
    }
    .navbar-collapse.collapse.in {
        display: block;
    }
    .navbar-light.bg-light .navbar-nav li.nav-item.active::before {
        display: none;
    }
} */

@media screen and (max-width: 576px) {
  .container .row .col-12.col-sm-2 {
    text-align: center;
  }
  .container .row .col-12.col-sm-2 .left-logo {
    width: 35%;
    margin-bottom: 5%;
  }
}

/* --- 21/01/19 --- */

/* --- 23/01/19 --- */

h1.toolbar-title {
  display: none;
}

.rdio.rdio-primary.radio-inline.radioninline_3 {
  margin-bottom: 0;
  /* margin-top: 0; */
}

/* --- 23/01/19 --- */

/* --- 24/01/19 --- */

img.header_mobile_img {
  display: none;
}

span.header_text {
  top: 0;
}

img.logout_img {
  display: none;
  max-width: 20px;
}

/* --- 24/01/19 --- */

/* --- 28/01/19 --- */

button.setup_event_bt {
  position: absolute;
  right: 0;
  z-index: 1;
  top: 4%;
}

button.setup_event_bt img {
  display: none;
}

/* --- 28/01/19 --- */

/* --- 29/01/19 --- */

button.setup_event_bt.daily {
  margin: 0 !important;
  top: -0.8%;
  right: 15px;
}

.socialButton {
  padding: 0;
  border: none;
  background: none;
  width: auto !important;
  height: auto !important;
  min-width: auto !important;
}

.fb {
  background-color: #3b5998;
  color: white;
  width: 100%;
}

.google {
  background-color: #dd4b39;
  color: white;
  width: 100%;
}

.seprator {
  position: relative;
  margin: 1em 0;
  text-align: center;
}

p.seprator:after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: #212529;
  position: absolute;
  top: 50%;
  left: 0;
}
p.seprator span {
  background-color: #fff;
  padding: 0 1em;
  position: relative;
  z-index: 1;
  font-style: italic;
}
/* --- 29/01/19 --- */

.agreeTerms {
  left: 0px !important;
  vertical-align: baseline !important;
  /* margin-right: 2%; */
  margin-bottom: 0px !important;
}

.new_btn_save_bt_users_light {
  font-weight: bold;
  background-color: #1f1f1f;
  line-height: 1.5;
  color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  text-transform: uppercase;
  margin-bottom: 2% !important;
  font-size: 12px;
}

.new_btn_save_bt_users_dark {
  font-weight: bold;
  background-color: #fff;
  line-height: 1.5;
  color: #1f1f1f;
  background-clip: padding-box;
  border-radius: 0.25rem;
  text-transform: uppercase;
  margin-bottom: 2% !important;
  font-size: 12px;
}

.mousechange:hover {
  cursor: pointer;
}

.show-pwd-dark-change {
  color: black;
  margin-right: 20px;
  display: inline;
  position: absolute;
  top: 3px;
  right: 15px;
  font-size: 25px;
}
.form-border {
  border: 1px solid #ccc;
  padding: 15px;
}
.form-border .form-control {
  background-color: #e6e6e6;
}
.btn-custom {
  color: #939799;
  margin-top: 2%;
}

.socialAcc {
  padding: 20px;
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}

.socialAcc:hover {
  opacity: 0.7;
}

.fa-facebook {
  /* background: #000000; */
  color: white;
}

.fa-twitter {
  /* background: #55ACEE; */
  color: white;
}

.fa-youtube {
  /* background: #bb0000; */
  color: white;
}

.fa-instagram {
  /* background: #125688; */
  color: white;
}

.react-tiny-popover-container {
  z-index: 100 !important;
}

/* calendar Month css */
span.rbc-toolbar-label {
  font-size: 35px;
  margin-right: 80px;
  font-weight: bold;
}

/* calendar white background */
.light-calendar .rbc-month-header {
  background-color: black !important;
  color: white !important;
  font-weight: bold !important;
}

.dark-calendar .rbc-month-header {
  background-color: white !important;
  color: black !important;
  font-weight: bold !important;
}

/* make current date view as black */
.dark-calendar .rbc-current {
  color: black;
}

/* red color border for current date */
.rbc-day-bg.rbc-today {
  border-width: 1px 1px 1px 1px;
  border-color: red;
  border-style: solid;
}

.login-footer {
  position: absolute;
  bottom: 0;
  right: 0;
}

.daily-calendar .rbc-event-content {
  font-size: 13px;
}

/* heading css */
.heading {
  font-size: 35px;
}

/* blast css */
fieldset.scheduler-border {
  padding: 0 1.25em 1.2em 1.25em !important;
  margin: 0 0 0 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

.border-dark {
  border: 2px groove #fff !important;
}

.border-light {
  border: 2px groove #000 !important;
}
fieldset.scheduler-border.border-dark .form-group input {
  border-color: #fff;
  background-color: transparent;
  color: #fff;
}
fieldset.scheduler-border.border-dark .form-group .show-pwd-dark-change {
  color: #fff;
}

fieldset.scheduler-border.border-dark
  .form-group
  .form-control::-webkit-input-placeholder {
  color: #fff !important;
}
fieldset.scheduler-border.border-dark
  .form-group
  .form-control::-moz-placeholder {
  color: #fff !important;
}
fieldset.scheduler-border.border-dark
  .form-group
  .form-control:-ms-input-placeholder {
  color: #fff !important;
}
fieldset.scheduler-border.border-dark
  .form-group
  .form-control:-moz-placeholder {
  color: #fff !important;
}

legend.scheduler-border {
  width: inherit; /* Or auto */
  padding: 0 10px; /* To give a bit of padding on the left and right */
  border-bottom: none;
  margin: 0px !important;
}

.blast-legend {
  height: 100%;
  /* min-height: 227px; */
}

/* .blast-list{
  font-size:13px
} */

.blastTitle {
  margin-bottom: 0px !important;
}

.blast-legend.blastFieldSet {
  border: 2px groove #000 !important;
}

.blast-legend.blastFieldSet.dark {
  border: 2px groove #fff !important;
}

.blastContainer {
  display: grid;
  /* grid-template-rows: minmax(58px, auto) minmax(32px, auto) minmax(38px, auto);
  grid-row-gap: 10px; */
  grid-template-rows: minmax(58px, auto) minmax(32px, auto) minmax(30px, auto);
  grid-row-gap: 4px;
  margin-left: -10px !important;
}

.blastRow2 {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 10px;
  align-items: center;
}

.blastP {
  margin-bottom: 0px !important;
}

.blast-button {
  min-width: 122px;
  padding: 4px 7px;
  font-size: 0.7rem;
  margin-top: 0px !important;
}

.picomplete {
  position: relative;
  display: inline-block;
}

.picomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  background-color: #fff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0px;
  right: 0;
  width: 92%;
  overflow-y: auto;
  max-height: 195px;
  scrollbar-width: thin !important;
  width: 100%;
  border-radius: 0 0 5px 5px !important;
}
.picomplete-items div {
  padding: 6px 10px;
  cursor: pointer;
  border-top: 0;
  border-bottom: 1px solid #d4d4d4;
}
.picomplete-items div:hover {
  background-color: #e9e9e9;
}

.beta {
  font-family: Calibri !important;
  padding-bottom: 20px;
}

legend {
  font-size: 1.2rem;
}
.reminderContainer {
  display: grid;
  align-items: end;
  grid-template-columns: auto 1fr;
}
.reminderText {
  margin-bottom: 5px;
}
.reminderText1 {
  margin-bottom: 14px;
}

.text-size {
  font-size: 0.8rem !important;
}

.box {
  width: 100%;
  padding: 10px;
  border: 0.5px solid gray;
  margin: 0;
}

.inline-doc {
  display: inline-block;
}

.help-section .top-links {
  font-size: 20px;
  font-weight: 700;
}
.help-section .top-links li {
  margin: 0 5px;
}
.help-section .top-links li a {
  text-transform: uppercase;
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
}
.help-section .accordion {
  border: 0;
}
.help-section .accordion .AccordionPannel h4 {
  font-weight: 800;
}
.help-section .accordion .accordion__item {
  border-color: #606060;
  border-width: 1px;
  border-style: solid;
  background: #f4f4f4;
  border-bottom: transparent;
}
.calendar-container-dark .help-section .accordion .accordion__item,
.calendar-container-dark .help-section .accordion .accordion__item:last-child,
.calendar-container-dark .frame-container {
  border-color: #fff;
}
.calendar-container-dark .help-section .accordion .accordion__button,
.calendar-container-dark .help-section .accordion .accordion__panel {
  color: #fff;
}
.help-section .accordion .accordion__item:last-child {
  border-bottom-width: 1px;
  border-color: #606060;
  border-style: solid;
}
.help-section .accordion .accordion__button {
  font-weight: bold;
  font-size: 16px;
  color: #000;
  position: relative;
  padding-right: 25px;
  background-color: #f4f4f4;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  position: relative;
}
.help-section .accordion .accordion__button:before {
  position: absolute;
  right: 10px;
  top: 30px;
  -webkit-transform: rotate(45deg) translateY(-100%);
  transform: rotate(45deg) translateY(-100%);
  margin-left: 5px;
}
.help-section .accordion .accordion__button[aria-expanded='true']::before,
.help-section .accordion .accordion__button[aria-selected='true']::before {
  transform: rotate(-135deg) translateY(100%);
}
.help-section .accordion .accordion__panel {
  font-size: 1.2rem;
  color: #000;
  padding-top: 0px;
}
.help-section .accordion .AccordionList {
  margin-bottom: 5px;
}
.help-section .moreLink {
  display: block;
  margin-left: auto;
  width: max-content;
  color: #000;
  font-weight: 600;
  font-size: 20px;
}
.calendar-container-dark .help-section .moreLink {
  color: #ffffff;
}
.help-section .moreLink i {
  font-size: 16px;
}
.help-section .video-section p {
  margin-bottom: 5px;
  font-size: 20px;
}
.help-section .video-section .video {
  margin-bottom: 0;
}
.help-section .video-section .video iframe {
  height: 15vw;
}

/***************** ayushi ******************/
ul.ul_plans {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 6px 20px;
}
ul.list-unstyled.footer-links li {
  min-width: 30px;
  margin: 0px;
  margin-left: 12px;
}
ul.list-unstyled.footer-links li img {
  height: 35px !important;
  width: 35px !important;
}
ul.list-unstyled.footer-links {
  display: flex;
  justify-content: flex-end;
}

.signup-btn .btn_save_bt {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.writing-promoting-section .main-title h2,
.features-plans-section .main-title h2,
.our-members-section .main-title h2 {
  margin-bottom: 50px !important;
}

h1 {
  font-size: 35px !important;
  font-weight: 700 !important;
  font-family: 'Raleway' !important;
}
h2 {
  font-size: 35px !important;
  font-weight: 600 !important;
  font-family: 'Raleway' !important;
  text-transform: uppercase !important;
}
h3 {
  font-size: 26px !important;
  font-weight: 700 !important;
  font-family: 'Raleway' !important;
}
h4 {
  font-size: 22px !important;
  font-weight: 700 !important;
  font-family: 'Raleway' !important;
}
h5 {
  font-size: 20px !important;
  font-weight: 700 !important;
  font-family: 'Raleway' !important;
}
h6 {
  font-size: 18px !important;
  font-weight: 700;
  font-family: 'Raleway' !important;
}
p {
  font-size: 18px !important;
  font-weight: 400 !important;
  font-family: 'Raleway' !important;
  line-height: 1.5 !important;
}
input[type='text'],
input[type='email'],
input[type='url'] {
  height: 38px !important;
  font-size: 16px !important;
  font-family: 'Raleway' !important;
  font-weight: 600 !important;
  padding: 5px 10px !important;
  color: #7c7c7c !important;
  border-radius: 5px !important;
}
input[type='password'] {
  height: 38px !important;
  font-size: 16px !important;
  font-family: 'Raleway' !important;
  font-weight: 600 !important;
  padding: 5px 10px !important;
  color: #7c7c7c !important;
  border-radius: 5px !important;
}
select.form-control {
  height: 38px !important;
  font-size: 16px !important;
  font-family: 'Raleway' !important;
  font-weight: 600 !important;
  padding: 5px 10px !important;
  color: #7c7c7c !important;
  border-radius: 5px !important;
}
input.form-control::placeholder {
  color: #7c7c7c !important;
  font-family: 'Raleway' !important;
  font-size: 16px !important;
}
nav.navbar input[type='text'],
nav.navbar input[type='password'] {
  background-color: white !important;
}
button,
input.btn.input-btn {
  height: 46px !important;
  font-size: 16px !important;
  padding: 10px !important;
  min-width: 150px !important;
  border-radius: 8px !important;
  font-family: 'Raleway' !important;
  font-weight: 700 !important;
  line-height: initial !important;
  text-transform: uppercase !important;
}
.landing-page h2 {
  font-size: 35px !important;
  text-transform: capitalize !important;
}
.home-main-page .landing-page h2 {
  font-size: 34px !important;
  text-transform: capitalize !important;
}
.main_right.author_profile nav.navbar {
  box-shadow: none !important;
}
body.home-main-page .calendar-container {
  padding: 0px;
  margin-bottom: 0rem !important;
}
.home-main-page .our-members-section .members-list em {
  font-size: 18px;
}

.optimizeBox p {
  font-size: 22px !important;
}
.filter-section .search_box button {
  background: none;
  border: none;
  position: relative;
  left: 0;
  top: 0px;
  width: auto;
  min-width: auto !important;
  min-height: auto !important;
  height: auto !important;
  padding: 5px 10px !important;
}
.filter-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.filter-section div {
  margin: 0px 5px;
}
.advertise-logged-outbtn {
  display: flex;
  justify-content: center;
}

.advertise-logged-outbtn a {
  border-radius: 5px !important;
  margin: 20px 15px 0px 15px;
}
.filter-section div:first-child {
  margin-left: 0px !important;
}
.filter-section .sport a {
  display: flex;
  align-items: center;
}
.filter-section input {
  border-radius: 8px !important;
  border: 1px solid #1f1f1f;
}
.loginScreen .show-pwd {
  color: white;
  right: 4%;
  display: inline;
  position: absolute;
  top: 6px;
  font-size: 20px;
  background: transparent;
}
.loginScreen .form-control {
  border-radius: 0px !important;
}
.loginScreen .email {
  background-size: 22px;
}

.privacy-policy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.privacy-policy button.close {
  width: auto !important;
  min-width: auto !important;
  padding: 0px !important;
}
.privacy-policy button.close i {
  font-size: 24px;
  font-size: 45px;
  color: white;
  opacity: 1 !important;
}
.privacy-policy button.close {
  opacity: 1 !important;
}
.privacy-content .red {
  color: rgb(255 0 0);
}
.faq-search {
  max-width: 50%;
  margin: 0px auto;
}

.faq-search img {
  position: absolute;
  left: 7px;
  top: 9px;
  width: 19px;
}

.faq-search {
  position: relative;
}

.faq-search input {
  padding-right: 35px !important;
  margin-top: 10px;
  height: 35px !important;
}
input.btn.input-btn {
  border-radius: 5px !important;
  background: #1f1f1f !important;
  box-shadow: none !important;
  color: #ffffff;
  font-weight: 600 !important;
  font-family: 'Raleway';
  margin-bottom: 15px;
}
form#create-course-form .form-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
form#create-course-form .form-group label {
  white-space: nowrap;
  width: 100%;
}
form#create-course-form input {
  background: #f7f7f7;
}
form#create-course-form input:disabled {
  opacity: 0.8;
  cursor: not-allowed;
  background: rgb(206 212 218);
  border-color: rgb(206 212 218);
}
/* form#create-course-form input:disabled:before {
  content: '';
} */
/* .react-datepicker__navigation {
  background: none !important;
  line-height: 1.7rem !important;
  text-align: center!important;
  cursor: pointer!important;
  position: absolute!important;
  top: 10px!important;
  width: 0!important;
  padding: 0!important;
  border: 0.45rem solid transparent!important;
  z-index: 1!important;
  height: 10px!important;
  width: 10px!important;
  text-indent: -999em!important;
  overflow: hidden!important;
} */
.react-datepicker__navigation {
  background: none !important;
  line-height: 1.7rem !important;
  text-align: center !important;
  cursor: pointer !important;
  position: absolute !important;
  top: 10px !important;
  width: 0 !important;
  padding: 0 !important;
  z-index: 1 !important;
  height: 10px !important;
  width: 10px !important;
  text-indent: -999em !important;
  overflow: hidden !important;
  min-width: auto !important;
  border-radius: 0px !important;
}
.react-datepicker__navigation:focus {
  outline: none !important;
  box-shadow: none !important;
}
.book-release label {
  width: 45% !important;
  margin-left: 0;
}

.book-release label input {
  width: 45% !important;
}

.book-release input#exampleEmail {
  width: 25%;
  padding: 10px;
  height: 38px;
}
.book-release .react-datepicker-wrapper {
  width: 100%;
  height: 38px;
}
.book-release .react-datepicker__input-container {
  width: 100% !important;
}
.edit-event .react-datepicker__input-container {
  margin-bottom: 15px !important;
}
.book-release .react-datepicker__input-container input {
  width: 100% !important;
  border: 1px solid #ced4da;
  color: #495057;
}
.book-release .react-datepicker__input-container input:focus {
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.book-release .react-datepicker__input-container input::placeholder {
  color: #7c7c7c !important;
  font-family: 'Raleway' !important;
  font-size: 16px !important;
}
.book-release i {
  right: 15px;
}
.book-release-input {
  position: relative;
  width: 25%;
}
.book-release i {
  right: 10px;
  position: absolute;
  top: 11px;
  pointer-events: none;
  cursor: pointer;
}
.upload_text input {
  background-color: transparent !important;
  font-size: 16px;
  border-radius: 5px !important;
}
.bg-btn {
  background: #1f1f1f;
  color: white;
}
/************* calendar page ****************/
.calendar-main-page span.rbc-btn-group button,
.daily-task-page span.rbc-btn-group button {
  margin-right: 0px !important;
  height: auto !important;
  font-size: inherit !important;
  min-width: auto !important;
  font-family: 'Raleway' !important;
  font-weight: 600 !important;
  line-height: initial !important;
  text-transform: uppercase !important;
  padding: 0.375rem 1rem !important;
  border-radius: 0px !important;
  line-height: normal !important;
}
.calendar-main-page .setup-btn button img {
  width: 20px;
  margin-left: 5px;
  filter: invert(1);
}

.calendar-main-page .setup-btn button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar-main-page .setup-btn {
  display: flex;
  justify-content: flex-end;
  z-index: 999999;
}
.calendar-main-page .rbc-today {
  background-color: #f3f3f3;
}
.calendar-main-page .rbc-day-bg.rbc-today {
  border-width: 2.5px;
}
.ag-theme-balham span button {
  min-width: auto !important;
  height: auto !important;
  padding: 2px 20px !important;
  font-size: 12px !important;
  background: #1f1f1f;
  color: white;
  border-radius: 25px !important;
}

.pageNo {
  font-size: 14px;
  font-family: 'Raleway';
  font-weight: 500;
}

.pageNo select {
  margin-left: 10px;
}

.test-header {
  margin-top: 10px;
}

.ag-paging-panel {
  font-size: 14px;
  font-weight: 500;
}
.user-listing-page .btn-danger {
  color: #fff;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
.daily-task-page .add-btn {
  position: absolute;
  right: 30px;
  z-index: 99;
}
.calendar-main-page .setup-btn {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0px;
  bottom: -55px;
  z-index: 99;
}
.help-section legend {
  text-align: left;
}
.help-section .accordion .accordion__panel p,
.help-section .accordion .accordion__panel ul li,
.help-section .accordion .accordion__panel span,
.accordion .accordion__panel a {
  text-align: left;
  font-size: 16px !important;
}
.help-section .accordion .accordion__panel p:last-child {
  margin-bottom: 0;
}
.help-section .accordion .accordion__panel span.link-span {
  color: #007bff !important;
  font-weight: 600;
}
.help-section .accordion .accordion__panel span.link-span:hover {
  cursor: pointer;
  text-decoration: underline;
}
.help-section .accordion .accordion__panel ul {
  margin-bottom: 0;
}
div#video-section p {
  font-weight: 700 !important;
}
.btn-danger {
  color: #fff !important;
  background-color: #c82333 !important;
  border-color: #bd2130 !important;
}
.help-section .accordion .accordion__button {
  padding-right: 30px;
}
#create-course-form .box {
  padding: 5px;
}
.advertise-page fieldset.scheduler-border {
  padding: 0px 12px 12px 12px !important;
}
.advertise-page legend.scheduler-border {
  padding: 0 5px;
  margin-bottom: 5px !important;
}
.terms-link span {
  color: #007bff !important;
  font-weight: 600;
  cursor: pointer;
}
.advertise-privacy-popup p {
  margin-bottom: 0px;
}
.advertise-privacy-popup button {
  margin-top: 20px;
}

.signupmain.signup-page-one .button_btft {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  padding: 0px;
}
@media only screen and (max-width: 767px) {
  .help-section .video-section .video {
    margin-bottom: 20px;
  }
  .help-section .video-section .video iframe {
    height: 50vw;
  }
  .frame-container {
    padding: 15px;
  }
}

.home-page {
  padding-bottom: 100px;
}
.home-main-page .home-page {
  padding-bottom: 0;
}
.timeline-section .main-title .subTitle {
  font-style: italic;
}

.userway-s3-1 .header-secion,
.userway-s3-1-ff .header-secion,
.userway-s3-2 .writing-promoting-section .ul_plans li .divImg img,
.userway-s3-3 .navigation.bg-light,
.userway-s3-3 .main_left,
.userway-s3-3 .logo_3 img {
  filter: invert(100%) !important;
  -webkit-filter: invert(100%) !important;
}
.userway-s3-1-ff .optimizeBox .brand-logo,
.userway-s3-2 .optimizeBox .brand-logo {
  filter: invert(100%) !important;
  -webkit-filter: invert(100%) !important;
}
.userway-s3-2 .header-secion {
  border-bottom: 2px solid white;
  background-color: white !important;
}
.userway-s3-2 input:checked + .slider {
  background-color: #68b5ff;
}
.userway-s3-2 button {
  background-color: rgb(255 255 255) !important;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  border-color: rgb(255, 255, 255) !important;
  color: rgb(80, 208, 160) !important;
}
.userway-s3-2 section.timeline-section .divImg img,
.userway-s3-1 .calendar-main-page .rbc-day-bg.rbc-today,
.userway-s3-1-ff .calendar-main-page .rbc-day-bg.rbc-today,
.userway-s3-1 .calendar-main-page .rbc-day-bg.rbc-off-range-bg,
.userway-s3-1-ff .calendar-main-page .rbc-day-bg.rbc-off-range-bg {
  filter: invert(100%) !important;
  -webkit-filter: invert(100%) !important;
}
.container.calendar-main-page .rbc-event {
  width: 98%;
}
.daily-calendar .rbc-day-bg.rbc-today {
  background: #f3f3f3 !important;
  border-width: 2px;
}
.messenge-page .compose.compose-dark button {
  min-width: auto !important;
  border-radius: 0px !important;
  padding: 9px 10px !important;
  min-height: auto !important;
  height: auto !important;
  outline: none !important;
  box-shadow: none !important;
}
.messenge-page .compose.compose-dark button i {
  font-size: 20px !important;
}
.messenge-page .messages-listing,
.message-list-container {
  height: 475px;
  overflow: auto;
  height: calc(100vh - 396px);
}
.messenge-page .messages-listing::-webkit-scrollbar,
.message-list-container::-webkit-scrollbar {
  width: 5px;
}

.messenge-page .messages-listing::-webkit-scrollbar-track,
.message-list-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.messenge-page .messages-listing::-webkit-scrollbar-thumb,
.message-list-container::-webkit-scrollbar-thumb {
  background: #888;
}
.messenge-page .conversation-list-item .conversation-photo {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  padding: 2px;
  background-image: linear-gradient(45deg, transparent, transparent);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.messenge-page .conversation-list-item .conversation-photo img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
  background: #fff;
}
.messenge-page .conversation-list-item {
  width: 100% !important;
}
.messenge-page {
  display: flex;
  justify-content: space-between;
  border: 1px solid #000;
}

.messenge-page .conversation-list {
  max-width: 300px;
  width: 100%;
  background: #ededed;
  overflow-x: hidden;
  border-right: 2px solid #000;
}
.messenge-page .compose.compose-dark {
  backdrop-filter: none !important;
  padding: 0px;
  background-color: white !important;
  /* margin-bottom: -2px; */
  margin-bottom: 0;
}
.messenge-page .scrollable.content {
  width: calc(100% - 300px);
}
.messenge-page .toolbar {
  backdrop-filter: none !important;
  background: #cbcbcb;
}
.messenge-page .toolbar button {
  min-width: auto !important;
  filter: invert(1);
}
.model_cover.my-profile .eventModal .profile_image {
  height: 160px !important;
  width: 160px !important;
  padding: 5px;
  overflow: hidden;
  background-image: linear-gradient(45deg, transparent, transparent);
}
.model_cover.my-profile .eventModal .profile_image img,
.profile_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  background: #fff;
}
.model_cover.my-profile .eventModal {
  margin-top: 0px;
}
.my-profile .event_body {
  margin-top: 20px !important;
}
.tox .tox-tbtn {
  border: 0 !important;
  border-radius: 3px !important;
  box-shadow: none;
  color: #222f3e !important;
  display: flex !important;
  flex: 0 0 auto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  height: 34px !important;
  justify-content: center !important;
  margin: 2px 0 3px 0 !important;
  outline: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  text-transform: none !important;
  width: 34px !important;
  min-width: auto !important;
}
.tox.tox-tinymce {
  height: 250px !important;
}
fieldset.scheduler-border {
  padding-bottom: 2px !important;
}
.react-tiny-popover-container button.btn.btn-link {
  text-transform: capitalize !important;
}
.react-tiny-popover-container .sub_notification {
  display: flex;
}

.control-group.blast-list.blastContainer .blastP {
  font-weight: 400;
  font-style: normal;
}
.popup .modal-content {
  min-height: 500px;
}

.popup .form-check h6 {
  text-align: center;
  margin-left: -10px;
  margin-bottom: 30px;
}

.popup .blast-purchase-btn .form-check {
  text-align: center;
}

.popup .blast-purchase-btn .form-check .promo-code {
  margin-top: 15px;
  background-color: black;
}

.popup .blast-purchase-btn .form-check .promo-code button.btn.bg-btn {
  color: white;
}
.popup .text-blast {
  margin-top: 20px;
  margin-left: 20px;
}
.popup .blast-purchase-btn.upgrade-btn .form-check input {
  width: 70%;
  border: 1px solid;
  background-color: #f7f7f7;
  border-color: #000;
  /* border-width: medium; */
}
.popup .modal-body h6 span {
  font-weight: 500;
  font-size: 18px;
  font-style: italic;
}

.popup .modal-body h6 img {
  height: 10px;
  font-weight: 100;
  margin-top: -10px;
}

.popup .modal-body h6 {
  margin-top: 20px;
}
.popup .blast-purchase-btn.upgrade-btn .form-check button {
  width: 70%;
  margin-right: 64px;
  margin-bottom: 20px;
}
.popup .text-blast {
  margin-top: 20px;
  margin-left: 20px;
  font-style: italic;
  font-size: 16px;
  line-height: 1;
}
.daily-task-page {
  position: relative;
}
.daily-task-page .add-btn {
  right: 0;
  position: absolute;
  z-index: 99;
}
.calendar-container a {
  border-radius: 9px;
}

.popup .blast-purchase-btn {
  margin-top: -15px;
}

.calendar-container a {
  margin-top: unset !important;
  font-size: 14px;
  /* text-transform: uppercase; */
}
.calendar-container .social-links a,
.calendar-dark .social-links a {
  font-size: 18px;
}
.planBtn {
  font-size: 14px !important;
  line-height: 1 !important;
  height: 46px;
  width: 156px;
  padding: 10px 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.planBtn:hover {
  opacity: 0.98;
}
.scheduler-border.border-light button {
  margin-top: unset;
}
.calendar span.rbc-btn-group {
  position: absolute;
  left: 0;
}
.calendar span.rbc-toolbar-label {
  margin-right: 0px;
}
.scheduler-border a:hover {
  color: red;
}
.modal1 p {
  margin-left: 23px;
}
/* .left .calendar span.rbc-btn-group {
  position: unset;
} */

.help-section a.moreLink:hover {
  color: black;
}
.modal-body p {
  font-size: 14px !important;
}

.container.edit-preferences-page .toggle-section p {
  display: flex;
}

.container.edit-preferences-page .toggle-section .toggle_switch {
  margin-left: 20px;
}
.advertise-page .upload_text input {
  width: 100% !important;
}
.calendar-container .control-group {
  margin-top: 0px !important;
}
.calendar-container .control-group .toggle_switch {
  margin-top: 0px !important;
}
.calendar-container .control-group.blast-list.blastContainer .blastP {
  font-size: 13px !important;
}
.calendar-container .pr-blogger .details-box .blastContainer .blastP {
  font-size: 14px !important;
}
.calendar-container .upload_text p {
  font-size: 17px !important;
}
.calendar-container-dark .rbc-toolbar {
  position: relative;
}
.calendar-dark pre {
  color: white !important;
  font-size: 13px;
}
.calendar-container .rbc-toolbar {
  position: relative;
}
.calendar-container-dark .help-section .accordion .accordion__button,
.calendar-container-dark .help-section .accordion .accordion__panel {
  color: #0e0e0e;
}
.calendar-container-dark .help-section .accordion .accordion__item,
.calendar-container-dark .help-section .accordion .accordion__item:last-child,
.calendar-container-dark .frame-container {
  border-color: #000 !important;
}
.calendar-dark .setup-btn .setup_event_bt.daily {
  background-color: #fbfafa !important;
  color: #060606 !important;
}
.calendar-dark .conversation-title {
  color: #000 !important;
}
.calendar-dark .message .bubble-container .bubble {
  color: #000 !important;
  background-color: white;
}
.calendar-dark span button {
  min-width: auto !important;
  height: auto !important;
  padding: 2px 20px !important;
  font-size: 12px !important;
  background: #1f1f1f;
  color: white;
  border-radius: 25px !important;
}
.calendar-container-dark .boxbutton {
  position: relative;
  padding: 20px 20px 20px 7px !important;
}
.calendar-container-dark .boxbutton button.btn.btn_save_bt.dark-button {
  float: none;
}
.box.boxbutton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.calendar-dark .mid-section {
  background: #202020;
}
.calendar-dark .main-wrapper {
  background: #202020 !important;
}

.calendar-container .boxbutton {
  position: relative;
  padding: 20px 20px 20px 7px !important;
}
.calendar-container .boxbutton .btn {
  position: absolute;
  right: 12px;
  bottom: 12px;
}
.calendar-container .boxbutton span {
  font-weight: 600;
}
.calendar-dark form#create-course-form .form-group input::placeholder {
  color: #202020 !important;
}

.calendar-dark .edit-profile-btn button {
  background-color: #fff !important;
  color: #000 !important;
}
.calendar-container #table1 .details1 {
  border-bottom: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
}
.calendar-container #table1 .text-center {
  border-bottom: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
}
.calendar-container #table1 .alignMiddle {
  border-bottom: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  /* border-left: 1px solid #000000 !important; */
}
.calendar-container #table1 .head {
  border-top: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
}
.calendar-container #table1 #border2 {
  border-left: 1px solid #000000 !important;
}
.calendar-container #table1 .aligncenter {
  border-left: 1px solid #000000 !important;
}
.calendar-container #table1 .text_data2 > li > i {
  font-size: 12px !important;
  margin-top: 0px !important;
}
.calendar-dark .add-btn img {
  background: #fff !important;
}

.calendar-dark .setup-btn img {
  background: #fff !important;
}
.calendar-container .add_to_beta_text p {
  font-size: 12px !important;
}
.calendar-dark .add_to_beta_text p {
  font-size: 12px !important;
}
.calendar-dark #table1 .text_data2 > li > i {
  font-size: 12px !important;
  margin-top: 0px !important;
}
.calendar-container .stickhead1 {
  padding: 0px 0px !important;
}
.main-content .stickhead1 {
  padding: 0px 0px !important;
}
.main-content #table1 .text_data2 > li > i.fa-info-circle {
  font-size: 12px !important;
  margin-top: 0px !important;
  margin-right: 8px !important;
}
.main-content #table1 .text_data2 > li > i {
  font-size: 12px !important;
  margin-top: 0px !important;
}
.calendar-container #table1 .details1 {
  border-bottom: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
}
.calendar-container #table1 .text-center {
  border-bottom: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
}
.calendar-container #table1 .alignMiddle {
  border-bottom: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
}
.calendar-container #table1 .head {
  border-top: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
}
.calendar-container #table1 #border2 {
  border-left: 1px solid #000000 !important;
}
.calendar-container #table1 .aligncenter {
  border-left: 1px solid #000000 !important;
}

.calendar-container .stickhead1 .header-tag {
  font-size: 14px !important;
}
.calendar-dark .stickhead1 .header-tag {
  font-size: 14px !important;
}
.main-content .stickhead1 .header-tag {
  font-size: 14px !important;
}
.main-content .details1 {
  padding: 0px 0px !important;
}
.main-content .black span {
  font-size: 8px !important;
}
/* .middle-content .black {
  font-size: 15px !important;
} */
.main-content .black p {
  font-size: 10px !important;
}
/* .calendar-container .black {
  font-size: 25px !important;
} */
.ag-theme-balham
  .ag-ltr
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected) {
  outline: none !important;
  border: none !important;
}

.calendar-dark .add-btn button.btn.btn_save_bt.daily img {
  filter: inherit;
}
.calendar-dark .calendar-main-page .setup-btn button img {
  filter: inherit;
}
.calendar-dark .main_right {
  background-color: #202020;
}
.calendar-dark .thanktwo_main {
  background-color: #202020;
}
.calendar-dark .main_right .author_main_s h1 {
  color: #7e7d7d;
}
.calendar-dark .main_right .btn-filled {
  background: #fff;
  color: #000;
}
.calendar-dark .in_title {
  background: none;
}
.calendar-dark .bg-btn {
  background: #ffffff;
  color: rgb(2, 2, 2);
}
.popup .blast-purchase-btn button {
  border: 2px solid #000000;
}
.calendar-dark .upload_text p {
  font-size: 17px !important;
}
.remove-header #table1 ul.text_data {
  margin-left: 17px !important;
}
.author-popup .form-check h6 {
  text-align: center;
}
.renew-membership .btn {
  margin-right: 0px !important;
}
.upgrade-btn .up-btn {
  margin-right: 0px !important;
}
.report-problem {
  font-family: 'Raleway' !important;
}
.report-problem .form-group {
  margin-bottom: 2px !important;
}
.report-problem .form-group label {
  margin-bottom: 0px !important;
}
.report-popup .modal-header {
  display: flex;
  justify-content: center;
}
.button {
  border-radius: 50px !important;
  min-width: 100px !important;
  font-size: 13px !important;
  height: 35px !important;
}
.form-group textarea::placeholder {
  color: #7c7c80 !important;
}
#table1 .text_data4 {
  margin-bottom: 0px !important;
}
#table1 .text_data3 {
  padding: 0px 5px !important;
}
.fa-info-circle {
  color: darkgrey;
}
.content ul.text_data li:last-child {
  margin-bottom: 2px;
}
.middle-text span {
  display: block;
  font-size: 0.7rem;
  letter-spacing: 0.5px;
}
.calendar-dark a {
  margin-top: unset !important;
  border-radius: 9px;
}
.report-problem .form-group select {
  margin-bottom: 20px !important;
}
.profile_model .event-poup-btn button {
  background-color: #1f1f1f;
  color: #fff;
}
.calendar-dark .edit-event {
  font-family: 'Raleway';
  font-size: inherit;
}
.calendar-dark .edit-event .upload_text span {
  color: #fff !important;
}
.calendar-dark .edit-event .checkbox_label_black {
  border: calc(17px * 0.125) solid white;
}
.calendar-dark .edit-event .ft_text span img {
  /* background-color: white; */
  filter: invert(1);
}

.calendar-dark .edit-event .in_title {
  background: #202020;
}
.calendar-dark input.btn.input-btn {
  background: #f7f7f7 !important;
  color: #000000;
}

.text-danger {
  color: #ff041d !important;
}
.calendar-dark #table1 th.stickhead {
  background: #202020;
  color: #fff;
}
.calendar-dark .black {
  color: white;
}
.calendar-dark #table1 #border {
  border-color: #202020 !important;
}
.calendar-dark .content {
  background: #202020;
}
.calendar-dark .new_btn_save_bt {
  background-color: #fff;
  color: #000;
  /* margin-top:30px !important; */
}
.calendar-dark .book-release i {
  color: #000;
}
.event-poup-btn .new_btn_save_bt {
  margin-top: 0px !important;
  margin-left: 2px !important;
}
/* .mainleft {
  flex-wrap: nowrap;
  min-height: 625px;
  height: 100%;
} */
.modal-body ol {
  font-size: 14px;
}
.ql-snow .ql-editor h1 {
  font-size: 1.5em !important;
}
.scheduler-border {
  position: relative;
}
.purchase-btn {
  position: absolute;
  bottom: 4px;
  right: 17px;
}
.purchase-btn {
  position: absolute;
  bottom: 4px;
  right: 17px;
}
.blast-popup label {
  margin-bottom: 0px;
}
.modal-body p img {
  width: 100%;
}
.calendar-dark .purchase-btn {
  position: absolute;
  bottom: 4px;
  right: 17px;
}

.my-membership-page .list-box {
  border: 1px solid #ccc;
}
.my-membership-page .list-box ul {
  list-style: none;
  margin: 8px;
  padding-left: 10px !important;
}

.input-code {
  margin-top: 8px;
  margin-bottom: 8px;
}
.calendar-container-dark .help-section .frame-container {
  border-color: #f4f4f4 !important;
}
.calendar-container-dark
  .help-section
  .frame-container
  .frame-content
  .form-control {
  border-color: #b4b1b1;
}
/* .calendar-container-dark
  .help-section
  .frame-container
  .frame-content
  .btn_save_bt {
  background-color: black !important;
  color: white !important;
} */
.remove-footer .footer {
  display: none;
}
.signup-page-one .text-bottom {
  font-size: 80% !important;
  text-align: center;
}
.signup-page-one form p {
  font-size: 80% !important;
}
.ag-body-viewport.ag-layout-auto-height .ag-center-cols-clipper,
.ag-body-viewport.ag-layout-auto-height .ag-center-cols-container {
  min-height: 20px;
}
.reader-price {
  margin-bottom: 0px;
}
.my-membership-page fieldset.scheduler-border {
  /* padding-bottom: 8px !important; */
  padding-bottom: 1em !important;
}
.label {
  margin-bottom: 0px !important;
}
.signupmain.signup-page-one .input-section .show-pwd-dark {
  top: 8px;
}
.message-popup .form-group.col-md-12 {
  position: relative;
}

.message-popup section.emoji-mart.emoji-mart-light {
  position: absolute;
  bottom: 0px;
  width: 100% !important;
  left: 0px;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.message-popup section.emoji-mart.emoji-mart-light::-webkit-scrollbar {
  width: 5px;
}

.message-popup section.emoji-mart.emoji-mart-light::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.message-popup section.emoji-mart.emoji-mart-light::-webkit-scrollbar-thumb {
  background: #888;
}
.emoji-mart .emoji-mart-emoji,
section.emoji-mart-search button {
  padding: 6px !important;
  height: auto !important;
  min-width: auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.emoji-mart .emoji-mart-emoji:focus,
.emoji-mart .emoji-mart-emoji:hover {
  cursor: pointer;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  background: #ededed;
}
.choose-emoji {
  position: relative;
}

.choose-emoji .emojipicker {
  position: absolute;
  top: 8px;
  left: unset;
  right: 22px;
  cursor: pointer;
  transform: scale(1.2);
}
.choose-emoji textarea {
  padding: 5px 30px 5px 10px;
}
.blast-purchase h6 {
  font-weight: 500;
  font-size: 20px !important;
}
.blast-purchase .input-text h6 {
  font-style: italic;
  font-size: 16.5px !important;
  font-weight: 400;
  margin: 0px 0px !important;
}
.blast-purchase .modal-dialog {
  max-width: 550px;
}
.blast-purchase .promo-code-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0px !important;
}
.blast-purchase .promo-code-content span {
  font-weight: 400 !important;
  font-size: 16.5px !important;
}
.blast-purchase .promo-code-content img {
  height: 14px !important;
  font-weight: 100;
  margin-top: 0;
  margin-right: 5px;
  margin-top: 0px !important;
}
.blast-purchase .input-text input[type='checkbox'] {
  height: 25px !important;
  width: 62px !important;
}
.blast-purchase .blast-purchase-btn button {
  border-radius: 40px !important;
  font-size: 16px !important;
}
.blast-purchase .add-on-btn button:first-child {
  border: 3px solid red;
  text-transform: initial !important;
}
.blast-purchase a {
  color: #2026e9 !important;
  text-decoration: underline;
}
.blast-purchase .cross-icon {
  display: flex;
  justify-content: flex-end;
}

.blast-purchase .cross-icon img {
  width: 15px;
  cursor: pointer;
}
.add-on-purchase .blast-purchase-btn button:first-child {
  border: 3px solid #6ca8ff !important;
  text-transform: initial !important;
}
.promo-code {
  margin-left: 20px;
  font-style: italic;
  font-weight: 500 !important;
}

.row.event-advertise-text {
  align-items: end;
}
.report-popup .modal-title:after {
  width: 100%;
}

.privacy-page {
  min-height: calc(100vh - 170px);
  position: relative;
  padding: 20px 0 80px;
}
.dashboard-btn {
  position: absolute;
  bottom: 0px;
  right: 15px;
}
.set-up-profile .full_title .form-group button:last-child {
  margin-left: 20px !important;
  float: none !important;
}

.set-up-profile .full_title .form-group button {
  float: none !important;
}

.upload_text img {
  height: 400px;
  width: 200px;
}
.align-advertise-btn {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.upload_text-one img {
  height: 733px;
  width: 90px;
}

/********* new message page css **********/
.messenge-page .conversation-list .user-avatar-image {
  display: none;
}
.message-list .right-items button.btn {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.message-list .right-items button.btn:focus {
  outline: none;
  box-shadow: none;
}
.right-items {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.user-avatar-image {
  /* margin-right: 10px; */
  padding: 3px;
  border-radius: 50%;
  height: 44px;
  width: 44px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: linear-gradient(45deg, transparent, transparent);
  display: flex;
  justify-content: center;
  align-items: center;
}
.authorImg {
  /* background-image: url(./Utils/Assets/OTR-color-circle_authors.png) !important; */
  background-image: url(./Utils/Assets/OTR-profile-color_authors-all.png) !important;
}
.vendorImg {
  /* background-image: url(./Utils/Assets/OTR-color-circle_vendors.png) !important; */
  background-image: url(./Utils/Assets/OTR-profile-color_vendor.png) !important;
}
.prFirmImg {
  /* background-image: url(./Utils/Assets/OTR-color-circle_pr-firm.png) !important; */
  background-image: url(./Utils/Assets/OTR-profile-color_pr-firm.png) !important;
}
.bloggerImg {
  /* background-image: url(./Utils/Assets/OTR-color-circle_blogger_1.png) !important; */
  background-image: url(./Utils/Assets/OTR-profile-color_blogger-1_1.png) !important;
}
.user-avatar-image.authorImg,
.conversation-list-item .conversation-photo.authorImg,
.message-list-option .msg-avatarImg.authorImg {
  background-image: url(./Utils/Assets/OTR-color-circle_authors.png) !important;
}
.user-avatar-image.vendorImg,
.conversation-list-item .conversation-photo.vendorImg,
.message-list-option .msg-avatarImg.vendorImg {
  background-image: url(./Utils/Assets/OTR-color-circle_vendors.png) !important;
}
.user-avatar-image.prFirmImg,
.conversation-list-item .conversation-photo.prFirmImg,
.message-list-option .msg-avatarImg.prFirmImg {
  background-image: url(./Utils/Assets/OTR-color-circle_pr-firm.png) !important;
}
.user-avatar-image.bloggerImg,
.conversation-list-item .conversation-photo.bloggerImg,
.message-list-option .msg-avatarImg.bloggerImg {
  background-image: url(./Utils/Assets/OTR-color-circle_blogger_1.png) !important;
}
.user-avatar-image img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: #fff;
  border: 0;
  object-fit: cover;
}
i.toolbar-button.fa.fa-arrow-right {
  color: black;
}
i.toolbar-button.fa.fa-arrow-right:hover {
  color: red;
}
.compose .emojipicker {
  cursor: pointer;
  position: absolute;
  left: unset;
  right: 50px;
  background: transparent;
  padding: 5px;
  margin-right: -10px;
  border-radius: 50%;
}
.compose textarea,
.compose input[type='text'] {
  width: 100%;
  overflow: hidden !important;
  padding-top: 8px !important;
  padding-left: 10px !important;
  padding-right: 30px !important;
  color: black !important;
}
.compose textarea::placeholder,
.compose input[type='text']::placeholder,
.message-search-box input::placeholder,
.compose input[type='text']::-ms-input-placeholder,
.compose textarea::-ms-input-placeholder,
.message-search-box input::-ms-input-placeholder,
.faq-search input.form-control::placeholder,
.faq-search input.form-control::-ms-input-placeholder {
  color: #000 !important;
}
/********* new message page css **********/
/* ************* New sign up page *************  */
.signup_text {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 80px;
  color: #1f1f1f;
  font-size: 17px;
  font-weight: 500;
}
.signup_text.note-txt span i {
  font-weight: 500;
  font-size: 15px;
}
.signupmain.signup-page-one .signup-btn-group {
  margin-right: 0px;
  margin-left: 0px;
  display: flex;
  justify-content: flex-end;
}
.container {
  padding: 10px;
}

.full_title {
  float: left;
  width: 100%;
  padding: 0;
}
.new-btn-filled {
  background-color: #1f1f1f;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  padding: 10px 40px;
}
.new-btn-bordered {
  padding: 9px;
  font-weight: 600;
  color: #000;
  background-color: transparent;
  border: 2px solid;
}
.signuptype_footercover {
  float: left;
  width: 100%;
  padding: 15px 0px;
  margin-top: 50px;
}
.progressbar {
  counter-reset: step;
  width: 100%;
  padding: 25px 0;
  margin-bottom: 0;
  float: none;
  display: flex;
}
button.btn.button_jon_sign {
  margin-top: 10px;
  margin-left: 15px;
  padding: 9px;
  float: right;
  width: 120px;
  font-weight: 600;
  color: #000;
  background-color: transparent;
  border: 2px solid;
}
.btn-filled {
  background-color: #1f1f1f;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  width: 170px;
  padding: 10px;
  float: right;
  margin-right: 15px;
  margin-top: 10px;
}
.bg-btn {
  background: #1f1f1f;
  color: white;
  margin-left: -17px;
}
/* ************* New sign up page *************  */
.help-section .edit-profile-btn {
  margin-right: 0px;
  display: flex;
  justify-content: flex-end;
}
.help-section .edit-profile-btn.mt-2 {
  margin-right: 0px;
}
.membership-plan-page div#scroll {
  padding: 0px;
}
.term-condition .edit-profile-btn {
  padding: 0;
}
.terms-condition {
  min-height: calc(100vh - 170px);
  position: relative;
  padding: 20px 0 80px;
}
.terms-condition .edit-profile-btn {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.terms-condition-second,
.privacy-page-second {
  min-height: calc(100vh - 90px);
  position: relative;
}
/* for profile follow button  */
.profile_detail .btn-danger {
  color: #fff !important;
  background-color: #c82333 !important;
  border-color: #bd2130 !important;
  margin-top: -50px;
}
.profile-detail-page button.btn.new_btn_save_bt.float-md-right {
  margin-top: -50px;
}
/* .main-wrapper .middle-content {
  min-height: calc(100vh - 135px);
  height: 100%;
}  */
.privacy-page-second .edit-profile-btn {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.profile-detail-page .profile_detail .event_body {
  margin-top: 0px;
}
.btn-login-1 .signup-btn .btn_save_bt {
  height: 46px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-login-1 .btn_h_login {
  margin-left: 10px;

  background-color: rgb(0, 0, 0);
  border: 1px solid #1f1f1f;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  padding: 5px 20px;
  border-radius: 0;
  height: 40px !important;
}
.help-section .frame-container .frame-content .form-control {
  background-color: #f2f2f2;
  border-color: #e7e7e7;
  border-radius: 15px;
  padding: 10px;
  font-weight: 600;
  color: gray;
}
.calendar-container-dark .frame-container .frame-content .btn_save_bt {
  background-color: white !important;
  color: black !important;
}
.calendar-container-dark
  .help-section
  .frame-container
  .frame-content
  .btn_save_bt {
  background-color: #fff !important;
  color: #000 !important;
}
.payment-option {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
}
.payment-option-other {
  margin-top: 15px;
}
.search-features p {
  margin-top: -35px;
  font-size: 0.7rem !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.54);
}
.calendar-dark .search-features p {
  color: #ccc;
}
.about-page .form-group {
  margin-bottom: 0;
  float: none;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-left: -20px;
}
.help-section .mb-5,
.my-5 {
  margin-bottom: 0 !important;
}
/* .about-page .new-btn-filled {
  margin-right: 30px;
} */
.header-secion button,
input.btn.input-btn {
  height: 46px !important;
  font-size: 16px !important;
  padding: 10px !important;
  min-width: 150px !important;
  border-radius: 8px !important;
  font-family: 'Raleway' !important;
  font-weight: 700 !important;
  line-height: initial !important;
  text-transform: uppercase !important;
  margin-left: 13.4rem;
}
.my-membership-page .list-box ul li {
  font-size: 16px;
  padding-top: 10px;
}
.my-membership-page .upgrade-btn:hover {
  color: #fff;
}
.middle-content .left-items .btn:focus {
  outline: none !important;
  box-shadow: none;
}
.middle-content .right-items .btn:focus {
  outline: none !important;
  box-shadow: none;
}
.linked-events-page .ag-theme-dark .ag-paging-panel {
  border-top: none;
  color: #cccccc;
  height: 100%;
  min-height: 30px;
}
.calendar-container-dark .linked-events-page .ag-theme-dark {
  background-color: #202020;
  color: #ccc;
}
.calendar-container-dark .linked-events-page .search-features {
  background: #302e2e;
  margin-top: 6px !important;
}
.my-membership-page .invite-code-box {
  width: 50%;
}
.my-membership-page .form-border.invite-code-box {
  width: 50%;
  border: none;
}
/* .my-membership-page .input-box {
  height: 34px;
  background-color: #f1f0f0;
  padding: 8px 10px;
  border-radius: 5px;
  font-family: 'Raleway' !important;
  font-weight: 600 !important;
  padding: 5px 10px !important;
  color: #7c7c7c !important;
  border-radius: 5px !important;
  font-size: 14px;
  border: 1px solid #ced4da;
} */
.my-membership-page .list-box {
  margin-bottom: 18px !important;
  margin-top: 14px !important;
}
.my-membership-page .form-border {
  border: 1px solid #ccc;
  padding: 15px;
  margin-top: 7px;
}
.my-membership-page .row {
  margin-top: 0px;
}
.my-membership-page .form-border {
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 10px;
}
.my-membership-page .new_btn_save_bt.renew-btn {
  margin-top: 22px;
  font-weight: bold !important;
}
.advertise-page #create-course-form .box {
  padding-left: 15px;
}
.advertise-page .box.boxbutton.mt-3 {
  padding-left: 15px !important;
}
.event_body ul .bullet-remove {
  list-style-type: none;
}
.calendar-container-dark .picomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  color: #6c757d;
  font-weight: 500;
  top: 84%;
  left: 0px;
  right: 0;
  width: 92%;
  overflow-y: scroll;
  max-height: 185px;
  scrollbar-width: thin !important;
}
.advertise-page .picomplete-items {
  position: absolute;
  border-bottom: none;
  /* border-top: none; */
  box-shadow: 0 4px 6px -1px #0003, 0 2px 4px -1px #0000001f;
  z-index: 99;
  top: 84%;
  left: 0px;
  right: 0;
  width: 92%;
  overflow-y: scroll;
  max-height: 185px;
  font-weight: 500;
  color: #6c757d;
  scrollbar-width: thin !important;
}
.advertise-page .picomplete-items.border-none {
  border: 0 !important;
}
.advertise-page .picomplete-items.with-border {
  border: 1px solid #d4d4d4;
}
.my-membership-page button {
  font-weight: 700 !important;
}
.my-membership-page .disable-renew-btn {
  background: #1f1f1f !important;
}
.calendar-container-dark .disable-renew-btn {
  background: #fff !important;
}
.calendar-container-dark .messages-listing p {
  color: #6c757d;
  /* padding: 15px; */
}
.messenge-page .messages-listing p {
  padding: 10px;
}
.messenge-page .scrollable.content {
  overflow: hidden;
}
.search-features {
  position: relative;
  z-index: 99;
  width: fit-content;
}
/* .about-us-page .nav-item a:hover {
  color: rgb(0, 0, 0) !important;
} */
.forgot-password .setup_p_hed {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.forgot-password .code_main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.forgot-password .code_main .btn-filled {
  background-color: #1f1f1f;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  width: 170px;
  padding: 10px;
  float: right;
  margin-right: 0px;
  margin-top: 10px;
}
.forget-page .form-group {
  display: inherit !important;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}
.forget-page .show-pwd-dark {
  color: black;
  margin-right: 10px;
  display: inline;
  position: relative;
  top: -27px;
  font-size: 20px;
}
.forget-page label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}
.forget-page .forget-btn {
  display: flex;
  justify-content: center;
}

.profile-detail-page .event_body ul .remove-bullet {
  list-style-type: none;
}
.user-listing-page .btn-danger {
  color: #fff;
  background-color: #a2a2a2 !important;
  border-color: #47525d !important;
}
.user-listing-page .btn-danger:focus {
  outline-color: none !important;
  box-shadow: none !important;
}
.calendar-container .about-us-page .nav-item a:hover {
  color: rgb(255, 255, 255) !important;
}
.calendar-container-dark .membership-plan-page .item1 .price_1 {
  font-size: 20px;
}
.membership-plan-page .item1 .price_1 {
  font-size: 18px;
  font-family: 'Calibri';
}
.membership-plan-page .item1 .freeclass {
  font-size: 18px !important;
  font-family: 'Calibri';
}
.membership-plan-page .item1 .launch {
  font-family: 'Calibri';
  font-size: 8px;
}
.membership-plan-page .item2 .usd {
  font-size: 15px;
  font-family: 'Raleway' !important;
}
.membership-plan-page .details1 {
  padding: 5px 3px !important;
}
.event_filter_page .test-header {
  /* margin-bottom: 40px; */
  margin-bottom: 0;
  margin-top: -5px;
}
.user-listing-page .ag-theme-dark .ag-paging-panel {
  border-top: 1px solid darkgrey;
  color: #cccccc;
  height: 32px;
  padding: 0 12px;
}
.membership-plan-page span,
.price_1 {
  font-family: 'Raleway' !important;
}
.container.event-filter-page .btn-gray {
  background-color: #a2a2a2;
}
.modal-content .remove-btn button {
  background-color: #a2a2a2 !important;
  border-color: #a2a2a2 !important;
}
.calendar-container-dark .ag-theme-dark .ag-paging-panel {
  border-top: 1px solid darkgrey;
  color: #cccccc;
  height: 32px;
  padding: 0 12px;
}
.profile-detail-page .container {
  padding: 10px;
  padding-bottom: 25px;
}
.membership-plan-page #table1 .headele {
  padding-bottom: 10px !important;
  padding-top: 0px !important;
  margin-bottom: 5px;
}
/*********** not by me **************/
.delete-confirmation-popup p {
  font-size: 22px !important;
  font-weight: 700 !important;
  margin-top: 20px;
}
.delete-confirmation-popup .delete-btn {
  display: flex;
  justify-content: center;
  padding: 40px 0px 0px;
}
.delete-confirmation-popup .delete-btn button:last-child {
  margin-right: 0px !important;
}
/***** membership plan page border *********/
.membership-plan-page #table1 #border2 {
  /* border: 1px solid #dee2e6  !important; */
  border-left: none !important;
}
.membership-plan-page #table1 .aligncenter {
  border-left: 1px solid #dee2e6 !important;
}
.membership-plan-page #table1 .head {
  border-top: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}
.membership-plan-page #table1 .details1 {
  border-bottom: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
}
.membership-plan-page #table1 .alignMiddle {
  border-bottom: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
}
.membership-plan-page #table1 .aligncenter {
  border-left: none !important;
}
.membership-plan-page #table1 .color1 {
  border-bottom: 3px solid rgb(255, 88, 88) !important;
}
.membership-plan-page #table1 .color2 {
  border-bottom: 3px solid rgb(255, 143, 96) !important;
}
.membership-plan-page #table1 .color3 {
  border-bottom: 3px solid rgb(251, 219, 105) !important;
}

.membership-plan-page #table1 .color4 {
  border-bottom: 3px solid rgb(101, 255, 152) !important;
}
.membership-plan-page #table1 .color5 {
  border-bottom: 3px solid rgb(108, 168, 255) !important;
}
.membership-plan-page #table1 .color6 {
  border-bottom: 3px solid rgb(160, 98, 255) !important;
}
.membership-plan-page #table1 .color7 {
  border-bottom: 3px solid rgb(255, 111, 222) !important;
}
.calendar-container-dark
  .event-filter-trending
  .ag-theme-balham
  .ag-paging-panel {
  border-top: 1px solid #bdc3c7;
  color: #ccc;
  height: 32px;
  padding: 0 12px;
  background: #302e2e;
}
.calendar-container-dark .event-filter-trending .test-header {
  color: #ccc;
  padding-bottom: 15px !important;
}
.event-filter-trending .test-header {
  padding-bottom: 15px !important;
}
.calendar-container-dark .event-filter-trending .ag-row .ag-cell {
  background: #302e2e;
  font-size: 14px;
  color: #ccc;
  font-family: 'Helvetica Neue', sans-serif;
}
.delete-confirmation-popup .modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  border: 2px solid black;
}
.delete-confirmation-popup p {
  font-size: 20px !important;
  font-weight: 700 !important;
  margin-top: 0px;
  line-height: 25px !important;
}
.delete-confirmation-popup .delete-btn {
  padding: 0;
}
.delete-confirmation-popup .delete-btn button:last-child,
.del-popup-btns button:first-child {
  background-color: red;
  border-color: red !important;
}
.delete-confirmation-popup .delete-btn button:last-child {
  margin-right: 20px !important;
}
.ag-menu {
  z-index: 999999 !important;
}
.ag-root-wrapper {
  overflow: inherit !important;
}
.membership-plan-page #table1 .secondtr th.stickhead,
.membership-plan-page #table1 .thirdtr th.stickhead {
  border-bottom: 1px solid #f5f5f5;
  border-bottom: none !important;
}
.membership-plan-page #table1 tr.fourthtr th.stickhead {
  border-bottom: 1px solid #dee2e6 !important;
}
.membership-plan-page #table1 #border1 h6 {
  text-align: center !important;
  line-height: 1;
  margin-right: 10px;
}
.membership-plan-page #table1 th.headDescription {
  padding: 0px !important;
  vertical-align: middle;
}
.membership-plan-page #table1 .text_data4 {
  font-size: 9px !important;
  line-height: 1.5 !important;
  margin-left: 0px;
  font-style: italic;
}
.modal-content .report-delete button:focus {
  outline: none;
  box-shadow: none;
}
/* login placeholder color  */
.login-screen-page .form-signin input[type='text'],
.login-screen-page input[type='email'],
input[type='url'] {
  height: 38px !important;
  font-size: 16px !important;
  font-family: 'Raleway' !important;
  font-weight: 600 !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
}
.login-screen-page .form-signin input[type='password'] {
  height: 38px !important;
  font-size: 16px !important;
  font-family: 'Raleway' !important;
  font-weight: 600 !important;
  padding: 5px 10px !important;
  color: #ffffff !important;
  border-radius: 5px !important;
  background: transparent !important;
}
.login-screen-page .form-signin input:autofill,
.login-screen-page .form-signin input:-webkit-autofill-strong-password,
.login-screen-page
  .form-signin
  input:-webkit-autofill-strong-password-viewable {
  background-color: transparent !important;
  background-image: none;
  color: #fff !important;
}
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill::first-line {
  -webkit-text-fill-color: #7c7c7c !important;
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  caret-color: #7c7c7c;
  -webkit-text-fill-color: #7c7c7c !important;
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    caret-color: #7c7c7c;
    font-weight: 600 !important;
    height: 38px !important;
    font-size: 1em !important;
    transition: all 600000s 0s;
} */
input:-internal-autofill-selected {
  font-size: 2em !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-size: 16px !important;
  -webkit-text-fill-color: #7c7c7c !important;
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  transition: none;
}
input:-webkit-autofill::first-line {
  font-size: 1em !important;
}
/* input:auto-fill {
  font-size: 16px !important;
  -webkit-text-fill-color: #7c7c7c !important;
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    transition: none;
} */
.my-membership-page .form-border .form-control:-webkit-autofill,
.my-membership-page .form-border .form-control:-webkit-autofill:hover,
.my-membership-page .form-border .form-control:-webkit-autofill:focus,
.my-membership-page .form-border .form-control:-webkit-autofill:active {
  background: #f1f0f0 !important;
  background-color: #f1f0f0 !important;
  border: 1px solid #ced4da;
  -webkit-box-shadow: 0 0 0 30px #f1f0f0 inset !important;
}
/* .login-screen-page .form-signin input:-internal-autofill-selected {
  background-image: none !important;
  background-color: #1f1f1f !important;
  color: #ffffff !important;
} */
.membership-page .author_main_s legend.scheduler-border {
  text-transform: uppercase !important;
  font-size: 24px;
}
.signup-page-one legend.scheduler-border {
  text-transform: uppercase !important;
  font-size: 24px;
}
.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  text-align: center !important;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none !important;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.form-check {
  font-size: 15px !important;
}
.membership-page .author_main_s p {
  font-size: 15px !important;
  font-weight: 400;
  font-family: 'Raleway' !important;
  line-height: 1 !important;
  margin-bottom: 10px;
}
.membership-page .author_main_s #heading p {
  line-height: 1.15 !important;
}
.membership-page .thanktwo_main {
  padding: 0px;
  margin-top: 30px;
  width: 100%;
}
.membership-page .thanktwo_main .form-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.membership-page .thanktwo_main .form-group button {
  float: none !important;
}
.membership-page .thanktwo_main .form-group input {
  float: none !important;
}
.membership-page .price-text {
  font-size: 40px !important;
}
.membership-page .author_main_s .font-weight-bold li {
  font-size: 15px !important;
  font-weight: bold !important;
  font-family: 'Raleway' !important;
  line-height: 1.5 !important;
  padding-left: 5px;
}
.membership-page .author_main_s .price_1 {
  font-size: 25px;
  font-weight: bold;
  line-height: 0%;
}
.modal-dialog .eventModal h5.modal-title {
  width: fit-content;
  padding: 10px;
  margin: 0px auto;
}
.modal-dialog .modal-title {
  border-bottom: 2px solid #000000;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.thankyou_cover .new_btn_save_bt:hover {
  color: #ffffff;
}
/*** membership plan page css ****/
.membership-plan-page .item1 .price_1 {
  font-size: 20px;
  /* font-size: 24px; */
  font-family: 'Calibri';
}
.membership-plan-page .item1 .freeclass {
  border-bottom: 1px solid #dee2e6 !important;
}

.membership-plan-page #table1 #border1 h6 {
  text-align: right !important;
  line-height: 1;
  margin-right: 10px;
  margin-bottom: 0px;
}
.membership-plan-page #table1 th.headDescription {
  padding: 0px !important;
}
input.btn.input-btn {
  outline: none !important;
  box-shadow: none !important;
}
.membership-plan-page tbody h6,
.membership-plan-page thead h6 {
  font-size: 14px !important;
  font-weight: 700 !important;
  text-align: right !important;
}
.membership-plan-page #table1 {
  table-layout: inherit !important;
  width: 100%;
}
.membership-plan-page th {
  width: 12% !important;
}
.membership-plan-page th#border1 {
  width: 16% !important;
}
.membership-plan-page #table1 tr.fourthtr th.color1 {
  border-bottom: 3px solid rgb(255, 88, 88) !important;
}
.membership-plan-page #table1 tr.fourthtr th.color2 {
  border-bottom: 3px solid rgb(255, 143, 96) !important;
}
.membership-plan-page #table1 tr.fourthtr th.color3 {
  border-bottom: 3px solid rgb(251, 219, 105) !important;
}
.membership-plan-page #table1 tr.fourthtr th.color4 {
  border-bottom: 3px solid rgb(101, 255, 152) !important;
}
.membership-plan-page #table1 tr.fourthtr th.color5 {
  border-bottom: 3px solid rgb(108, 168, 255) !important;
}
.membership-plan-page #table1 tr.fourthtr th.color6 {
  border-bottom: 3px solid rgb(160, 98, 255) !important;
}
.membership-plan-page #table1 tr.fourthtr th.color7 {
  border-bottom: 3px solid rgb(255, 111, 222) !important;
}
.membership-plan-page #table1 .sub_head {
  font-size: 13px !important;
  margin: 0px 8px !important;
  font-weight: 700 !important;
  
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.membership-plan-page #table1 th .button {
  padding: 7px 20px;
}
.membership-plan-page #table1 th .button:hover,
.membership-plan-page #table1 th .button:focus {
  cursor: pointer;
}
.membership-plan-page th.head.stickhead.headele p {
  margin-bottom: 5px;
}
.membership-plan-page .item2.free-tag {
  display: flex !important;
  justify-content: space-around;
}
.membership-plan-page .edit-profile-btn {
  padding-bottom: 15px;
}
.membership-plan-page #table1 .text_data2 {
  margin-left: 2px;
}
.membership-plan-page #table1 ul.text_data {
  margin-left: 18px !important;
}
.modal-header {
  border-bottom: none;
}
.membership-plan-page .fa-info-circle {
  margin-left: 4px;
}

.author-description {
  display: block !important;
  padding-top: 10px;
}
@media screen and (max-width: 1500px) {
  .membership-plan-page .item1 .price_1,
  .calendar-container-dark .membership-plan-page .item1 .price_1 {
    font-size: 18px;
  }
}
@media screen and (max-width: 1440px) {
  .membership-plan-page .item1 .price_1,
  .calendar-container-dark .membership-plan-page .item1 .price_1 {
    font-size: 18px;
  }
  .membership-plan-page .sticky-row.thirdtr {
    min-height: 78px !important;
  }
  .membership-plan-page .sticky-row.fourthtr {
    top: 149px !important;
  }
}
@media screen and (max-width: 1400px) {
  .membership-plan-page .item1 .price_1,
  .calendar-container-dark .membership-plan-page .item1 .price_1 {
    font-size: 16px;
  }
}
@media screen and (max-width: 1366px) {
  .membership-plan-page .item1 .price_1 {
    font-size: 15px;
  }
  .calender_list span {
    font-size: 15px;
  }
  .calender_list a span:first-child {
    font-size: 14px !important;
    padding-bottom: 0;
    margin: 0 5px;
  }
  .calender_list a:first-child span:first-child {
    margin-left: 0;
  }
  .filter_options {
    padding: 15px 10px;
  }
  .filter_row img {
    width: 20px;
  }
  .filter-section div:first-child {
    margin: 0px !important;
  }
  .switch {
    width: 41px;
    height: 22px;
  }
  .slider:before {
    height: 15px;
    width: 15px;
    left: 3px;
    bottom: 3.5px;
  }
  .membership-plan-page .item1 .freeclass {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1220px) {
  .membership-plan-page .item1 .price_1 {
    font-size: 12px;
  }
}
/********** Start Date: 22-02-22 ********/
.signup-page-one .form-group .btn-filled {
  background-color: #1f1f1f;
  color: #fff;
  font-weight: 600;
  font-size: 1rem;
  width: 170px;
  padding: 8px 10px;
  float: right;
  margin-right: 15px;
  margin-top: 0;
}
.signup-page-one .form-group button.btn.button_jon_sign {
  margin-top: 0px;
  margin-left: 15px;
  padding: 9px;
  float: right;
  width: 120px;
  font-weight: 600;
  color: #000;
  background-color: transparent;
  border: 2px solid;
}
.membership-page .thanktwo_main .form-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
/* .membership-btn {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 65%;
} */
.calendar-container-dark .event-filter-page .ag-theme-balham .ag-header {
  background-color: #626262;
  color: #cccccc;
  font-weight: 400;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  border-bottom: 1px solid #bdc3c7;
}
.privacy-page p,
.terms-condition p,
.privacy-page ul li,
.terms-condition ul li {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px !important;
}
.privacy-page a,
.term-condition a {
  font-size: 18px !important;
}
.privacy-page span,
.terms-condition span {
  font-family: Raleway !important;
}
.privacy-page p a,
.terms-condition p a {
  font-size: inherit !important;
}
.privacy-page h1,
.privacy-page h2,
.privacy-page h3,
.privacy-page h4,
.privacy-page h5,
.privacy-page h6,
.terms-condition h1,
.terms-condition h2,
.terms-condition h3,
.terms-condition h4,
.terms-condition h5,
.terms-condition h6 {
  text-transform: unset !important;
  font-size: 14px !important;
  font-weight: bold !important;
  line-height: 1.2;
}
.privacy-page ul,
.terms-condition ul {
  padding-left: 30px;
}
.calendar-container-dark .privacy-page p {
  margin-top: 0;
  margin-bottom: 0;
}
.privacy-page .heading,
.terms-condition .heading {
  font-size: 35px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
}
/********** End Date: 22-02-22 ********/
/********** MembershipPlan, Msg, Colors Date 24-02-22 ********/
.membership-plan-page .item1 .freeclass {
  font-size: 20px !important;
  font-family: 'Calibri';
}
.calendar-container .calendar-main-page .rbc-off-range-bg {
  background: #ededed;
}
.calendar-container-dark .calendar-main-page .rbc-off-range-bg {
  background: #ededed;
}
.calendar-container-dark .container.event-filter-page .btn-gray {
  background: #a2a2a2;
  color: #fff;
  opacity: inherit;
}
.calendar-container .user-listing-page .btn-danger {
  background-color: #a2a2a2 !important;
  border-color: #a2a2a2 !important;
}
.edit-event .in_form.row .form-control {
  margin-bottom: 15px;
}
.messenge-page .active-list {
  background-color: #cbcbcb !important;
  margin: 5px 0px;
  align-items: center;
}
.messenge-page textarea:focus {
  outline: 2px solid #000;
  border-radius: 5px;
  margin-left: 2px;
}
.messenge-page .message-list .toolbar {
  display: flex;
  align-items: center;
  font-weight: 500;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  height: 66px;
}
.membership-page .thanktwo_main {
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.payment-page .thanktwo_main .btn-secondary {
  color: #000;
  background-color: transparent;
  border: 2px solid #000;
}
.payment-page .thanktwo_main .bg-btn {
  color: #fff;
  background-color: #1f1f1f;
  border: 2px solid #000;
}
.payment-page .thanktwo_main .btn-secondary:hover {
  color: #fff;
  background-color: #1f1f1f;
  border: 2px solid #000;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.modal-content .modal-body p {
  margin-top: 0;
  margin-bottom: 0;
}
.my-membership-page .disable-renew-btn {
  background: #a2a2a2 !important;
}
/********** MembershipPlan, Msg, Colors Date: 24-02-22 ********/
/********* Date: 25-02-22 EditEvent, Placeholder color **********/
.calendar-dark .edit-event .picomplete-items div {
  padding: 3px 10px 3px 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
  color: #7c7c7c;
}
.calendar-dark .edit-event input[type='text'],
input[type='email'],
input[type='url'] {
  height: 38px !important;
  font-size: 16px !important;
  font-family: 'Raleway' !important;
  font-weight: 600 !important;
  padding: 5px 10px !important;
  color: #7c7c7c !important;
  border-radius: 5px !important;
}
.set-up-profile nput[type='text'],
input[type='email'],
input[type='url'] {
  height: 38px !important;
  font-size: 16px !important;
  font-family: 'Raleway' !important;
  font-weight: 600 !important;
  padding: 5px 10px !important;
  color: #7c7c7c !important;
  border-radius: 5px !important;
}
/********* Date: 25-02-22 EditEvent, Placeholder color **********/
.create-event-popup .book-release-btn button {
  max-width: 50% !important;
  box-shadow: 0px 1px 4px -2px #333;
  text-shadow: 0px -1px #333;
  background: linear-gradient(to right, rgb(101, 193, 255), rgb(113, 147, 255));
  margin-bottom: 7px;
}
.create-event-popup .book-release-btn {
  display: flex;
  justify-content: center;
}
.profile-detail-page .event_body ul .remove-bullet {
  list-style-type: none;
}
.profile-detail-page .event_body ul .bullet-remove {
  list-style-type: none;
  line-height: 1.3;
}
/* for profile page  */
.profile-detail-page ul li p.line-space {
  line-height: 1 !important;
  padding-bottom: 4px !important;
  font-size: 16px !important;
}
.remove-icon {
  list-style-type: none;
  padding-left: 0px !important;
}
/* for my profile page */
.my-profile-page ul li p.line-space {
  line-height: 1 !important;
  padding-bottom: 4px !important;
}
.my-profile-page ul .bullet-remove {
  list-style-type: none;
  line-height: 1.3;
  padding-bottom: 1px;
}
/***** for membership purchase button popup ******/
/***** for membership purchase button popup ******/
.membership-page-popup .purchase-popup {
  margin-top: 0 !important;
  margin-left: 30px !important;
}
.popup .modal-content {
  min-height: 0;
}

/***** for membership purchase button popup ******/
.thankyou_cover .text-center {
  text-align: center !important;
  margin-bottom: 15px;
}
/***** for membership purchase button popup ******/
.membership-page .membership-btn {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 60%;
}
.membership-page .membership-btn .otr-text {
  max-width: 200px;
  font-size: 10px;
  border: 1px solid #bfbcbc;
  border-radius: 5px;
  padding: 4px 10px;
  text-align: justify;
  font-weight: 600;
  font-family: 'Raleway';
  line-height: 12px;
  height: 46px;
}
.membership-page fieldset.scheduler-border.border-dark {
  height: unset;
}
.payment-option img,
.payment-option-other img {
  height: 44px;
}
.payment-option-other img {
  width: 100%;
  border: 1px solid #bfbcbc;
  border-radius: 5px;
}
.notification-span {
  background: red;
  color: white;
  font-family: 'Raleway', sans-serif;
  line-height: 15px;
  padding: 2px 5px;
  border-radius: 50%;
}
.upgrade-plan-page .modal-body_s {
  border: 2px solid #000;
  padding: 15px 30px 10px 30px;
}
a.btn.new_btn_save_bt.float-md-right.upgrade-btn {
  font-size: 16px;
}
.custom_textarea {
  height: 100px !important;
  padding: 8px 12px;
}
.set-up-profile .text-right {
  text-align: right !important;
  margin-top: 15px;
  color: white;
  font-family: 'Raleway', sans-serif;
  line-height: 15px;
  padding: 2px 5px;
  border-radius: 50%;
}

.event-filter-popup .keyword-box legend {
  display: block;
  width: unset;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
.event-filter-popup label.switch {
  margin: 0px 0 0 5px;
}
.blast-noti-page .ag-theme-balham h2 {
  font-size: 35px !important;
  font-weight: 600 !important;
  font-family: 'Raleway' !important;
  text-transform: uppercase !important;
  margin-bottom: 1.5rem;
}
.modal-content .modal-body li {
  list-style-type: square;
  font-size: 14px;
}
p.copyRight.text-white {
  margin-bottom: 0px;
  position: absolute;
  left: 20px;
}
.btn-login-1 p.copyRight.text-white {
  position: absolute !important;
}
@media screen and (max-width: 767px) {
  .writing-promoting-section .ul_plans li {
    width: 100%;
    text-align: center;
  }
}
.main-content.profile-setup input,
.main-content.profile-setup select {
  border: 1px solid #acacac !important;
  padding: 4px 10px;
  border-radius: 5px;
  margin: 0;
}
input[type='file'] {
  float: left;
  width: 50%;
  margin-top: 0 !important;
}
.profile-setup .set-up-profile label {
  display: unset;
  margin-bottom: 0.5rem;
}
.radio-label {
  margin-left: 4px;
  font-weight: 400;
  margin-top: 30px;
}
.profile-setup .set-up-profile .custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1rem;
}
.profile-setup .full_title {
  float: left;
  width: 100%;
  padding-left: 16px;
}
.profile-setup .edit-profile-img {
  margin-top: -10px;
}
.event-popup-body .remove-icon {
  list-style-type: none;
  padding-left: 0px !important;
  margin-bottom: 0;
}
.membership-plan-page .item1 .freeclass {
  border-bottom: none !important;
}
.membership-plan-page .main_head {
  margin: 0 !important;
}
.membership-plan-page #table1 .headtitle {
  vertical-align: middle;
  padding: 5px;
  font-size: 22px;
}
.profile-setup .form_group {
  margin-top: 0 !important;
}
/* .form_gp .ft_text {
  margin-top: 0;
  text-align: right;
  float: right;
  width: 100%;
} */
.edit-event .in_form {
  border: 2px solid #acacac;
  padding: 10px 20px;
  border-radius: 6px;
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 20px;
  padding-left: 0;
}
.edit_event .form-control {
  padding: 0.375rem 0.75rem !important;
}
.edit_event .form-control:disabled,
.form-control[readonly],
.form-control:disabled {
  background-color: #ededed !important;
}
.edit_event label {
  display: unset;
  margin-bottom: 0.5rem;
  font-weight: 600;
}
.edit_event .sync {
  margin-top: 20px;
  height: 38px !important;
  background: #ededed;
  border-color: #ededed !important;
  box-shadow: 0px 0px 1px #ededed;
  box-shadow: none !important;
}
.edit_event .in_form {
  padding: 5px 20px 10px 0;
}
.edit_event .custom-checkbox p {
  float: left;
  margin-left: 10px;
  margin-bottom: 0;
  font-size: 16px !important;
}
.edit_event .checkbox_list {
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 0;
}
.edit_event .custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1rem;
}
/* .edit_event .sync {
  margin-top: 0;
} */
.edit_event .sync__search {
  margin-top: 0;
}
.edit_event label i {
  font-weight: 400;
}
.edit_event .rdio label {
  font-weight: 400;
}
.edit_event .radiobuttons span {
  font-weight: 600;
}
.edit_event .ft_text {
  margin-top: -18px;
}
.edit_event .in_form {
  padding: 13px 20px 10px 0;
}
.edit_event input.form-control.sync {
  height: 34px;
}
.edit-event.edit_event input.form-control.sync_btn {
  margin-top: 24px;
  box-shadow: none;
}
/* .edit-event.edit_event label{
  display: flex;
} */
.edit-event.edit_event p {
  margin-bottom: 0px;
}
.model_cover .event-cover-popup .event-poup-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.model_cover .btn-custom {
  color: #939799;
  margin-top: 0;
}
.edit-event.edit_event p {
  margin-bottom: 0px;
  font-size: 14px !important;
}
.edit_event .search_sync_btn {
  margin-top: 2px;
}
.edit_event .ft_text span {
  float: none;
  cursor: pointer;
}
.edit_event .ft_text {
  margin-bottom: 5px;
  margin-top: 0;
}
/* .edit_event .form_gp input, .edit_event .form_gp .form-control {
  margin-bottom:  0 !important;
} */
/* .model_cover h3.text-center {
  width: 20;
  border-bottom: 2px solid;
  padding: 6px 0;
  width: 100%;
  border-radius: 2px;
}
.model_cover .modal-title {
  border-bottom: none;
} */
.schedule_form {
  display: flex;
  flex-direction: row;
}
.fieldset_border {
  margin: 15px;
  padding: 15px;
  position: relative;
  border-radius: 0;
}
.fieldset_border span {
  position: absolute;
  top: -13px;
  left: 25px;
  background: #fff;
  padding: 0 5px;
  color: #000;
  font-size: 14px;
  font-weight: 700;
}
.blast-popup button.send__btn {
  margin-right: 30px !important;
}
.blast-popup label {
  margin-bottom: 0px;
  font-size: 14px;
}
.event-cover-popup .h5_tag .modal-title {
  border: none;
}
.event-cover-popup .h5_tag h3.text-center {
  border-bottom: 2px solid black !important;
  /* width: 200px; */
  padding-bottom: 15px;
  margin-bottom: 26px;
  text-transform: uppercase;
  padding-top: 10px;
}
.event-cover-popup .event__body {
  margin-top: -15px !important;
}
.ag-header-cell-label .ag-header-cell-text {
  text-overflow: unset;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 800;
}
.ag-header-icon.ag-header-cell-menu-button {
  opacity: 1 !important;
}

.blast-popup .fieldset_border span {
  color: #878787 !important;
}
/* .ag-header-cell.ag-header-cell-sortable, .ag-cell {
  width: auto !important;
} */
.delete-popup-section .modal-content .modal-body {
  border-radius: 5px;
}
.delete-popup-section .modal-content .modal-body p {
  margin-bottom: 20px;
  text-align: center;
  font-weight: 600 !important;
}
.delete-popup-section .modal-content .modal-body h6:first-child {
  max-width: 326px;
}
.delete-popup-section .modal-content .modal-body h6 {
  font-size: 20px !important;
  text-align: center;
  margin: 0 auto 20px;
}
.delete-popup-section .modal-content .modal-body h6 span.yes {
  color: #58c61f;
  margin: 0 5px;
}
.delete-popup-section .modal-content .modal-body h6 span.no {
  color: red;
  margin: 0 5px;
}
.del-popup-btns {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}
.del-popup-btns button {
  margin-top: 0;
  float: none;
  border: 2px solid #1f1f1f;
}
/* .del-popup-btns button.btn-no {
  background: #fff;
    color: #1f1f1f;
} */
.del-popup-new p {
  font-size: 18px !important;
}
.del-popup-new p:first-child {
  margin-bottom: 20px;
}
.disable-radio input[type='radio']:disabled {
  opacity: 0.4;
}
.agree-checkbox input[type='checkbox'] {
  margin-bottom: 0;
}
.auto-renewal p:last-child {
  margin-bottom: 10px;
}
.signup-member-page .member-purchase-section,
.member-payment-page .member-purchase-section {
  /* margin-top: -27px !important; */
}
.member-payment-page.membership-page
  .member-purchase-section
  fieldset.scheduler-border
  .purchase-sectionnnnn,
.member-payment-page.membership-page
  .member-purchase-section
  fieldset.scheduler-border
  .purchase-section {
  height: 135px;
  position: relative;
}
.signup-member-page.membership-page
  .member-purchase-section
  fieldset.scheduler-border
  .purchase-sectionnnnn,
.signup-member-page.membership-page
  .member-purchase-section
  fieldset.scheduler-border
  .purchase-section {
  position: relative;
  height: 146px;
}
.member-payment-page.membership-page .member-purchase-section h6,
.signup-member-page.membership-page .member-purchase-section h6 {
  text-transform: uppercase;
  margin-bottom: 15px;
  width: 100%;
  bottom: 6px;
  position: absolute;
}
.signup-member-page.membership-page .member-purchase-section h6 p,
.member-payment-page.membership-page .member-purchase-section h6 p {
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: 'Raleway' !important;
  margin: 0 !important;
  line-height: 1.2 !important;
}
.signup-member-page.membership-page .member-purchase-section h6 p sup,
.member-payment-page.membership-page .member-purchase-section h6 p sup {
  top: 5px;
}
.member-payment-page.membership-page .purchase-section,
.member-payment-page.membership-page .purchase-sectionnnnn {
  margin-top: 15px;
}
.member-payment-page.membership-page .purchase-section h5,
.member-payment-page.membership-page .purchase-sectionnnnn h5,
.signup-member-page.membership-page .purchase-section h5,
.signup-member-page.membership-page .purchase-sectionnnnn h5 {
  color: #ea0000;
  font-size: 16px !important;
  font-weight: 500 !important;
  margin: 10px 0 0;
}
/* .member-purchase-section h6 {
  margin-bottom: 25px;
  margin-top: 58px;
} */
.member-purchase-section h6 {
  margin-bottom: 20px;
  margin-top: 20px;
}
.membership-page .purchase-section h6 {
  margin-top: 35px;
}
.membership-page .purchase-sectionnnnn p {
  margin-top: 10px;
  margin-bottom: 10px;
}
.membership-page .purchase-sectionnnnn span {
  /* margin-top: 5px; */
}
.membership-page .member-purchase-section p,
.membership-page .member-purchase-section h5,
.signup-member-page .member-purchase-section p,
.signup-member-page .member-purchase-section li {
  font-size: 15px !important;
}
.member-payment-page.membership-page .member-purchase-section p,
.signup-member-page .member-purchase-section p {
  font-size: 16px !important;
  margin-top: 14px;
}
.purchase-sectionnnnn {
  min-height: 135px;
}
.membership-page .purchase-sectionnnnn h5 {
  color: rgb(255, 88, 88);
  font-size: 14px !important;
}
.purchase-sectionnnnn h5,
.purchase-sectionnnnn p,
.purchase-sectionnnnn h6 {
  display: flex;
  justify-content: space-between;
}
.purchase-sectionnnnn h5 span,
.purchase-sectionnnnn p span:first-child,
.purchase-sectionnnnn h6 span:first-child {
  /* text-align: left; */
  text-align: right; /* min-width: 70px; */
  min-width: auto;
}
.purchase-sectionnnnn h6 span span,
.purchase-sectionnnnn h6 p span {
  border-bottom: 2px solid #000;
}
.membership-page .member-consent-section .form-check {
  font-size: 16px !important;
  padding: 0 0.6rem 0 0;
  line-height: 1.3;
}
.membership-page .member-consent-section .form-check .mb-2 {
  margin-bottom: 0 !important;
}
.membership-page .member-consent-section .form-check .mb-2:last-child {
  margin-bottom: 0.9rem !important;
}
.membership-page .member-consent-section .form-check label {
  margin-left: 0.8rem !important;
}

/* .member-purchase-sectioncheckout h6 {
  margin-bottom: 20px !important;
  margin-top: 50px !important;
} */
.membership-page .auto-renewal-section p,
.membership-page .promo-code-section p {
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: 'Raleway' !important;
  line-height: 1 !important;
  margin-bottom: 10px;
}
.my-event-setup-popup .modal-content {
  position: relative;
}
.my-event-setup-popup .modal-body {
  border: 0 !important;
  padding: 0;
}
.my-event-setup-popup .modal-header {
  padding: 0;
}
.my-event-setup-popup .modal-header button.close {
  opacity: 1 !important;
}
.my-event-setup-popup .modal-header button.close span {
  color: #000;
  text-shadow: none !important;
}
.my-event-setup-popup .modal-body h6 {
  margin-bottom: 20px;
  font-size: 20px !important;
  font-weight: 700;
}
.my-event-setup-popup .modal-body p {
  margin-bottom: 20px;
  font-weight: 500 !important;
  text-align: center;
  font-size: 19px !important;
}
.my-event-setup-popup .modal-body ul {
  text-align: left;
  font-weight: 500;
  padding-left: 36px;
}
.my-event-setup-popup .modal-body ul li {
  margin-bottom: 20px;
}
.member-consent-section .form-check {
  padding-left: 0;
}
.membership-page span b {
  font-family: 'Raleway';
  font-weight: 700;
}
.row.signupmain.payment-page.membership-page.signup-page
  .member-purchase-section {
  margin-top: 8px !important;
}
.show-advertise-popup {
  display: flex;
  justify-content: center;
  margin-bottom: 20;
}
.show-advertise-popup img {
  width: 100%;
  height: 400px;
  object-fit: contain;
}
.show-app-popup img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}
.membership-page .membership-btn .bg-btn {
  margin-left: 0;
}
.membership-page .form-group .btn-filled {
  width: 150px;
  border-radius: 10px;
  color: #fff;
  border: 2px solid #000;
  margin-top: 0;
}
.import-otp .code_main.input-code input {
  height: 46px !important;
  font-size: 14px;
}
.import-otp .code_main.input-code input::placeholder {
  font-size: 14px !important;
}
.import-otp .modal-title {
  padding: 0 !important;
  margin-bottom: 30px !important; /* width: 100% !important; */
}
.import-otp .modal-title h3 {
  font-size: 26px !important;
  font-weight: 700 !important;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
.import-otp .signup_text p {
}
.row-m-10 {
  margin-left: -10px !important;
  margin-right: -10px !important;
}
.row-m-5 {
  margin-left: -5px !important;
  margin-right: -4px !important;
}
.col-p-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.member-consent-section fieldset.scheduler-border,
.promo-code-section fieldset.scheduler-border,
.member-purchase-section fieldset.scheduler-border {
  margin-top: 0.5rem !important;
}
.ul li {
  display: flex;
  align-items: baseline;
}
.ul li i.fa-plus-circle {
  margin-right: 5px;
}
.event-setup-popup .modal-header {
  padding: 0;
}
.event-setup-popup .model_cover {
  border: 0;
}
.event-setup-popup.modal-dialog .setup-popup-title h3 {
  margin-bottom: 0;
  padding-bottom: 10px;
}
.event-setup-popup .event_body.modal-body li,
.event-setup-popup .event_body.modal-body p {
  font-size: 16px !important;
  font-weight: 500 !important;
}
.event-setup-popup.modal-dialog .setup-popup-title {
  margin-bottom: 40px;
  width: fit-content;
  margin: auto auto 30px;
  border-bottom: 2px solid #000;
}
.event-setup-popup ul {
  margin-bottom: 1.5em;
}
.event-setup-popup .event-popup-body div em {
  line-height: 1;
  font-weight: 500;
}
.signup-modal .modal-header {
  padding: 1rem 10px;
}
.signup-modal .modal-header .modal-title {
  padding: 10px 0px !important;
}
.signup-modal .modal-header .close {
  top: 0;
  right: 13px;
}
.signup-modal .modal-header h3 {
  text-transform: uppercase;
  font-size: 22px !important;
}
.giveaway-popup .btn-filled {
  width: 150px;
}
.release-popup .btn-filled {
  width: 150px;
}
.borderline {
  border-bottom: 2px solid black;
  margin: 0px 0px 15px 0px;
}
.signup-modal .modal-body h3 {
  margin: 15px 0px;
  border-bottom: 2px solid black;
  padding-bottom: 18px !important;
  font-size: 22px !important;
}
span.slogan-text {
  position: absolute;
  font-size: 14px;
  margin-top: 3px;
  font-weight: 400;
  font-family: 'Raleway' !important;
}
.header_text.activeOutline {
  border-bottom: 2px solid #000;
  padding-bottom: 5px;
}
/* Blast Details POpup */
.blastdetails-modal img {
  width: unset !important;
  max-width: 100%;
}
.blast-popup .ck.ck-button {
  min-width: auto !important;
  width: auto !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 5px !important;
  min-height: auto;
  height: auto !important;
}
.blast-popup .ck-button_with-text {
  width: 150px !important;
  min-width: 150px !important;
}
.daily-task-popup .ck.ck-button {
  min-width: auto !important;
  width: auto !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 5px !important;
  min-height: auto;
  height: auto !important;
}
.daily-task-popup .ck-button_with-text {
  width: 150px !important;
  min-width: 150px !important;
}
.footer-btn button {
  margin-bottom: 0 !important;
}
.footer-margin {
  margin-bottom: 36px;
}
.footer-badges-link ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.footer-badges-link ul.download-links img {
  height: 50px;
}
.row.footer-badges-link {
  margin-bottom: 36px;
}
ul.payments-links {
  align-items: flex-start;
}
ul.download-links {
  align-items: flex-end;
}
ul.payments-links li {
  width: 100%;
}
/* User Event POpup */
.user-event-popup.modal-dialog {
  max-width: 470px;
}
.user-event-popup .modal-body h5 {
  max-width: 90%;
  margin: 0 auto 30px;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 1.3 !important;
}
.user-event-popup .modal-body ul li {
  font-size: 16px;
  max-width: 92%;
  line-height: 1.4;
}
/* Time Picker in EVent Setup */
.time-picker {
  padding: 0 !important;
  float: none !important;
  width: 100% !important;
}
.time-picker span.rc-time-picker {
  width: 100%;
}

.time-picker .rc-time-picker-icon::before {
  font-weight: 400;
  font: normal normal normal 14px/1 FontAwesome;
  content: '\f017';
  position: absolute;
  top: 12px;
  right: 10px;
  pointer-events: none;
}
.time-picker .rc-time-picker-input {
  cursor: pointer;
}
.time-picker .rc-time-picker-clear {
  display: none;
}
.time-picker .rc-time-picker-clear-icon:after {
  content: none;
}
.calender_list a {
  margin: 0;
}
.filter-section .search_box button {
  padding: 5px 5px 5px 10px !important;
}
/* Blast Message Modal */
.blast-msg-grp .__react_component_tooltip {
  margin-right: 38px;
}
a:hover,
a:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.profileSave {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  margin-bottom: 5px;
}
.profileSave a {
  top: 0;
}
/* Week Picker */
.book-release-input .SingleDatePicker {
  height: 38px !important;
  font-size: 16px !important;
  font-family: 'Raleway' !important;
  font-weight: 600 !important;

  color: #7c7c7c !important;

  border-radius: 5px !important;
  border-radius: 2px;
  border: 1px solid #dbdbdb;
  background: #f7f7f7;
}
.book-release-input .SingleDatePicker,
.book-release-input .SingleDatePickerInput {
  width: 100%;
}
.book-release-input input {
  border-radius: 0 !important;
  border: 0 !important;
  overflow: hidden;
  height: 36px !important;
}
.book-release-input .SingleDatePickerInput {
  border-radius: 0;
  border: 0 !important;
  overflow: hidden;
}
.book-release-input .DateInput {
  position: relative;
  width: 100%;
  cursor: pointer;
}
.book-release-input .DateInput:after {
  content: '\f133';
  font-family: FontAwesome;
  font-weight: 900;
  right: 10px;
  position: absolute;
  top: 5px;
  pointer-events: none;
  cursor: pointer;
}
/* Disclaimer In Beta Reader Page */
.beta-reader-disclaimer {
  bottom: 25px;
  position: absolute;
  width: 94%;
}
.event-cover-popup a {
  font-weight: 500;
}
span.disabled_list {
  color: #b5b5b5 !important;
  cursor: not-allowed;
}
/* Set Up Profile Page */
.set-up-profile .form-group {
  position: relative;
}
.set-up-profile .form-group .emojipicker {
  right: 5px;
  bottom: 5px;
  position: absolute;
  cursor: pointer;
}
.set-up-profile .form-group .blog-name {
  padding-right: 30px !important;
}
.set-up-profile .form-group .emoji-mart {
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100% !important;
  /* max-width: 355px; */
  top: 64px;
}
.set-up-profile .form-group .emoji-mart button.emoji-mart-anchor {
  width: fit-content !important;
  min-width: 50px !important;
}
.set-up-profile .form-group .emoji-mart .emoji-mart-search-icon {
  top: 0;
  cursor: pointer;
  outline: 0 !important;
}
.set-up-profile .form-group .emoji-mart .emoji-mart-search input,
.set-up-profile .form-group .emoji-mart .emoji-mart-search input::placeholder {
  color: #3d3d3d;
  font-size: 15px;
  height: 30px;
}
.daily-task-popup .form-group span i {
  font-size: 15px !important;
  margin-left: 5px;
}
.daily-task-popup .__react_component_tooltip {
  max-width: 81%;
  overflow: hidden;
}
.signup-link-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.signup-link-section .compare-plans-btn {
  /* padding: 2px !important;
    background: transparent !important;
    border-bottom: 2px solid #000;
    border-radius: 0 !important; */
  width: fit-content;
  /* height: fit-content !important; */
  min-width: 120px !important;
}
.event-filter-page-popup button.close {
  line-height: 1 !important;
  display: flex;
  align-items: center;
  height: auto !important;
  right: 7px;
  top: -4px;
  font-size: 38px !important;
}

.event-toggle-col {
  padding-left: 8px;
  padding-right: 8px;
}
/* Membership Page */
.login-socialInfo {
  font-style: italic;
  font-size: 16px !important;
  width: calc(100% - 150px);
  text-align: left;
  margin: 0;
  margin-top: 1.1rem;
}

.hosted-by-section {
  width: 100%;
}
/* Date Picker (FIlter Popup) */
.filter-date-section .react-datepicker-wrapper {
  position: relative;
}
.filter-date-section
  .react-datepicker-wrapper
  .react-datepicker__input-container {
  position: relative;
  width: 100%;
}
.filter-date-section input {
  width: 100%;
  font-size: 1rem !important;
  line-height: 1.5;
  color: #495057 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem !important;
  font-weight: 400 !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.filter-date-section .react-datepicker-wrapper:before {
  content: '\f133';
  font-family: FontAwesome;
  font-weight: 900;
  right: 10px;
  position: absolute;
  top: 5px;
  pointer-events: none;
  cursor: pointer;
  z-index: 1;
}
/* Vendor Page Profile Modal */
/* .vendorModal-list li span {

}
.vendorModal-list li span a {
 position: unset !important;
} */
/* .empty-cell {
  margin-bottom: 0;
}

.user-listing-page .ag-center-cols-container .ag-cell[col-id="message"] {
  text-align: center;
} */
/* Convert Social Page */
.convert-social-page .setup_p_hed h4 {
  text-transform: none;
}
.convert-social-page form .form-group.btnSection {
  display: flex;
  width: 100%;
  justify-content: center;
}
/* .convert-social-page .signup_text {
  margin: 10px auto 0;
} */
.convert-social-page .code_main p.socMsg {
  font-style: italic;
  /* width: calc(100% + 30px); */
  margin: -10px 0 10px 0;
  font-size: 14px !important;
  text-align: center;
}
.forgot-password form .code_main .form-group label sup {
  font-size: 20px;
  top: 0;
}
/* Sign Up page one // added disclaimer text */
.signup-page-one .full_title {
  margin-bottom: 1em;
}

/* Membership Page */
.membership-plan-page .sticky-row {
  position: sticky;
  background: #fff;
}
.membership-plan-page .sticky-row.secondtr {
  top: 0;
  /* min-height: 73px; */
  height: 73px;
}

.membership-plan-page .sticky-row.thirdtr {
  top: 73px;
  min-height: 60px;
}
.membership-plan-page .sticky-row.fourthtr {
  top: 149px;
}
/* Advertise Page */
.book-release-linkAd {
  margin-bottom: 0;
}
.book-release-linkAd label {
  position: relative;
  top: 13px;
}
.book-release-linkAd input,
.book-release-linkAd input::-webkit-input-placeholder {
  text-align: left;
}
.book-release-popupTxt,
.book-release-popupTxt span {
  font-style: italic;
  font-size: 12.95px;
  width: 42%;
  white-space: break-spaces;
  line-height: 1.1;
}
.advertise-privacy-popup button.btn {
  margin-left: auto !important;
  float: unset;
  margin-right: auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.daily-task-popup figure img {
  width: 100%;
}
.daily-task-popup .image.image_resized img {
  /* height: 300px; */
  object-fit: cover;
  width: auto;
  width: 100%;
}

.form-group .image_resized {
  display: flex;
  margin: 0px auto;
  justify-content: center;
}
.form-group .image-style-align-left {
  display: flex;

  justify-content: flex-start;
  margin: initial;
  margin-right: auto;
}
.form-group .image-style-side {
  justify-content: flex-end;

  display: flex;
  margin: initial;
  margin-left: auto;
}
.daily-task-page .daily-calendar .rbc-row .rbc-row-segment {
  padding: 0 3px 1px 0px;
  margin-bottom: 1px;
}
.faq-page .row.mb-5 {
  margin-top: 17px;
}
/* .ck-restricted-editing_mode_standard p {
  font-family: 'Calibri, Geneva, sans-serif' !important;
  font-size: 12px !important;
} */
.ck-restricted-editing_mode_standard p {
  font-family: 'Raleway' !important;
  font-size: 14px !important;
}

/* .ck-content .image.image_resized img {
  width: 100%;
} */
/****** My Profile Page *****/
.profile_detail.my-profile a {
  font-size: 16px;
}
#table1 .viewableTxt {
  /* font-style: italic !important; */
  font-size: 12px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  margin-bottom: 2px !important;
  margin-left: 2px;
}
.form-group .form-control:focus,
form#create-course-form input:focus {
  border-color: #ced4da;
  box-shadow: 0 0 0 30px #f1f0f0 inset !important;
}
.modal-body
  .form-group
  .ck-editor
  .ck.ck-editor__main
  .ck.ck-editor__editable.ck-focused {
  outline: none;
  border: 1px solid #ced4da;
  box-shadow: none;
}

.ck.ck-sticky-panel__content {
  position: static !important;
  top: initial !important;
}
.ck.ck-sticky-panel__placeholder {
  display: none !important;
}
.event-filter-page .ag-theme-balham .ag-paging-panel {
  height: 22px;
}
.daily-task-popup h2 {
  text-transform: none !important;
}
.daily-task-popup
  .ck-dropdown__panel
  .ck-list
  .ck-list__item
  .ck-button_with-text {
  background: transparent !important;
  font-family: 'Raleway' !important;
  box-shadow: none !important;
  color: #000 !important;
  text-transform: capitalize !important;
  font-size: 13px !important;
  padding: 5px 10px !important;
  width: 100% !important;
  height: 30px !important;
  font-weight: 500 !important;
  cursor: pointer;
}
.daily-task-popup .ck-dropdown__panel .ck-list .ck-list__item:hover {
  cursor: pointer;
  background: #c4c4c4;
}
/* Ad Carousel */
.advertise-section .advertise ul.react-multi-carousel-track {
  height: 394px;
}

.advertise-section .advertise ul.react-multi-carousel-track li,
.advertise-section .advertise ul.react-multi-carousel-track li a,
.advertise-section .advertise ul.react-multi-carousel-track li a div {
  height: 100%;
}

input:focus,
.form-control:focus {
  outline: 0 !important;
  /* box-shadow: 0 0 0 0.2rem rgb(206 212 218 / 40%) !important; */
  box-shadow: none !important;
  border-color: inherit !important;
}
.membership-page .promo-code input:focus {
  border-color: #212121 !important;
}
.form-signin .checkbox_label:hover {
  border-color: #fff;
}
.filter-section input:focus {
  outline: #101010 auto 1px !important;
}
.form-group .form-control:focus,
.edit-event input.form-control:focus,
.daily-task-popup .react-datepicker-wrapper input:focus {
  border-color: #ced4da !important;
  box-shadow: 0 0 0 30px #ffffff inset !important;
  box-shadow: none !important;
}
.form-signin input:-webkit-autofill,
.form-signin input:-webkit-autofill:hover,
.form-signin input:-webkit-autofill:focus,
.form-signin input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1f1f1f inset !important;
  -webkit-text-fill-color: #7c7c7c !important;
}
/* Calendar Tab */
.calender_list a span,
.calender_list a.list {
  font-size: 14px;
}
.calender_list span.devider {
  margin: 0 10px;
}
.calender_list span.header_text {
  margin: 0;
}
/* Following Event Header */
.following-event-header span.yes_no {
  text-transform: uppercase;
  font-size: 17px !important;
  font-weight: 600;
  padding: 0.1rem 1rem;
  padding-right: 0.5rem;
  padding-left: 0.8rem;
  margin: 0 !important;
}
/* Vendor Listing Page */
.vendor-listing-page .vendor-services {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 0;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #000;
  width: fit-content;
  margin: 0 auto 0.5em;
}
.vendor-listing-page .vendor-services span {
  line-height: 1.1;
  font-size: 14.6px;
  border: 0.5px solid #4f4f4f;
  border-radius: 4px 0 0 4px;
  padding: 2px 5px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fff;
  font-weight: 500;
}
.calendar-dark .vendor-listing-page .vendor-services span {
  background: #302e2e;
}
.vendor-listing-page .vendor-services select,
.vendor-listing-page .vendor-services select:focus,
.vendor-listing-page .vendor-services select:focus-within {
  font-size: 15px;
  outline: none;
  font-family: 'Raleway', sans-serif;
  /* text-transform: capitalize; */
  width: 450px;
  max-width: 100%;
  padding: 2px 5px 2px 2px;
  border: 0.5px solid #4f4f4f;
  border-radius: 4px;
}
.vendor-listing-page p.blankCell {
  text-align: center;
  margin: 0;
  font-size: 16px !important;
}
/* Message Search Option */
.message-search-section .message-search-box {
  max-width: 50%;
}
.message-search-box {
  max-height: 40px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  position: relative;
}
.message-search-box input,
.message-search-box input:focus {
  border: 1px solid #000 !important;
  border-radius: 5px !important;
  width: 100%;
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  height: 35px !important;
  font-family: 'Raleway' !important;
  padding-right: 30px !important;
}
.message-search-box input:focus,
.faq-search input:focus {
  border-color: #000 !important;
}
.message-search-box input::placeholder {
  color: #000 !important;
}
.messenge-page .message-list .compose textarea:focus {
  border-color: #000 !important;
  outline: 0 !important;
  border-radius: 0;
  margin-left: 0;
}
.message-search-box input::placeholder,
.messenge-page .message-list .compose textarea::placeholder,
.compose textarea::-webkit-input-placeholder,
.messenge-page .message-list .compose input[type='text']::placeholder,
.compose input[type='text']::-webkit-input-placeholder {
  color: #000 !important;
  font-family: 'Raleway' !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  opacity: 1;
}
.message-search-box button,
.message-search-box button:hover,
.message-search-box button:focus,
.message-search-box button:focus-within {
  min-width: 35px !important;
  max-width: 40px !important;
  height: 35px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0 !important;
  background: transparent !important;
  cursor: pointer;
  outline: 0 !important;
  box-shadow: none !important;
}
.message-search-box i,
.faq-search i {
  font-size: 24px;
  color: #000;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1px;
  top: 0;
}
.message-search-box i:hover,
.faq-search i:hover {
  /* opacity: 0.8; */
  filter: brightness(0.8);
  cursor: pointer;
}
/* Vendor List Page */
.filter-select {
  width: 450px;
  max-width: 100%;
  height: 35px;
  font-family: Raleway, sans-serif !important;
  border-radius: 0 4px 4px 0;
}
.filter-select .filter-select__control {
  max-height: 35px;
  height: 35px;
  padding: 0 5px;
  border: 0 !important;
  padding-left: 10px;
}
.calendar-dark .filter-select .filter-select__control {
  background-color: transparent !important;
}
.calendar-dark .filter-select {
  background-color: #626262 !important;
}
.calendar-dark .vendor-listing-page .vendor-services {
  border: 1px solid #fff;
}
.calendar-dark
  .filter-select
  .filter-select__value-container
  .filter-select__placeholder {
  color: #fff !important;
}
.calendar-dark
  .filter-select
  .filter-select__indicators
  .filter-select__dropdown-indicator
  svg
  path {
  fill: #fff !important;
}
.calendar-dark .filter-select .filter-select__menu {
  background-color: #302e2e !important;
  border: 0.5px solid #fff !important;
}
.calendar-dark .filter-select .filter-select__menu .filter-select__option {
  background-color: transparent !important;
  color: #fff !important;
}
.calendar-dark .filter-select .filter-select__menu .filter-select__option:hover,
.calendar-dark
  .filter-select
  .filter-select__menu
  .filter-select__option:focus {
  background-color: #626262 !important;
}
.filter-select .filter-select__value-container {
  padding: 0;
}
.filter-select .filter-select__placeholder,
.filter-select .filter-select__single-value {
  color: #000 !important;
  font-family: Raleway, sans-serif !important;
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
}
.filter-select .filter-select__input,
.filter-select .filter-select__input input {
  color: #000 !important;
  font-family: Raleway, sans-serif !important;
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  font-weight: normal;
}
.filter-select .filter-select__indicators .filter-select__dropdown-indicator {
  padding: 0;
}
.filter-select
  .filter-select__indicators
  .filter-select__dropdown-indicator
  svg
  path {
  fill: #000;
}
.filter-select .filter-select__menu {
  background-color: #fff !important;
  border-radius: 5px;
  border: 0.5px solid #ededed !important;
  margin-top: 2px;
  z-index: 999;
}
.filter-select .filter-select__menu .filter-select__menu-list {
  padding: 0;
  max-height: 470px;
}
.filter-select
  .filter-select__menu
  .filter-select__menu-list::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.filter-select
  .filter-select__menu
  .filter-select__menu-list::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #f7f7f7;
}
.filter-select
  .filter-select__menu
  .filter-select__menu-list::-webkit-scrollbar-thumb {
  background-color: #858585;
}
.filter-select .filter-select__menu .filter-select__option {
  background-color: #fff !important;
  color: #000 !important;
  cursor: default;
  display: block;
  font-size: 15px;
  line-height: 1;
  padding: 3px 8px;
  width: 100%;
}
/* .vendor-listing-page .filter-select .filter-select__menu .filter-select__option:first-child {
pointer-events: none;
} */
.filter-select .filter-select__menu .filter-select__option:hover,
.filter-select .filter-select__menu .filter-select__option:focus {
  background-color: #ededed !important;
  cursor: pointer;
}
span.undiscount-price {
  font-weight: 500 !important;
  position: relative;
  margin-right: 4px;
}
span.undiscount-price:after {
  content: '';
  position: absolute;
  top: 10px;
  left: -5px;
  width: 42px;
  height: 1px;
  transform: rotate(200deg);
  background: #000;
}
/**************** Membership Page Dark View **************/
.membership-plan-page .sticky-row {
  /* background: transparent !important; */
}
.calendar-container-dark .membership-plan-page .sticky-row {
background-color: #202020;
}
/**************** Calendar (Dashboard) Page Dark View **************/
.calendar-dark .rbc-calendar .rbc-toolbar .rbc-btn-group button:hover {
  color: #202020;
  cursor: pointer;
}
.calendar
  .calender-view-otr.rbc-calendar
  .rbc-month-view
  .rbc-month-row
  .rbc-row {
  margin-bottom: 1px;
}
/**************** All profiles view popup **************/
.user-profile-modal .event-poup-btn {
  align-items: center;
}
.user-profile-modal .event-poup-btn .editBtn {
  /* margin-top: 0 !important; */
  margin-right: 15px;
}
.user-profile-modal .event_body .event-popup-body {
  max-height: calc(100vh - 385px);
  padding-right: 5px;
}
.user-profile-modal .event_body .event-popup-body.prFirm-profile-inner {
  max-height: calc(100vh - 446px);
}
/**************** Blast Page (fix for scroll) **************/
.blast-details-page .ag-paging-panel {
  height: 25px;
}
/* .blast-details-page .search-features p {
  margin-top: -27px;
  margin-bottom: 0;
} */
.blastContainer {
  grid-template-rows: minmax(50px, auto) minmax(30px, auto) minmax(30px, auto);
}
/**************** Beta Reader Page (fix for scroll) **************/
.beta-reader-page .btn_save_bt {
  margin-top: 0;
}
.beta-reader-page .ag-paging-panel {
  height: 20px;
}
.beta-reader-page .search-features p {
  margin-top: -27px;
  margin-bottom: 0;
}
.beta-reader-page .disclaimer-text {
  margin-top: -13px !important;
}
/**************** Blast Page (fix for scroll) **************/
.my-membership-page .list-box:last-child {
  margin-bottom: 0 !important;
}
::-internal-autofill-previewed {
  font-size: inherit !important;
}
.conditions-modal .modal-body h2 {
  text-transform: none !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.event-poup-btn .btn-danger {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}

.btn.unfollow-btn:disabled {
  background-color: #a2a2a2 !important;
  border-color: #a2a2a2 !important;
}
.reset-cover-btn {
  border-color: #1f1f1f !important;
  background: #1f1f1f;
  color: white;
  max-width: 150px;
  max-height: 38px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.26rem !important;
}
.rbc-overlay .rbc-event {
  display: flex !important;
  height: 20px;
  align-items: center;
}
.rbc-overlay {
  min-width: 200px !important;
}
/* Font Raleway on Conditions Modal */
.conditions-modal .modal-body span,
.conditions-modal .modal-body p span {
  font-family: 'Raleway' !important;
}
/* Black Border in all tables across site */
.ag-theme-balham .ag-root {
  border: 1px solid #000000 !important;
}
.ag-theme-balham .ag-header {
  border-bottom: 1px solid #000000 !important;
}
.calendar-dark .ag-theme-dark .ag-root,
.calendar-dark .ag-root {
  border: 1px solid #ffffff !important;
}
.calendar-dark .ag-theme-dark .ag-header,
.calendar-dark .ag-root .ag-header {
  border-bottom: 1px solid #ffffff !important;
}
.calendar-dark .blast-noti-page .ag-theme-balham {
  background-color: #202020;
}
.calendar-dark .blast-noti-page .ag-theme-balham .heading,
.calendar-dark .blast-noti-page .ag-theme-balham .ag-overlay-no-rows-center {
  color: #fff;
}
.calendar-dark .blast-noti-page .ag-theme-balham .ag-root {
  border: 1px solid #fff !important;
}
.calendar-dark .blast-noti-page .ag-theme-balham .ag-header {
  border-bottom: 1px solid #fff !important;
  background-color: #626262;
  background-image: none;
  color: #ccc;
}
/* Messages Tab (Dark View) */
.calendar-container-dark .message-search-box {
  background: transparent;
}
.calendar-container-dark .message-search-box input,
.calendar-container-dark .message-search-box input:focus {
  border: 1px solid #fff !important;
  color: #000 !important;
}

.calendar-container-dark .messenge-page,
.calendar-container-dark .conversation-list,
.calendar-container-dark .messenge-page .message-list .compose textarea,
.calendar-container-dark .messenge-page .message-list .compose textarea:focus,
.calendar-container-dark .message-list .compose .btn {
  border-color: #fff !important;
}
.calendar-container-dark .messages-listing p {
  color: #000000;
}
/* Message Popup */
.message-popup textarea {
  border-radius: 5px !important;
  border-color: #000 !important;
  outline: none !important;
}
.message-select input {
  padding-left: 0 !important;
  max-height: 26px !important;
  padding: 0 !important;
  color: #000 !important;
}
.message-select input,
.user-message__single-value,
.message-popup textarea,
.user-message__placeholder {
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
  font-weight: 500 !important;
  color: #000 !important;
}
.user-message__control {
  border-color: #000 !important;
  box-shadow: none !important;
  border-radius: 5px !important;
}
.user-message__indicators,
.user-message__value-container,
.user-message__control {
  height: 40px;
}
.user-message__indicator-separator {
  background-color: #000 !important;
}
.user-message__indicator {
  color: #000 !important;
}
.user-message__menu::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
.user-message__menu::-webkit-scrollbar-thumb {
  background-color: #cccccc;
}
.user-message__menu::-webkit-scrollbar-track {
  background-color: #ededed;
}
.user-message__option.user-message__option--is-focused,
.user-message__option.user-message__option--is-selected,
.user-message__menu .user-message__option:active {
  background-color: #ededed;
  cursor: pointer;
  color: #000;
}
.user-message__option.user-message__option--is-selected {
  background-color: #cbcbcb !important;
}
.message-select .user-message__indicator:hover {
  cursor: pointer;
}
.message-select .user-message__indicator:hover svg {
  opacity: 0.8;
  transition: all ease-in 0.2s;
}
.faq-search input.form-control {
  border: 1px solid #000 !important;
  color: #000 !important;
  font-weight: 500 !important;
}
/* Membership Page */
.my-membership-page p {
  font-size: 16px !important;
}
.headDescription .sub_head {
  min-height: 78px;
}
.no-profile-page h2 {
  text-transform: none !important;
  font-size: 30px !important;
  margin-bottom: 0 !important;
}

/* Blast Page for PR Firm & Bloggers */
.pr-blogger .details-box {
  width: 49%;
}

/* Reset Button To Reset Author */
.resetAuth-section {
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
}
.resetAuth-section label {
  margin-bottom: 0.2rem;
}
.edit-event.edit_event .form-group {
  width: 100%;
  position: relative;
}

.edit-event.CoverReveal-eventPage .form-group.authorName,
.edit-event.Giveaway-eventPage .form-group.authorName,
.edit-event.BookPromo-eventPage .form-group.authorName,
.edit-event.ReleaseParty-eventPage .form-group.authorName,
.edit-event.ARCSign-up-eventPage .form-group.authorName {
  margin-bottom: 6px !important;
}

.editProfile-img {
  height: 60px;
  width: 60px;
  padding: 2.5px;
  border-radius: 100%;
  background-size: cover !important;
  background-position: center;
  margin-top: -10px;
  display: inline-block;
}
.editProfile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  margin-top: 0 !important;
}
.edit_event .timeZoneControl {
  display: flex;
  align-items: center;
}
.edit_event .timeZoneControl input {
  margin: 0px !important;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  border: 2.125px solid #000;
  border-radius: 12.5%;
  cursor: pointer;
}
.edit_event .timeZoneControl input:checked::after {
  content: '';
  position: absolute;
  height: 20px;
  width: 9px;
  background-color: red;
  opacity: 1;
  -webkit-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  background-color: #fff !important;
  border-right: 2.25px solid #000 !important;
  border-bottom: 2.25px solid #000 !important;
  top: -5px;
  left: 28px;
}

.edit-event.edit_event input[type='file']::file-selector-button {
  background-color: #ededed;
  border-radius: 0.15rem;
  height: 28px;
  width: 100px;
  cursor: pointer;
  border: 1px solid #3d3d3d;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  transition: background-color 200ms;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
}

/* Image in Received Blast Details Modal */
.received-blast-modal figure {
  overflow: hidden;
  margin: 0.5em 0;
}
.received-blast-modal figure img {
  max-width: 100%;
}

/* My Events Page */
.my-events-page.container {
  padding-bottom: 5px;
}
.my-events-page h4 {
  margin-bottom: 0 !important;
}

.reset-page .header-secion,
.reset-page .footer {
  display: none;
}
.reset-page .forgot-password {
  padding-top: 50px;
  z-index: 0;
  position: relative;
}

.calendar-dark .edit_event .timeZoneControl input {
  border-color: #fff !important;
}
.calendar-dark .edit_event .timeZoneControl input:checked:after {
  border-right-color: #fff !important;
  border-bottom-color: #fff !important;
  background-color: transparent !important;
}

.react-datepicker-wrapper .invalid {
  border-color: #ced4da !important;
}

.picomplete-items.author-names .picomplete-item {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  padding: 8px 10px;
}
.picomplete-item .authorAvatar {
  width: 32px;
  height: 32px;
  padding: 2.5px;
  border: 0;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(./Utils/Assets/OTR-color-circle_authors.png) !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.picomplete-item .authorAvatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.message-select .user-message__menu-list::-webkit-scrollbar,
.picomplete-items::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.message-select .user-message__menu-list::-webkit-scrollbar-thumb,
.picomplete-items::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}
.message-select .user-message__menu-list::-webkit-scrollbar-track,
.picomplete-items::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.user-message__option {
  padding: 8px 6px;
}
.message-list-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}
.message-list-option .msg-avatarImg {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  overflow: hidden;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.message-list-option img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* .conditions-modal .modal-body {
  max-height: calc(100vh - 222px);
}

.conditions-modal .modal-body::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
.conditions-modal .modal-body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}
.conditions-modal .modal-body::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 5px;
} */

.calendar-added-disabled {
  position: relative;
  cursor: pointer;
}
.calendar-added-disabled:hover:before {
  content: 'Already added';
  position: absolute;
  top: -2px;
  background: #c1c2c2;
  text-transform: none;
  z-index: 999;
  font-weight: 600;
  padding: 4px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -2px;
  opacity: 1;
  border-radius: 10px;
}
.calendar-event-expired:hover:before {
  content: 'Event expired';
}
.vco-remove-btn {
  margin-top: -11px !important;
  margin-bottom: 10px !important;
}
.vco-remove-btn span {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}
.vco-remove-btn i {
  font-size: 24px;
}

/* Vendor Openings Setup Profile */
.vendor-openings {
  padding-right: 15px;
  padding-left: 15px;
}
.vendor-openings span {
  color: rgb(31, 31, 31);
  font-weight: 600;
}

#otrTextarea::placeholder {
  color: gray !important;
  font-weight: 500 !important;
}
