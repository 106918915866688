.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 90px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0px;
  top: 38px;
}

.dropdown-content a {
  color: black;
  padding: 3px 8px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.alignment {
  margin-right: 40%;
  margin-bottom: 9%;
  margin-top: 1%;
}
.fontSize {
  line-height: 1.5;
  font-size: 80%;
}
.pageNo {
  float: right;
}

#modal {
  width: 700px;
}
#betaModal {
  width: 800px;
}
#text {
  font-size: 14px;
}
.form-control {
  padding: 3px;
}

@media only screen and (max-width: 650px) {
  #modal {
    width: 100%;
  }
  #betaModal {
    width: 100%;
  }
}
.vendor-listing-page span button {
  padding: 5px 10px !important;
  min-width: auto !important;
  font-size: 11px !important;
  height: auto !important;
}
.disclaimer-text p {
  font-size: 0.7rem !important;
}
.disclaimer-popup-btn {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.user-profile-modal li {
  word-break: break-all;
}

li.bullet-remove.break_word {
  word-break: break-word;
}